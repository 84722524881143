import * as ActionTypes from "../constants/offlineConstants";
import { axiosApiInstance, BASE_URL, encryptData } from "../../helpers/helper";




const getAllOfflineQuotes = (searchText) => async (dispatch) => {
  dispatch({ type: ActionTypes.FETCH_OFFLINEQUOTE_REQUEST });
  try {
    const { data } = await axiosApiInstance.get(
      BASE_URL + "/api/offline-quote/all?search=" + searchText
    );
    console.log(data,"---------------------->>>from api",data.data)
    dispatch({
      type: ActionTypes.FETCH_OFFLINEQUOTE_SUCCESS,
      payload: data.data
    });
  } catch (error) {
    dispatch({
      type: ActionTypes.FETCH_OFFLINEQUOTE_FAILURE,
      payload: error.message
    });
  }
};





export { getAllOfflineQuotes };
