import React, { useEffect, useState } from 'react';
import "./account.css";
import '../usersData/usersData.css';
import '../gametitledetails/gametitledetails.css';
import '../activeGamesDetail/activeGamesDetail.css';
import search from "../../assets/images/search.svg";
import editImg from "../../assets/images/editWithFrame.svg";
import deleteImg from "../../assets/images/deleteWithFrame.svg";
import OrganisationLeaveDeleteModal from '../modal/OrganisationLeaveDeleteModal';
import ManageTeamDeleteUserModal from "../modal/manageTeamDeleteUser";
import ManageTeamAddEditUserModal from "../modal/manageTeamAddEditUsermodal";
import { useDispatch, useSelector } from 'react-redux';
import { getAllOrganisations, leaveOrganisation, updateOrganisation, deleteOrganisationUser, getOrganisationUsers } from '../../redux/actions/organisationActions';
import { getMyPlans } from '../../redux/actions/plansApiActions';
import ROLES, { OrgRoles } from '../../helpers/userTypes';
import useDebouncedSearch from '../../helpers/debounce';
import LoadingComponent from '../loader/LoadingComponent';
import { BASE_URL, S3_BASE_URL, axiosApiInstance, failureAlert, successAlert } from '../../helpers/helper';
import { uploadFile } from '../../redux/actions/commonActions';
import Select from "react-select";


import { ToastContainer } from 'react-toastify';
import ExtraMilePlay from '../../assets/images/ExtramilPlay.png';
import * as ActionTypes from "../../redux/constants/commonApiConstants";
import { COLOR_OPTIONS } from '../../helpers/colorsoptions';

import upload from '../../assets/images/upload.svg';
import { getOrganisation } from '../../redux/actions/plansApiActions';
import { getUser } from '../../redux/actions/userAction';

import remove from '../../assets/images/remove.svg';

const SelectItem = ({ data }) => {
    if (true)
        return (
            <div className="select-item">
                <span style={{ color: data.code }}>{data.label}</span>
                <div className="color-box" style={{ background: data.code }}></div>
            </div>
        )
    else
        return (
            <div />
        )
}

const OrgDetails = (props) => {
    const useSearch = () => useDebouncedSearch(text => searchUsers(text));
    const searchUsers = (text) => {
        dispatch(getOrganisationUsers(text, true));
    }
    const { searchText, setSearchText, results } = useSearch();
    const { role, email } = props
    const [openLeaveDeleteModal, setLeaveDeleteModal] = useState(false);
    const [leaveOrgFlag, setLeaveOrgFlag] = useState(false);
    const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
    const [openAddEditUserModal, setOpenAddEditUserModal] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [deleteUser, setDeleteUser] = useState({});
    const [loaded, setLoaded] = useState(true);
    const [ownerCount, setOwnerCount] = useState(false);
    const [selectedOrganisation, setSelectedOrganisation] = useState("");
    const [previousSelectedOrganisation, setPreviousSelectedOrganisation] = useState("");
    const [bannerImgNames, setBannerImgNames] = useState();
    const [bannerImgSize, setBannerImgSize] = useState();
    const [bannerBackgLocation, setBannerBackgLocation] = useState("");
    const [signInBannerUpload, setSignInBannerUpload] = useState(false);
    const [enableSave, setEnableSave] = useState(false);
    const [removeOrgBanner, setRemoveOrgBanner] = useState('');




    const OrgUsers = useSelector(state => state.orgUsers);
    const { orgUsers } = OrgUsers;
    const MyPlanDetails = useSelector(state => state.getMyPlans);
    const { myPlanDetails } = MyPlanDetails;
    const OrgDetailsByEmail = useSelector(state => state.getOrganisation);
    const { orgDetailsByEmail } = OrgDetailsByEmail;
    const LeaveOrganisation = useSelector(state => state.leaveOrganisation);
    const { userInfo } = useSelector(state => state.getUser);
    const fileUpload = useSelector(state => state.uploadedFile);
    const { uploadedFile } = fileUpload;

    const [userDetails, setUserDetails] = useState({
        organizationName: "",
        email: "",
        companyLogo: "",
        colourAccent: null
    });
    const [prevDetails, setPrevDetails] = useState({
        organizationName: "",
        email: "",
        companyLogo: ""

    });


    const dispatch = useDispatch();

    useEffect(() => {
        if (JSON.stringify(prevDetails) !== JSON.stringify(userDetails)) {
            setEnableSave(true)
        }
        else {
            setEnableSave(false)
        }
    }, [userDetails, prevDetails]);

    useEffect(() => {
        if (uploadedFile && uploadedFile.data) {
            setUserDetails(prevState => ({
                ...prevState,
                companyLogo: uploadedFile.data.path
            }))
        }
    }, [uploadedFile]);


    useEffect(() => {
        if (orgDetailsByEmail && orgDetailsByEmail.data) {
            let colourAccent = null;
            if (orgDetailsByEmail.data.colourAccent) {
                COLOR_OPTIONS.forEach(item => {
                    if (item.code === orgDetailsByEmail.data.colourAccent)
                        colourAccent = item;
                })
            }
            setUserDetails(prevState => ({
                ...prevState,
                organizationName: orgDetailsByEmail.data?.name || "",
                companyLogo: orgDetailsByEmail.data.companyLogo || "",
                colourAccent: colourAccent
            }))
            setPrevDetails(prevState => ({
                ...prevState,
                organizationName: orgDetailsByEmail.data?.name || "",
                companyLogo: orgDetailsByEmail.data.companyLogo || "",
                colourAccent: colourAccent
            }))
            if (orgDetailsByEmail && orgDetailsByEmail.data && orgDetailsByEmail.data.sigInPageBanner) {
                // setBannerImgNames(extractFilename(orgDetailsByEmail.data.sigInPageBanner));
                setBannerImgNames(orgDetailsByEmail.data.sigInPageBanner);
            }
            setSelectedOrganisation({ value: orgDetailsByEmail.data?.name, label: orgDetailsByEmail.data?.name, id: orgDetailsByEmail.data.id });
            setPreviousSelectedOrganisation({ value: orgDetailsByEmail.data?.name, label: orgDetailsByEmail.data?.name, id: orgDetailsByEmail.data.id });
        }
    }, [orgDetailsByEmail]);


    const extractFilename = (url) => {
        var parts = url.split('/');
        return parts[parts.length - 1];
    }

    useEffect(() => {
        if (
            (OrgUsers && OrgUsers.loading) ||
            (MyPlanDetails && MyPlanDetails.loading) ||
            (results && results.loading) ||
            (LeaveOrganisation && LeaveOrganisation.loading)
        ) {
            setLoaded(false);
        }
        else {
            setLoaded(true);

        }
    }, [OrgUsers, MyPlanDetails, results, LeaveOrganisation]);
    useEffect(() => {
        if (orgUsers && orgUsers.data) {
            var count = 0;
            orgUsers.data.map((user) => {
                if (user.role === ROLES.ORG_SUPER_ADMIN)
                    count += 1;
                return user;
            })
            setOwnerCount(count);
        }
    }, [orgUsers]);
    useEffect(() => {
        dispatch(getOrganisationUsers('', true));
        if (OrgRoles.includes(userInfo?.data?.role))
            dispatch(getMyPlans());
    }, []);

    const handleEdit = (user) => {
        setEditUser(user);
        setOpenAddEditUserModal(true);
    }
    const handleAdd = () => {
        setEditUser(null);
        setOpenAddEditUserModal(true);
    }
    const handleDelete = (user) => {
        setDeleteUser(user);
        setOpenDeleteUserModal(true)
    }
    const handleDeleteOrg = () => {
        setLeaveOrgFlag(false)
        setLeaveDeleteModal(true);
    }
    const handleLeaveOrg = () => {
        setLeaveOrgFlag(true);
        setLeaveDeleteModal(true);
    }
    const handleDeleteUser = async (e) => {
        const response = await dispatch(deleteOrganisationUser(deleteUser.id));
        if (response === 200) {
            setOpenDeleteUserModal(false);
            dispatch(getOrganisationUsers('', true));
        }
    }

    // const fileChanged = async (e) => {
    //     e.persist();
    //     const file = e.target.files[0];
    //     e.target.value = [];
    //     if (file && file.type && file.type.startsWith("image/")) {
    //         const data = new FormData();
    //         data.append("company-logos", file);
    //         const response = await dispatch(uploadFile(data));
    //         if (response?.status?.includes?.("417"))
    //             failureAlert("Uploaded file contains some malware!");
    //         else if (response?.status?.includes?.("500"))
    //             failureAlert("File format not supported");
    //     }
    //     else {
    //         failureAlert("File format not allowed");
    //     }
    // }

    const handleSiginBannerChange = (e) => {
        e.persist();
        const file = e.target.files[0]
        e.target.value = null;
        const img = new Image();
        let width, height;
        img.onload = async function () {
            height = this.height;
            width = this.width;
            if (e.target.name === "sigin-banner-upload") {
                if (width === 1126 && height === 844) {
                    setBannerImgSize('');
                    const data = new FormData();
                    data.append("sigin-page-banner", file);
                    await axiosApiInstance.post(BASE_URL + "/api/file/upload", data)
                        .then((response) => {
                            setBannerBackgLocation(response.data.data.location);
                            setBannerImgNames(response.data.data.location);
                            setEnableSave(true);
                            setSignInBannerUpload(true)
                        })
                        .catch((err) => {
                            console.log(`fetch Error: ${err}`);
                        })

                }
                else {
                    console.log(`${height}h---w${width}`);
                    setBannerImgSize("*Image dimension should be 1126x844")
                    failureAlert("The company banner dimensions should be 1126 x 844.")

                }

            }
        }
        const _URL = window.URL || window.webkitURL;
        img.src = _URL.createObjectURL(file);
    }

    const fileChanged = async (e) => {
        e.persist();
        const file = e.target.files[0]
        e.target.value = null;
        const img = new Image();
        let width, height;
        img.onload = async function () {
            height = this.height;
            width = this.width;
            if (e.target.name === "org-logo-upload") {
                // if (width === 422 && height === 136) {
                const data = new FormData();
                data.append("company-logos", file);
                const response = await dispatch(uploadFile(data));
                if (response?.status?.includes?.("417"))
                    failureAlert("Uploaded file contains some malware!");
                else if (response?.status?.includes?.("500"))
                    failureAlert("File format not supported");
                // }
                // else {
                //     console.log(`${136}h---w${422}`);
                //     failureAlert("The company logo dimensions should be 422 x 136.")

                // }

            }
        }
        const _URL = window.URL || window.webkitURL;
        img.src = _URL.createObjectURL(file);
    }

    const removeLogo = () => {
        if (!userDetails.companyLogo)
            return;
        setUserDetails(prevState => ({
            ...prevState,
            companyLogo: null
        }));
        dispatch({
            type: ActionTypes.FILE_UPLOAD_SUCCESS,
            payload: null
        })
    }
    const handleRemoveOrgBanner = () => {
        setEnableSave(true);
        setBannerImgNames('');
        setRemoveOrgBanner('remove');
    }


    const handleSiginPageBannerUpload = async (remove) => {
        const { email } = userInfo.data
        if (remove !== "remove" && bannerBackgLocation && bannerBackgLocation !== null && bannerBackgLocation !== '') {
            const data = {
                sigInPageBanner: bannerBackgLocation,
            }

            if ((role === ROLES.ORG_ADMIN || role === ROLES.ORG_SUPER_ADMIN) && orgDetailsByEmail && orgDetailsByEmail.data) {
                const reponseUpdate = await dispatch(updateOrganisation(orgDetailsByEmail.data.id, data));
                if (reponseUpdate && reponseUpdate.status === 200) {
                    // dispatch(getUser());
                    // dispatch(getOrganisation(email));s
                    setBannerBackgLocation('');
                }
            }
            else {
                // dispatch(getUser());
                setBannerBackgLocation('');
                successAlert('You are not allowed to add sign-in page banner!');
            }
            //   setOpenSaveAccountChangesModal(false);
            // setSignInBannerUpload(false);
            // setEnableSave(false)

        }
        else if (remove === 'remove' && bannerImgNames === '') {

            const data = {
                sigInPageBanner: null,
            }
            if ((role === ROLES.ORG_ADMIN || role === ROLES.ORG_SUPER_ADMIN) && orgDetailsByEmail && orgDetailsByEmail.data) {
                const reponseUpdate = await dispatch(updateOrganisation(orgDetailsByEmail.data.id, data));
                if (reponseUpdate && reponseUpdate.status === 200) {
                    dispatch(getUser());
                    dispatch(getOrganisation(email));
                    // successAlert('Sign In Page Banner Remove Successfully!');
                    setBannerImgNames('');
                    setRemoveOrgBanner('');
                }
            }
            else {
                dispatch(getUser());
                successAlert('You are not allowed to remove sign-in page banner!');
            }
        }
    }

    const changetheme = (e) => {
        setUserDetails(prevState => ({
            ...prevState,
            colourAccent: e
        }));
    }

    const updateChanges = async (e) => {
        e.preventDefault();

        const { companyLogo, colourAccent } = userDetails;
        const { email } = userInfo.data;
        if (bannerBackgLocation && bannerBackgLocation !== null && bannerBackgLocation !== '') {
            await handleSiginPageBannerUpload('addSignInBanner');
        }
        if (removeOrgBanner === 'remove' && bannerImgNames === '') {
            await handleSiginPageBannerUpload('remove');
        }

        if ((role === ROLES.ORG_ADMIN || role === ROLES.ORG_SUPER_ADMIN) && orgDetailsByEmail && orgDetailsByEmail.data) {
            const reponseUpdate = await dispatch(updateOrganisation(orgDetailsByEmail.data.id, { companyLogo, colourAccent: colourAccent && colourAccent.code }));
            if (reponseUpdate && reponseUpdate.status === 200) {
                dispatch(getUser());
                dispatch(getOrganisation(email));
                successAlert('Organisation details updated successfully!');
            }
        }
        else {
            dispatch(getUser());
            successAlert('You are not allowed to update organization info');
        }
        //   setOpenSaveAccountChangesModal(false);
        setEnableSave(false)

    }

    return (
        <div className="myaccount-wrapper organisation-details-wrapper organization-details">
            <LoadingComponent loaded={loaded} />
            <ToastContainer position="bottom-center" />
            <div className="myaccount-heading">
                <h5>My Organisation Details</h5>
            </div>
            {/* <h4 className="title">My Organisation</h4> */}
            <div className="group">
                <div className="org-detail-cont">
                    <div className="form-group">
                        <label htmlFor="orgName">Company Name</label>
                        <input type="text" value={orgDetailsByEmail && orgDetailsByEmail.data && orgDetailsByEmail.data.name} className="form-control" disabled />
                    </div>
                    <div className="form-group">
                        <label htmlFor="orgName">Country</label>
                        <input type="text" value={orgDetailsByEmail && orgDetailsByEmail.data && orgDetailsByEmail.data.country} className="form-control" disabled />
                    </div>
                </div>
            </div>
            {
                role === ROLES.ORG_SUPER_ADMIN || role === ROLES.ORG_ADMIN ?
                    (<div className="group">
                        <div className="account-upload">
                            <div className="orgs-uploads-cont">
                                <div className="upload-container">
                                    {/* <h5>Upload Company Logo</h5> */}
                                    <div className='upload-group'>
                                        <div className="account-profile-name">
                                            <div className="account-profile">
                                                <img src={userDetails.companyLogo ? S3_BASE_URL + userDetails.companyLogo : ExtraMilePlay} alt="logo" />
                                            </div>
                                            <span>Company Logo</span>
                                        </div>
                                        <div className="cstm-upload-btn">
                                            <label className="upload-btn" style={{ background: 'transparent', display: 'flex', flexDirection: 'column' }}>
                                                <span>
                                                <input type="file" name='org-logo-upload' onChange={fileChanged} accept="image/x-png,image/jpg,image/jpeg,image/gif" />

                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.66797 11.8333V13.1666C2.66797 13.5202 2.80844 13.8593 3.05849 14.1094C3.30854 14.3594 3.64768 14.4999 4.0013 14.4999H12.0013C12.3549 14.4999 12.6941 14.3594 12.9441 14.1094C13.1942 13.8593 13.3346 13.5202 13.3346 13.1666V11.8333" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M4.66797 6.50011L8.0013 3.16678L11.3346 6.50011" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M8 3.16678V11.1668" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    Update New Logo
                                                </span>
                                                {/* <span>(422 X 136)</span> */}
                                            </label>
                                            <button type="submit" className={`btn btn-remove ${userDetails.companyLogo ? "" : "disabled"}`} onClick={removeLogo}>
                                                <img src={remove} alt="remove" />
                                                Remove
                                            </button>
                                        </div>
                                    </div>

                                    {/* <h5>Upload Company Banner</h5> */}

                                    <div className='upload-group' style={{ marginTop: '30px' }}>
                                        <div className="account-profile-name">
                                            <div className="account-profile">
                                                <img src={bannerImgNames ? bannerImgNames : "https://staging-extramileplay-public.s3.ap-south-1.amazonaws.com/uploads/sigin-page-banner/Login+sidbanner.png"} alt="logo" />
                                            </div>
                                            <span>Company Banner</span>
                                        </div>
                                        <div className="cstm-upload-btn">
                                            <label className="upload-btn" style={{ background: 'transparent', display: 'flex', flexDirection: "column" }}>
                                                <input type="file" name='sigin-banner-upload' onChange={handleSiginBannerChange} accept="image/x-png,image/jpg,image/jpeg" />
                                                <span>
                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.66797 11.8333V13.1666C2.66797 13.5202 2.80844 13.8593 3.05849 14.1094C3.30854 14.3594 3.64768 14.4999 4.0013 14.4999H12.0013C12.3549 14.4999 12.6941 14.3594 12.9441 14.1094C13.1942 13.8593 13.3346 13.5202 13.3346 13.1666V11.8333" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M4.66797 6.50011L8.0013 3.16678L11.3346 6.50011" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M8 3.16678V11.1668" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    Update Banner
                                                </span>
                                                <span>(1126 X 844)</span>
                                            </label>
                                            <button type="submit" className={`btn btn-remove ${bannerImgNames ? "" : "disabled"}`} onClick={(e) => { handleRemoveOrgBanner('remove') }}>
                                                <img src={remove} alt="remove" />
                                                Remove
                                            </button>
                                        </div>
                                    </div>


                                </div>
                                {/* 
                                <div className="upload-container">
                                    <h5>Upload Company Banner</h5>

                                    <div className='upload-group'>
                                        <div className="account-profile-name">
                                            <div className="account-profile">
                                                <img src={bannerImgNames ? bannerImgNames : "https://staging-extramileplay-public.s3.ap-south-1.amazonaws.com/uploads/sigin-page-banner/Login+sidbanner.png"} alt="logo" />
                                            </div>
                                            <span>Company Banner</span>
                                        </div>
                                        <div className="cstm-upload-btn">
                                            <label className="upload-btn" style={{ background: 'transparent', display:'flex', flexDirection:"column" }}>
                                                <input type="file" name='sigin-banner-upload' onChange={handleSiginBannerChange} accept="image/x-png,image/jpg,image/jpeg" />
                                                <span>
                                                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M2.66797 11.8333V13.1666C2.66797 13.5202 2.80844 13.8593 3.05849 14.1094C3.30854 14.3594 3.64768 14.4999 4.0013 14.4999H12.0013C12.3549 14.4999 12.6941 14.3594 12.9441 14.1094C13.1942 13.8593 13.3346 13.5202 13.3346 13.1666V11.8333" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M4.66797 6.50011L8.0013 3.16678L11.3346 6.50011" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M8 3.16678V11.1668" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                    Update Banner
                                                </span>
                                                <span>(1126 X 844)</span>
                                            </label>
                                            <button type="submit" className={`btn btn-remove ${bannerImgNames ? "" : "disabled"}`} onClick={(e) => { handleRemoveOrgBanner('remove') }}>
                                                <img src={remove} alt="remove" />
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                </div> */}


                                <div className="upload-container"
                                // style={{ marginBottom: "4px" }}
                                >
                                    {/* <h5>Change Color Accent</h5> */}

                                    <div className="color-change" style={{ width: "auto", maxWidth: "100%" }}>
                                        <div className="form-group">
                                            <label htmlFor="" style={{ width: '150px' }}>Change Color Accent</label>
                                            <Select
                                                classNamePrefix="react-select"
                                                className="form-select"
                                                options={COLOR_OPTIONS}
                                                onChange={(e) => changetheme(e)}
                                                menuPlacement={"auto"}
                                                placeholder="Select Color"
                                                value={userDetails.colourAccent}
                                                formatOptionLabel={function (data) {
                                                    return (
                                                        <SelectItem data={data} />
                                                    );
                                                }} />
                                        </div>
                                    </div>

                                </div>



                            </div>
                        </div>
                    </div>) : null
            }
            {
                role === ROLES.ORG_ADMIN || role === ROLES.ORG_SUPER_ADMIN ? (
                    <div className='upload-group' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderBottom: '1px solid #bfc1d7', paddingBottom: '20px' }}>
                        <button type='button' className='btn btn-primary' onClick={(e) => updateChanges(e)} disabled={!enableSave} style={{ opacity: enableSave ? '1' : '0.5', cursor: enableSave ? 'pointer' : 'not-allowed', width: "164px" }}>save change</button>
                    </div>
                ) : null
            }

            <hr /><br />
            <div className="details-top">
                <div className="counts">
                    <h4>Total Users</h4>
                    <p>
                        <span className="teams-user-count">
                            {myPlanDetails && myPlanDetails.data && myPlanDetails.data.planDetail && myPlanDetails.data.planDetail.currentUserCount}
                            {" of "}
                            {myPlanDetails && myPlanDetails.data && myPlanDetails.data.planDetail && myPlanDetails.data.planDetail.plan.userLimit}
                        </span>
                    </p>
                </div>
                <div className="right">
                    <div className="input-icon desk-view">
                        <img src={search} alt="search" />
                        <input
                            type="text"
                            name="search"
                            placeholder="Search by email"
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                // e.target.value.length === 0 && dispatch(getOrganisationUsers("", true))
                            }}
                        />
                    </div>
                    <div className="btn-tooltip">
                        <button className={role === "ORG_SUPER_ADMIN" ? `btn btn-secondry-owner btn-secondry${myPlanDetails && myPlanDetails.data && myPlanDetails.data.planDetail && myPlanDetails.data.planDetail.currentUserCount >= myPlanDetails.data.planDetail.plan.userLimit ? `btn-primary disabled` : ``}` : ` btn btn-secondry  ${myPlanDetails && myPlanDetails.data && myPlanDetails.data.planDetail && myPlanDetails.data.planDetail.currentUserCount === myPlanDetails.data.planDetail.plan.userLimit ? `disabled btn-secondry` : ``}`} onClick={handleAdd}>{role === "ORG_SUPER_ADMIN" ? "+ Add New Owner/Admin" : "+ Add New Admin"}</button>
                        {myPlanDetails && myPlanDetails.data && myPlanDetails.data.planDetail && myPlanDetails.data.planDetail.currentUserCount === myPlanDetails.data.planDetail.plan.userLimit ? (
                            <div className="tooltip" role="tooltip">
                                <span style={{ fontSize: '11px' }}>User limit exceeded. Please contact the admin.</span>
                            </div>)
                            : null

                        }
                    </div>

                </div>
            </div>
            <div className="input-icon mobile-view">
                <img src={search} alt="search" />
                <input
                    type="text"
                    name="search"
                    placeholder="Search"
                    value={searchText}
                    onChange={(e) => {
                        setSearchText(e.target.value);
                        // e.target.value.length === 0 && dispatch(getOrganisationUsers("", true)) 
                    }}
                />
            </div>
            <div className="report-table mb0">
                {orgUsers && orgUsers.data && orgUsers.data.length > 0 ?
                    <div className="game-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Full Name</th>
                                    <th>Email Id</th>
                                    <th>Role</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orgUsers.data.map((user) => {
                                        return (
                                            <tr key={JSON.stringify(user)}>
                                                <td className="">{(user.firstName || "") + " " + (user.lastName || "")}</td>
                                                <td className="">{user.email}</td>
                                                <td className="games-title">{user.role === ROLES.ORG_SUPER_ADMIN ? "Owner" : user.role === ROLES.ORG_ADMIN ? "Admin" : "User"}</td>
                                                <td className="action-group">
                                                    {
                                                        (role === ROLES.ORG_SUPER_ADMIN || user.role === role) && (user.email !== email) &&
                                                        <>
                                                            {/* <span>
                                                                <img src={editImg} alt="" onClick={() => handleEdit(user)} />
                                                            </span>
                                                            <span>
                                                                <img src={deleteImg} alt="" onClick={() => handleDelete(user)} />
                                                            </span> */}

                                                            <span>
                                                                {/* <img src={editButton} alt="edit" className={`${deleteUsersList.length !== 0 ? "invisible" : ""}`} onClick={() => handleEdit(user)} /> */}
                                                                <div className="animated-btn-tooltip">
                                                                    <button class="animated-editBtn"
                                                                        onClick={() => handleEdit(user)}
                                                                    >
                                                                        <svg height="1em" viewBox="0 0 512 512">
                                                                            <path
                                                                                fill="#5F638A"
                                                                                d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                                                            ></path>
                                                                        </svg>
                                                                    </button>
                                                                    <div className="hover-tooltip" role="tooltip">
                                                                        <span>Edit</span>
                                                                    </div>
                                                                </div>
                                                            </span>

                                                            <span>
                                                                {/* <img src={deleteButton} alt="delete" className={`edit-btn ${deleteUsersList.length !== 0 ? "invisible" : ""}`} onClick={() => handleDelete(user)} /> */}
                                                                <div className="animated-btn-tooltip">
                                                                    <button class="session-delete-button" onClick={() => handleDelete(user)}>
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 69 14"
                                                                            class="svgIcon bin-top"
                                                                        >
                                                                            <g clip-path="url(#clip0_35_24)">
                                                                                <path
                                                                                    fill="black"
                                                                                    d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                                                                ></path>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_35_24">
                                                                                    <rect fill="white" height="14" width="69"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>

                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 69 57"
                                                                            class="svgIcon bin-bottom"
                                                                        >
                                                                            <g clip-path="url(#clip0_35_22)">
                                                                                <path
                                                                                    fill="black"
                                                                                    d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                                                                ></path>
                                                                            </g>
                                                                            <defs>
                                                                                <clipPath id="clip0_35_22">
                                                                                    <rect fill="white" height="57" width="69"></rect>
                                                                                </clipPath>
                                                                            </defs>
                                                                        </svg>
                                                                    </button>
                                                                    <div className="hover-tooltip" role="tooltip">
                                                                        <span>Delete</span>
                                                                    </div>

                                                                </div>
                                                            </span>
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    : <div className="no-users">
                        <h4>No Users Found</h4>
                    </div>
                }
            </div>
            <div className="table-responsive">
                {
                    orgUsers && orgUsers.data &&
                    orgUsers.data.map((user) => {
                        return (
                            <div className="report-t-card c-white-card">
                                <div className="t-top">
                                    <div className="role">
                                        <span className="role">Role</span>
                                        <h4>{user.role === ROLES.ORG_SUPER_ADMIN ? "Owner" : user.role === ROLES.ORG_ADMIN ? "Admin" : "User"}</h4>
                                    </div>
                                    <div className={((role === ROLES.ORG_SUPER_ADMIN || user.role === role) && (user.email !== email)) ? "action-group" : "action-group hide"}>
                                        {/* <span>
                                            <img src={editImg} alt="" onClick={() => handleEdit(user)} />
                                        </span>
                                        <span>
                                            <img src={deleteImg} alt="" onClick={() => handleDelete(user)} />
                                        </span> */}
                                        <span>
                                            {/* <img src={editButton} alt="edit" className={`${deleteUsersList.length !== 0 ? "invisible" : ""}`} onClick={() => handleEdit(user)} /> */}
                                            <div className="animated-btn-tooltip">
                                                <button class="animated-editBtn"
                                                    onClick={() => handleEdit(user)}
                                                >
                                                    <svg height="1em" viewBox="0 0 512 512">
                                                        <path
                                                            fill="#5F638A"
                                                            d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"
                                                        ></path>
                                                    </svg>
                                                </button>
                                                <div className="hover-tooltip" role="tooltip">
                                                    <span>Edit</span>
                                                </div>
                                            </div>
                                        </span>

                                        <span>
                                            {/* <img src={deleteButton} alt="delete" className={`edit-btn ${deleteUsersList.length !== 0 ? "invisible" : ""}`} onClick={() => handleDelete(user)} /> */}
                                            <div className="animated-btn-tooltip">
                                                <button class="session-delete-button" onClick={() => handleDelete(user)}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 69 14"
                                                        class="svgIcon bin-top"
                                                    >
                                                        <g clip-path="url(#clip0_35_24)">
                                                            <path
                                                                fill="black"
                                                                d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                                            ></path>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_35_24">
                                                                <rect fill="white" height="14" width="69"></rect>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>

                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 69 57"
                                                        class="svgIcon bin-bottom"
                                                    >
                                                        <g clip-path="url(#clip0_35_22)">
                                                            <path
                                                                fill="black"
                                                                d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                                            ></path>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_35_22">
                                                                <rect fill="white" height="57" width="69"></rect>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </button>
                                                <div className="hover-tooltip" role="tooltip">
                                                    <span>Delete</span>
                                                </div>

                                            </div>
                                        </span>

                                    </div>
                                </div>
                                <div className='t-bottom'>
                                    <h4 className="name">{(user.firstName || "") + " " + (user.lastName || "")}</h4>
                                    <h4 className="email">{user.email}</h4>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <div className="leave-container">
                <hr /><br />
                <div className={role === "ORG_SUPER_ADMIN" ? "leave leave-owner" : "leave"}>
                    <h4>Exiting the platform</h4>
                    {
                        role === "ORG_SUPER_ADMIN" ?
                            <div className="btn-group">
                                <div className={`${ownerCount <= 1 ? "btn-tooltip" : ""}`}>
                                    <button className={ownerCount > 1 ? "btn btn-outline" : "btn btn-outline disabled"} onClick={(e) => (ownerCount > 1 && handleLeaveOrg(e))}>Leave Organisation</button>
                                    {ownerCount <= 1 ? <div className="tooltip" role="tooltip">
                                        <span>Please add/assign another user as an organisation owner.</span>
                                    </div> : null} </div>
                                <button className="btn btn-secondry" onClick={handleDeleteOrg}>Delete Organisation</button>
                            </div> :
                            <button type="submit" className="btn btn-outline" onClick={handleLeaveOrg}>Leave Organisation</button>
                    }
                </div>
            </div>
            <OrganisationLeaveDeleteModal modalid={"leave-delete-org"} {...props} setLeaveDeleteModal={setLeaveDeleteModal} toggle={openLeaveDeleteModal} leave={leaveOrgFlag} orgId={orgDetailsByEmail && orgDetailsByEmail.data && orgDetailsByEmail.data.id} />
            {openDeleteUserModal && <ManageTeamDeleteUserModal modalid="delete-user-modal" toggle={openDeleteUserModal} setOpenDeleteUserModal={setOpenDeleteUserModal} user={deleteUser} handleDeleteUser={handleDeleteUser} orgDetailPage />}
            {openAddEditUserModal &&
                <ManageTeamAddEditUserModal
                    modalid="add-user-modal"
                    toggle={openAddEditUserModal}
                    user={editUser}
                    setOpenAddEditUserModal={setOpenAddEditUserModal}
                    orgDetailPage
                    successAlert={successAlert}
                />}
        </div>
    )
}

export default OrgDetails;