import React, { useState, useEffect } from "react";
import PageLayout from "../pagelayout/pagelayout";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import "./paymentDetails.css";
import DataTable from "react-data-table-component";
import search from "../../assets/images/search.svg";
import PaymentDetailsModal from "../modal/paymentDetailsModal";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { getAllPaymentForAdmin, verifyOfflinePayment } from "../../redux/actions/paymentApiActions";
import useDebouncedSearch from "../../helpers/debounce";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { failureAlert, IsAdmin, successAlert } from "../../helpers/helper";
import LoadingComponent from "../loader/LoadingComponent";
import { SYMBOLS } from "../../constants";
const PaymentDetails = (props) => {
  const useSearch = () => useDebouncedSearch(text => searchDetails(text));
  const searchDetails = (text) => {
    dispatch(getAllPaymentForAdmin(text));
  }
  const { searchText, setSearchText, results } = useSearch();
  const isAdmin = IsAdmin();
  const dispatch = useDispatch();
  const [detailsState, setDetailState] = useState({});
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [transactionData, setTransactionData] = useState([]);
  const [verifyFlag, setVerifyFlag] = useState(false);
  const [loaded, setLoaded] = useState(true);
  const AllPaymentForAdmin = useSelector(state => state.allPaymentForAdmin);
  const { allPaymentForAdmin } = AllPaymentForAdmin;
  const OfflinePaymentVerify = useSelector(state => state.offlinePaymentVerify);
  useEffect(() => {
    dispatch(getAllPaymentForAdmin(""));
    console.log("transaction data=====>>>>>>>>>>>>>.",JSON.stringify(allPaymentForAdmin))
  }, [dispatch]);
  const signOut = async () => {
    await dispatch(logOut());
    if (isAdmin) props.history.push("/admin");
    else props.history.push("/");
  };
  const customStyles = {
    headCells: {
      style: {
        fontFamily: "firaSans-semibold",
        fontSize: "15px",
        borderBottom: "1px solid Black",
        borderTop: "1px solid Black"
      },
    },
    cells: {
      style: {
        fontFamily: "firaSans-regular",
        fontSize: "14px",
        textAlign: "center"
      },
    },
    header: {
      style: {
        fontFamily: "firaSans-semibold",
        color: '#737373'
      },
    },
  };
  const verifyPayment = async (details) => {
    let resStatus = await dispatch(verifyOfflinePayment(details));
    if (resStatus === 200) {
      window.gtag("event", "Users Subscribed", {
        event_category: "USER",
        event_label: "Users subscribed to the ExtramilePlay platform"
      });
      successAlert('Payment Verified Successfully !');
      setOpenDetailsModal(false);
      dispatch(getAllPaymentForAdmin(""));
    } else {
      failureAlert("Payment Verification Failed !");
    }
  }
  useEffect(() => {
    if (allPaymentForAdmin && allPaymentForAdmin.data) {
      const transactions = allPaymentForAdmin.data.map((record, index) => ({
        id: index + 1,
        email: record.user.email,
        companyName: record.organization?.name,
        plan: record.plan.title,
        Amount: `${SYMBOLS[record.currency]} ${record.amount}`,
        status: record.status,
        timeStamp: new Intl.DateTimeFormat("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true
        }).format(new Date(record.createdAt)),
        mode: record.paymentGateway,
        name: `${record.user.firstName} ${record.user.lastName}`,
        phoneNumber: record.organization?.phoneNumber,
        users: record.plan.userLimit,
        GSTIN: record.organization?.GSTIN,
        transactionId: record.id,
        comments: record.comments
      }));
     
      setTransactionData(transactions);
    }
  }, [allPaymentForAdmin]);
  useEffect(() => {
    setLoaded(!(AllPaymentForAdmin?.loading || OfflinePaymentVerify?.loading || results?.loading));
  }, [AllPaymentForAdmin, results, OfflinePaymentVerify]);
  const columns = [
    {
      name: "S.No",
      // selector: "id",
      selector: row => row.id,
      center: true,
      sortable: false
    },
    {
      name: "Email",
      // selector: "email"
      selector: row => row.email,
    },
    {
      name: "Company Name",
      // selector: "companyName"
      selector: row => row.companyName,
    },
    {
      name: "Plan",
      // selector: "plan"
      selector: row => row.plan,
    },
    {
      name: "Amount",
      // selector: "Amount"
      selector: row => row.Amount,
    },
    {
      name: "Status",
      // selector: "status"
      selector: row => row.status,
    },
    {
      name: "Time Stamp",
      // selector: "timeStamp"
      selector: row => row.timeStamp,
    },
    {
      name: "Mode of payment",
      // selector: "mode",
      selector: row => row.mode,
      sortable: true
    },
    {
      name: "Details",
      cell: (row) => (
        <button
          className="view-btn btn-primary"
          data-toggle="modal"
          data-target="#payment-details-modal"
          onClick={() => handleChange(row)}
        >
          {row.status === "PENDING" && row.mode === "OFFLINE" ? "VERIFY" : "VIEW"}
        </button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    }
  ];
  const handleChange = (row) => {
    setVerifyFlag(row.status === 'PENDING');
    setOpenDetailsModal(true);
    setDetailState(row);
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  }
  return (
    <div className="admin-homepage">
      <PaymentDetailsModal details={detailsState} toggle={openDetailsModal} setOpenDetailsModal={setOpenDetailsModal} verify={verifyFlag} verifyPayment={verifyPayment} />
      <PageLayout
        sidebartitle=""
        active={"Payments"}
        category
        sideBarContents={sidebarContentAdmin}
        profile
        signOut={signOut}
        isAdmin={isAdmin}
        {...props}
      >
        <div className="container-payment">
          <div className="payment">
            <h1 className="title">Payment History</h1>
          </div>
        </div>
        <div className="input-icon search-bar">
          <img src={search} alt="search" />
          <input type="text" name="search" placeholder="Search" value={searchText} onChange={handleSearch} />
        </div>
        <ToastContainer position="bottom-center" />
        <LoadingComponent loaded={loaded} >
          <div className="data-table">
            <DataTable
              title="Transactions"
              columns={columns}
              data={transactionData}
              className="rdt_TableHead"
              responsive
              striped
              highlightOnHover
              pagination
              customStyles={customStyles}
            />
          </div>
        </LoadingComponent>
      </PageLayout>
    </div>
  );
};
export default PaymentDetails;