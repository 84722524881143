

import React, { useCallback, useEffect, useState } from 'react';


import { BASE_URL, S3_BASE_URL, axiosApiInstance, failureAlert, successAlert } from '../../helpers/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgTeams } from '../../redux/actions/teamAction';
import LoadingComponent from '../loader/LoadingComponent';
import { ToastContainer } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../header/header';
import { FaUsersLine } from 'react-icons/fa6';
import arrowback from "../../assets/images/arrow-back.svg";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";
import { getUser } from '../../redux/actions/userAction';
import DeleteTeamModal from '../modal/deleteTeamModal';
import { uploadFile } from '../../redux/actions/commonActions';
import AddTeamModal from '../modal/addTeamModal';
import PlayIcon from "../../assets/backgrounds/favicon.png"
import { data } from 'synonyms/dictionary';




const EditTeams = (props) => {


    const [loaded, setLoaded] = useState(true);
    const [teams, setTeams] = useState([])
    const [selectedTeamInfo, setSelectedTeamInfo] = useState()
    const [orgUsers, setOrgUsers] = useState([]);
    const [selectedTeamUsersData, setSelectedTeamUsersData] = useState([])
    const [teamLogo, setTeamLogo] = useState('')

    const [teamMembers, setTeamMembers] = useState([]);
    const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState('');
    const [teamCaptainData, setTeamCaptainData] = useState({
        userId: null,
        teamId: null
    })

    const [isAllOrgUsersSelected, setIsAllOrgUsersSelected] = useState(false)
    const [openAddTeamModal, setOpenAddTeamModal] = useState(false)

    const [teamCaptainSearch, setTeamCaptainSearch] = useState();
    const [teamMemberSearch, setTeamMemberSearch] = useState();




    const getAllOrgsTeam = useSelector((state) => state.getOrgsTeams);
    const { orgTeams } = getAllOrgsTeam;
    const UserInfo = useSelector((state) => state.getUser);
    const { userInfo } = UserInfo;
    const fileUpload = useSelector(state => state.uploadedFile);
    const { uploadedFile } = fileUpload;
    const history = useHistory();
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const teamId = queryParams.get('teamId');

    useEffect(() => {
        if (uploadedFile && uploadedFile.data) {
            setTeamLogo(uploadedFile.data.path)

        }

    }, [uploadedFile, fileUpload])

    useEffect(() => {
        if (userInfo && userInfo.data && userInfo.data.organizationId) {
            setTeams([])
            dispatch(getOrgTeams(userInfo.data.organizationId))
        }

    }, [userInfo])

    useEffect(() => {

        if (orgTeams && orgTeams.message === "Sucessfully fetch org teams" && teamId) {
            setTeams(orgTeams.data);
            let teamInfo = orgTeams.data.find((data, idx) => data.id === teamId);
            setSelectedTeamUsersData([])
            setIsAllOrgUsersSelected(false)
            setSelectedTeamInfo(teamInfo)
            getOrgUsers(teamInfo.id, '')
            getOrgSelectedTeamUsers(teamInfo.id)
            setDeleteUserId('')
            setOpenDeleteUserModal(false)
            setTeamLogo(teamInfo.teamLogo);
            if (teamInfo && teamInfo.teamCaptainId !== null && teamInfo && teamInfo.teamCaptainId !== '') {
                setTeamCaptainData({
                    teamId: teamInfo.id,
                    userId: teamInfo.teamCaptainId
                })

            }
            else {
                setTeamCaptainData({
                    teamId: null,
                    userId: null
                })
            }

        }
        else if (orgTeams && orgTeams.message === "Sucessfully fetch org teams" && !teamId) {
            setTeams(orgTeams.data);
            let teamInfo = orgTeams.data[0];
            setSelectedTeamUsersData([])
            setIsAllOrgUsersSelected(false)
            setSelectedTeamInfo(teamInfo)
            getOrgUsers(teamInfo.id, '')
            getOrgSelectedTeamUsers(teamInfo.id)
            setDeleteUserId('')
            setOpenDeleteUserModal(false)
            setTeamLogo(teamInfo.teamLogo);
            if (teamInfo && teamInfo.teamCaptainId !== null && teamInfo && teamInfo.teamCaptainId !== '') {
                setTeamCaptainData({
                    teamId: teamInfo.id,
                    userId: teamInfo.teamCaptainId
                })

            }
            else {
                setTeamCaptainData({
                    teamId: null,
                    userId: null
                })
            }

            if (selectedTeamInfo && selectedTeamInfo?.id) {
                let teamInfo = orgTeams.data.find((data, idx) => data.id === selectedTeamInfo.id);
                setSelectedTeamUsersData([])
                setIsAllOrgUsersSelected(false)
                setSelectedTeamInfo(teamInfo)
                getOrgUsers(teamInfo.id, '')
                getOrgSelectedTeamUsers(teamInfo.id)
                setDeleteUserId('')
                setOpenDeleteUserModal(false)
                setTeamLogo(teamInfo.teamLogo);
                if (teamInfo && teamInfo.teamCaptainId !== null && teamInfo && teamInfo.teamCaptainId !== '') {
                    setTeamCaptainData({
                        teamId: teamInfo.id,
                        userId: teamInfo.teamCaptainId
                    })

                }
                else {
                    setTeamCaptainData({
                        teamId: null,
                        userId: null
                    })
                }

            }


        }
        else if (orgTeams && orgTeams.message === "org teams not available") {
            setTeams([]);
        }

    }, [getAllOrgsTeam])

    useEffect(() => {
        if (selectedTeamInfo && selectedTeamInfo?.id && teamMemberSearch && teamMemberSearch.length > 0) {
            getOrgUsers(selectedTeamInfo.id, teamMemberSearch)
        }
        else if (selectedTeamInfo && selectedTeamInfo?.id && teamMemberSearch === '') {
            getOrgUsers(selectedTeamInfo.id)
        }

    }, [teamMemberSearch])


    useEffect(() => {
        if (selectedTeamInfo && selectedTeamInfo?.id && teamCaptainSearch && teamCaptainSearch.length > 0) {
            getOrgSelectedTeamUsers(selectedTeamInfo.id, teamCaptainSearch)
        }
        else if (selectedTeamInfo && selectedTeamInfo?.id && teamCaptainSearch === '') {
            getOrgSelectedTeamUsers(selectedTeamInfo.id)
        }

    }, [teamCaptainSearch])




    const handleTeamSelection = (teamInfo) => {
        if (teams && teams.length > 0) {
            setSelectedTeamUsersData([])
            setIsAllOrgUsersSelected(false)
            setSelectedTeamInfo(teamInfo)
            getOrgUsers(teamInfo.id, '')
            getOrgSelectedTeamUsers(teamInfo.id)
            setDeleteUserId('')
            setOpenDeleteUserModal(false)
            setTeamCaptainSearch('')
            setTeamMemberSearch('')

            setTeamLogo(teamInfo.teamLogo);
            if (teamInfo && teamInfo.teamCaptainId !== null && teamInfo && teamInfo.teamCaptainId !== '') {
                setTeamCaptainData({
                    teamId: teamInfo.id,
                    userId: teamInfo.teamCaptainId
                })

            }
            else {
                setTeamCaptainData({
                    teamId: null,
                    userId: null
                })
            }
        }
    }


    const getOrgUsers = async (orgteamId, searchText) => {

        setLoaded(false)
        const usersInfo = await axiosApiInstance.post(BASE_URL + "/api/organization-team/get-allusers", { organizationId: userInfo.data.organizationId, organisationTeamId: orgteamId, search: searchText })
        if (usersInfo && usersInfo.data && usersInfo.data.message === "Succesfully fetch organization users") {
            setOrgUsers(usersInfo.data.data);
        }
        setLoaded(true)
    }

    const getOrgSelectedTeamUsers = async (orgteamId, searchText) => {

        setLoaded(false)
        const usersInfo = await axiosApiInstance.post(BASE_URL + "/api/organization-team/org/team-users", { organizationId: userInfo.data.organizationId, organisationTeamId: orgteamId, search: searchText })
        if (usersInfo && usersInfo.data && usersInfo.data.message === "Succesfully fetch organization users") {
            setTeamMembers(usersInfo.data.data);
        }
        setLoaded(true)
    }

    const handleAddNewTeamModal = () => {
        setOpenAddTeamModal(true);
    }

    const handleTeamChange = (userId, teamId) => {

        setSelectedTeamUsersData((prevSelectedTeams) => {
            const updatedSelection = prevSelectedTeams.filter(selection => selection.userId !== userId);
            const updatedSelectionUnique = prevSelectedTeams.find(selection => selection.userId === userId);
            const userSelection = orgUsers.find(user => user.id === userId)?.organizationTeam?.id;

            if (userSelection && userSelection === teamId && !updatedSelectionUnique) {
                updatedSelection.push({ userId, teamId: null });
            } else if (updatedSelectionUnique) {
                if (updatedSelectionUnique.teamId === teamId) {
                    updatedSelection.push({ userId, teamId: null });
                } else {
                    updatedSelection.push({ userId, teamId });
                }
            } else {
                updatedSelection.push({ userId, teamId });
            }

            return updatedSelection;
        });


    };

    const getSelectedChekced = (userId) => {
        const userSelection = selectedTeamUsersData.find(selection => selection.userId === userId);
        return userSelection && userSelection.teamId === null ? false : userSelection && userSelection.teamId !== null ? true : (orgUsers.find(user => user.id === userId)?.organizationTeam?.id || false);
    };

    const handleSelectAllTeamUsers = () => {
        setSelectedTeamUsersData([])
        if (isAllOrgUsersSelected === false && orgUsers && orgUsers.length > 0) {
            orgUsers.map((member, index) => {
                setSelectedTeamUsersData((prevState) => [
                    ...prevState,
                    { userId: member.id, teamId: selectedTeamInfo.id },
                ]);
            })
            setIsAllOrgUsersSelected(true)
        }
        else if (isAllOrgUsersSelected === true && orgUsers && orgUsers.length > 0) {
            orgUsers.map((member, index) => {
                setSelectedTeamUsersData((prevState) => [
                    ...prevState,
                    { userId: member.id, teamId: null },
                ]);
            })
            setIsAllOrgUsersSelected(false)
        }
    }

    useEffect(() => {
        if (selectedTeamUsersData && selectedTeamUsersData.length > 0) {
            console.log("selectedUserData:  " + JSON.stringify(selectedTeamUsersData));
        }
    }, [selectedTeamUsersData])

    const handleTeamMembersSearch = (e) => {
        let value = e.target.value;
        setTeamMemberSearch(value)
    }

    const handleTeamMembersCaptainSearch = (e) => {
        let value = e.target.value;
        setTeamCaptainSearch(value)
        console.log("va;ue: " + value);
        // getOrgSelectedTeamUsers(selectedTeamInfo.id, value)

    }


    const handleDeleteUser = async (userId) => {
        setLoaded(false)
        if (!userId) {
            return failureAlert("failed to delete team")
        }
        const body = {
            usersTeam: [{
                userId: userId,
                teamId: null
            }]
        }
        const response = await axiosApiInstance.post(BASE_URL + "/api/organization-team/update-team", body);
        if (response && response.data && response.data.message === "org user Updated sucessfully") {
            successAlert("sucessfully added user to team");

            setTeamMembers([])
            // setSelectedTeamUsersData([])
            // setOrgUsers([])
            // setSelectedTeamInfo()

            if (userInfo && userInfo.data && userInfo.data.organizationId) {
                dispatch(getOrgTeams(userInfo.data.organizationId));
            }


        }
        setDeleteUserId('')
        setOpenDeleteUserModal(false)
        setLoaded(true)
    }

    const handleDeleteUserModal = (userId) => {
        setDeleteUserId(userId)
        setOpenDeleteUserModal(true)
    }

    const handleTeamCaptainSelection = (userId) => {
        if (userId) {
            setTeamCaptainData({
                userId: userId,
                teamId: selectedTeamInfo.id
            })
        }
    }

    const getSelectedTeamCaptainChecked = (userId) => {
        const userSelection = teamCaptainData.userId === userId
        return userSelection ? true : false
    };

    useEffect(() => {
        if (teamCaptainData && teamCaptainData.userId !== null) {
            console.log("teamCaptainData:  " + JSON.stringify(teamCaptainData));
        }
    }, [teamCaptainData])

    const handleBack = () => {
        history.push('/manage-users-team')
    }

    const handleSaveChanges = async (e) => {
        e.preventDefault();

        if (selectedTeamUsersData && selectedTeamUsersData.length > 0 || teamCaptainData && teamCaptainData.userId !== null && teamCaptainData.userId !== selectedTeamInfo.teamCaptainId || teamLogo && teamLogo.length > 0 && teamLogo !== selectedTeamInfo.teamLogo) {

            if (userInfo && userInfo.data && userInfo.data.organizationId) {

                const body = {
                    teamcaptainInfo: teamCaptainData,
                    usersTeam: selectedTeamUsersData,
                    teamLogoData: {
                        teamId: selectedTeamInfo.id,
                        logo: teamLogo ? teamLogo : null
                    }
                }

                const response = await axiosApiInstance.post(BASE_URL + "/api/organization-team/update-team", body);
                if (response && response.data && response.data.message === "org user Updated sucessfully") {
                    successAlert("sucessfully added user to team");
                    setIsAllOrgUsersSelected(false);

                    setTeamCaptainData({
                        teamId: null,
                        userId: null
                    })
                    setSelectedTeamUsersData([]);
                    setTeamMembers([])
                    setOrgUsers([])
                    setTeams([])
                    setTeamCaptainSearch('')
                    setTeamMemberSearch('')
                    // setSelectedTeamInfo()

                    dispatch(getOrgTeams(userInfo.data.organizationId));

                }
            }
        }
        else {
            failureAlert("Nothing to update")
        }
    }


    const fileChanged = async (e) => {
        e.persist();
        const file = e.target.files[0]
        e.target.value = null;
        const img = new Image();
        let width, height;
        img.onload = async function () {
            height = this.height;
            width = this.width;
            if (e.target.name === "team-logo-upload") {
                if (width === 50 && height === 50) {
                    const data = new FormData();
                    data.append("team-logo", file);
                    const response = await dispatch(uploadFile(data));
                    if (response?.status?.includes?.("417"))
                        failureAlert("Uploaded file contains some malware!");
                    else if (response?.status?.includes?.("500"))
                        failureAlert("File format not supported");
                }
                else {
                    failureAlert("The team logo dimensions should be 50 x 50.")

                }

            }
        }
        const _URL = window.URL || window.webkitURL;
        img.src = _URL.createObjectURL(file);
    }

    const handleScheduleGame = () => {
        history.push('/team/games')
    }
    const handleEditTeamsClick = ()=>{
        history.push("/manage-edit-teams")
    }


    return (<>
        <Header profile />
        <div className="flex w-[100%] h-[100vh] flex-col items-center justify-start pt-[110px] md:pr-8 md:pl-8 lg:pr-10 lg:pl-10 pr-5 pl-5 pb-5 overflow-y-scroll">

            <ToastContainer position='bottom-center' />
            <LoadingComponent loaded={loaded} />
            <div className="flex items-center justify-center w-[100%] relative">
                <h1 className='edit-team-head'onClick={handleEditTeamsClick}> Edit Teams</h1>

                <img src={arrowback} alt="back" onClick={handleBack} className='flex h-[30px] w-[30px] absolute left-0 hover:cursor-pointer' />
            </div>

            <div className=" flex items-center justify-center w-[100%] sm:w-[100%] md:w-[100%] lg:w-[750px] h-[220px] sm:h-[110px] md:h-[110px] lg:h-[110px] mt-12">

                <div className="  flex w-[100%] sm:w-[100%] md:w-[80%] lg:w-[750px] h-[220px] sm:h-[110px] md:h-[110px] lg:h-[110px] bg-[#f0f0f0] items-center justify-between flex-col sm:flex-row  md:flex-row lg:flex-row p-[5px] pr-3 rounded-[10px] border border-[#7e7e7e]">
                    <div className="flex lg:w-[45%] md:w-[45%] sm:w-[45%] w-[100%] h-[100%] items-center ">


                        <div className="flex h-[100px] w-[100px] rounded-[8px] relative ">
                            <img
                                src={teamLogo ? `${S3_BASE_URL}${teamLogo}` : PlayIcon}
                                alt="logo" className="h-full w-full object-cover rounded-[8px]" />
                            <div className="flex items-center justify-center w-[25px] h-[25px] rounded-full bg-white absolute right-[-3px] top-[-3px] cursor-pointer">
                                <FaRegEdit className="h-[15px] w-[15px] absolute right-[3px] top-[3px]" />
                                <input
                                    type="file"
                                    className="absolute w-[25px] h-[25px] rounded-full opacity-0 cursor-pointer"
                                    name="team-logo-upload"
                                    onChange={fileChanged}
                                    accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                />
                            </div>
                        </div>
                        <div className=" flex justify-start ml-3 h-[100%] flex-col">
                            <span className='text-sm font-semibold mb-1'>Members {selectedTeamInfo && selectedTeamInfo.members.length}</span>
                            <p className='md:text-base sm:text-sm text-base lg:text-lg  text-[#7e7e7e]'>Team <br></br><span className='md:text-lg sm:text-base text-base lg:text-xl font-bold text-themeColor text-[]'>
                                {selectedTeamInfo ? selectedTeamInfo.name : ''}
                            </span> </p>

                        </div>

                    </div>

                    <div className="flex w-[100%] lg:w-[55%] md:w-[55%] sm:w-[55%] h-[100%] justify-start flex-col mt-2 sm:mt-0 md:mt-0 lg:mt-0">
                        <div className="flex w-[100%] h-[40px] relative items-center  border border-[#7e7e7e] mb-0 bg-white">
                            <input type="text" className='team-members-search' placeholder='Assign a captain '
                                onChange={(e) => setTeamCaptainSearch(e.target.value)}
                                value={teamCaptainSearch}
                            />
                            <FaSearch className=' absolute right-[10px] h-[23px] w-[23px]' />
                        </div>

                        <div className="fex items-center justify-center w-[100%] max-h-[60px] overflow-y-scroll no-scrollbar pt-1">
                            {
                                teamMembers && teamMembers.length > 0 &&
                                teamMembers.map((member, index) => {
                                    return (
                                        <div className="flex cursor-pointer items-center justify-between w-[100%] h-[40px] pr-3 pl-3 hover:bg-hoverThemeColor bg-[#fcfcfc] text-[#5f5f5f] mt-2 " >
                                            <div className="flex items-center  w-[100%] h-[100%] flex-row">
                                                <FaUserCircle className=' mr-3 h-[20px] w-[20px]' />
                                                <span>{member.firstName}</span>
                                                <span className='ml-[5px]'>{member.lastName}</span>


                                            </div>
                                            <div className=" flex h-[30px] w-[40px] items-center justify-center"
                                            //  onClick={(e) => { e.stopPropagation(); handleTeamCaptainSelection(member.id); }}
                                            >
                                                <label class="checkbox-label">
                                                    <input type="checkbox" class="checkbox-tow"
                                                        checked={getSelectedTeamCaptainChecked(member.id)}
                                                        onClick={(e) => { e.stopPropagation(); handleTeamCaptainSelection(member.id); }}
                                                    />
                                                    <div class="svg-icon">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            height="16px"
                                                            viewBox="0 0 448 512"
                                                        >
                                                            <path
                                                                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                    <span class="check-box-container"></span>
                                                </label>
                                            </div>
                                            <div className=" flex h-[30px] w-[30px] items-center justify-center mr-2">
                                                <div className="animated-btn-tooltip" style={{ width: '25px', height: '25px' }} >


                                                    <button class="session-delete-button"
                                                        style={{ width: '25px', height: '25px' }}
                                                        onClick={(e) => handleDeleteUserModal(member.id)}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 69 14"
                                                            class="svgIcon bin-top"
                                                        >
                                                            <g clip-path="url(#clip0_35_24)">
                                                                <path
                                                                    fill="black"
                                                                    d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                                                ></path>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_35_24">
                                                                    <rect fill="white" height="14" width="69"></rect>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            fill="none"
                                                            viewBox="0 0 69 57"
                                                            class="svgIcon bin-bottom"
                                                        >
                                                            <g clip-path="url(#clip0_35_22)">
                                                                <path
                                                                    fill="black"
                                                                    d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                                                ></path>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_35_22">
                                                                    <rect fill="white" height="57" width="69"></rect>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </button>
                                                    <div className="hover-tooltip" role="tooltip"
                                                        style={{
                                                            top: '-18px',
                                                            left: '12px'
                                                        }}
                                                    >
                                                        <span
                                                            style={{
                                                                fontSize: '7px'
                                                            }}
                                                        >Delete</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }



                        </div>
                    </div>
                </div>


            </div>

            <div className="flex w-[100%] items-center lg:items-start justify-center lg:flex-row mt-10 flex-col">


                <div className=" flex w-[100%] md:w-[80%] lg:w-[260px] h-[400px] rounded-[5px] border border-[#7e7e7e] flex-col">

                    <h1 className=' flex items-center justify-center text-center h-[50px] w-[100%] text-lg bg-[#f3f3f3] font-semibold'>Select Team To Edit</h1>
                    <div className="fex items-center justify-center w-[100%] overflow-y-scroll no-scrollbar">

                        <div className={`flex items-center justify-center h-[35px] pr-3 pl-3 border-b border-[#7e7e7e]`}
                        >
                            <IoIosAddCircle style={{
                                width: '25px',
                                height: '25px',
                                cursor:'pointer'
                            }}
                            onClick={(e) => handleAddNewTeamModal()}

                                fill='var(--color-theme)'
                            />

                        </div>

                        {
                            teams && teams.length > 0 && teams.map((team, index) => {
                                return (
                                    <div className={`flex cursor-pointer items-center justify-between w-[100%] h-[35px] pr-3 pl-3 border-b border-[#7e7e7e] hover:bg-hoverThemeColor  ${selectedTeamInfo && selectedTeamInfo !== "" && selectedTeamInfo.id === team.id ? ' bg-themeColor hover:bg-themeColor text-white' : ''} `} key={index} onClick={(e) => handleTeamSelection(team)}>
                                        <span>{team.name}</span>
                                        {selectedTeamInfo && selectedTeamInfo !== "" && selectedTeamInfo.id === team.id ? (
                                            <MdOutlineModeEditOutline className={` h-[20px] w-[20px] ${selectedTeamInfo && selectedTeamInfo !== "" && selectedTeamInfo.id === team.id ? 'fill-white' : 'fill-black'}`} />
                                        ) : null
                                        }
                                    </div>
                                )
                            })
                        }



                    </div>
                </div>


                <div className="flex w-[100%] md:w-[80%] lg:w-[450px] items-center justify-start flex-col lg:ml-10 min-h-[120px] max-h-[400px] mt-[30px] lg:mt-0">
                    <div className="flex w-[100%] h-[40px] relative items-center  border border-[#7e7e7e] mb-0">
                        <input type="text" className='team-members-search' placeholder='Find Members '
                            onChange={(e) => setTeamMemberSearch(e.target.value)}
                            value={teamMemberSearch}
                        />
                        <FaSearch className=' absolute right-[20px] h-[23px] w-[23px]' />
                    </div>

                    <div className="fex items-center justify-center w-[100%] overflow-y-scroll no-scrollbar h-auto max-h-[320px]">
                        {
                            orgUsers && orgUsers.length > 0 &&
                            orgUsers.map((member, index) => {
                                return (
                                    <div className="flex cursor-pointer items-center justify-between w-[100%] h-[40px] pr-3 pl-3 hover:bg-hoverThemeColor bg-[#fcfcfc] text-[#5f5f5f] mt-2" onClick={(e) => { e.stopPropagation(); handleTeamChange(member.id, selectedTeamInfo.id) }}>
                                        <div className="flex items-center w-[80%] h-[100%] flex-row">
                                            <FaUserCircle className=' mr-3 h-[20px] w-[20px] ' />
                                            <span className=' text-xs sm:text-sm md:text-base lg:text-base'>{member.firstName}</span>
                                            <span className='ml-[5px] text-xs sm:text-sm md:text-base lg:text-base'>{member.lastName}</span>

                                        </div>
                                        <div className="flex h-[30px] w-[30px] items-center justify-center" onClick={(e) => e.preventDefault()}>
                                            <label class="checkbox-label">
                                                <input type="checkbox" class="checkbox-tow"
                                                    checked={getSelectedChekced(member.id)}
                                                    onClick={(e) => { e.stopPropagation(); handleTeamChange(member.id, selectedTeamInfo.id) }}
                                                />
                                                <div class="svg-icon">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        height="16px"
                                                        viewBox="0 0 448 512"
                                                    >
                                                        <path
                                                            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                        ></path>
                                                    </svg>
                                                </div>
                                                <span class="check-box-container"></span>
                                            </label>
                                        </div>
                                    </div>
                                )
                            })
                        }


                    </div>{
                        orgUsers && orgUsers.length > 1 ? (
                            <div className="flex cursor-pointer flex-row-reverse items-center justify-between w-[100%] h-[40px] pr-3 pl-3 bg-[#fcfcfc] text-[#5f5f5f]  mt-0">
                                <span
                                    className='hover:font-semibold hover:border-b-2 cursor-pointer border-b border-[#5f5f5f]'
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSelectAllTeamUsers()

                                    }}
                                >{isAllOrgUsersSelected ? 'Deselect All' : 'Select All'}</span>

                            </div>
                        ) : null
                    }

                </div>

                <div className="flex flex-col lg:mr-[-230px] lg:ml-10 lg:h-[100%] lg:justify-end">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleSaveChanges}
                        style={{
                            opacity: selectedTeamUsersData && selectedTeamUsersData.length > 0 || teamCaptainData && teamCaptainData.userId !== null && teamCaptainData.userId !== selectedTeamInfo.teamCaptainId || teamLogo && teamLogo !== selectedTeamInfo?.teamLogo ? '1' : '0.5',
                            cursor: selectedTeamUsersData && selectedTeamUsersData.length > 0 || teamCaptainData && teamCaptainData.userId !== null && teamCaptainData.userId !== selectedTeamInfo.teamCaptainId || teamLogo && teamLogo !== selectedTeamInfo?.teamLogo ? 'pointer' : ''
                        }}
                        disabled={selectedTeamUsersData && selectedTeamUsersData.length > 0 || teamCaptainData && teamCaptainData.userId !== null && teamCaptainData.userId !== selectedTeamInfo.teamCaptainId || teamLogo && teamLogo !== selectedTeamInfo?.teamLogo ? false : true}

                    > Save Changes</button>

                    <button
                        type="button"
                        className="btn btn-primary mt-8"
                        onClick={handleScheduleGame}
                        disabled={teams && teams.length > 0 ? false : true}

                    > Schedule Team Games</button>
                </div>




            </div>

            {/* <div className="flex items-center justify-center w-[100%] mt-5">
                <button
                    type="button"
                    className="btn btn-primary"
                // onClick={handleUpdateTeam}
                // style={{

                //     opacity: !teamImg && selectedTeamUser.length === 0 && selectedTeamMembers.length == 0 && selectTeamCaptain.userId === selectedTeam.teamCaptainId || teamImg === selectedTeam.teamLogo && selectedTeamUser.length === 0 && selectedTeamMembers.length == 0 && selectTeamCaptain.userId === selectedTeam.teamCaptainId ? '0.5' : 1,
                //     cursor: !teamImg && selectedTeamUser.length === 0 && selectedTeamMembers.length == 0 && selectTeamCaptain.userId === selectedTeam.teamCaptainId || teamImg === selectedTeam.teamLogo && selectedTeamUser.length === 0 && selectedTeamMembers.length == 0 && selectTeamCaptain.userId === selectedTeam.teamCaptainId ? 'not-allowed' : 'pointer',

                // }}
                // disabled={!teamImg && selectedTeamUser.length === 0 && selectedTeamMembers.length == 0 && selectTeamCaptain.userId === selectedTeam.teamCaptainId || teamImg === selectedTeam.teamLogo && selectedTeamUser.length === 0 && selectedTeamMembers.length == 0 && selectTeamCaptain.userId === selectedTeam.teamCaptainId ? true : false}

                > Save Changes</button>
            </div> */}

            {
                openDeleteUserModal &&
                <DeleteTeamModal toggle={openDeleteUserModal} setOpenAddTeamModal={setOpenDeleteUserModal} teamId={deleteUserId} handleDeleteFun={handleDeleteUser} textInfo={" Are you sure you want to remove this user from team?"} />
            }
            {
                openAddTeamModal &&
                <AddTeamModal modalid={"usersAddedModal"} setOpenAddTeamModal={setOpenAddTeamModal} toggle={openAddTeamModal} />
            }

        </div >


    </>

    );
};
export default EditTeams;
