import React from 'react';
import { Link } from "react-router-dom";

import './breadcrumb.css';

const BreadCrumb = (props) => {
  return (
    <div className="breadcrumb">
      <Link to="">Home</Link>
      {
        props.breadCrumbPath === "category" &&
        (<Link to="/category/All" >Category</Link>)
      }
      {
        props.breadCrumbPath === "objectives" &&
        (<Link to="/objective/All" >Objectives</Link>)
      }
      {
        props.objective &&
        <Link to="/objective/All" >Objectives</Link>
      }
      {
        props.category &&
        <Link to="/category/All" >Category</Link>
      }
            {
        props.breadCrumbPath === "category" && props.subBreadCrumbPath && (<>
          <Link to={`/category/${props.subBreadCrumbPath}`}>{props.subBreadCrumbPath}</Link></>)
      }
      {
        props.breadCrumbPath === "objectives" && props.subBreadCrumbPath && (<>
          <Link to={`/objective/${props.subBreadCrumbPath}`}>{props.subBreadCrumbPath}</Link></>)
      }
      {/* {
        props.gameDetail?.data?.category?.[0] && (<>
          <Link to={`/category/${props.gameDetail.data.category[0].title}`}>{props.gameDetail.data.category[0].title}</Link></>)
      } */}
      {
        props.fromManageGames &&
        <Link to="/manage-games" >Manage Games</Link>
      }
      {
        props.webinar &&
        <Link to={"/webinar/history"}>PlayStream</Link>
      }
      <Link to={"#"} className="active">{props.gameDetail && props.gameDetail.data && props.gameDetail.data.title}</Link>
    </div>
  );
};

export default BreadCrumb;