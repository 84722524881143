import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./index.css";
import "./AddUserTeams.css"
//image
import excel from "../../assets/images/excel.svg";
import uploadwhite from "../../assets/images/uploadwhite.svg";
import deleteButton from "../../assets/images/deleteWithFrame.svg";
import Confirm from "../modal/confirm";
import SampleCsv from "../modal/samplecsv";
import ManageTeamAddEditUserModal from "../modal/manageTeamAddEditUsermodal";
import {
    addUsersWithDummyEmailOrg,
    getMasterOtpOrganisation,
    getOrganisationUsers,
    setMasterOtpOrganisation,
    updateOrganisation
} from "../../redux/actions/organisationActions";
import { useDispatch, useSelector } from "react-redux";
import {
    downloadEmployeeDatabase,
    uploadEmployeeDetails,
    uploadFile
} from "../../redux/actions/commonActions";
import { getOrganisation } from "../../redux/actions/plansApiActions";
import { BASE_URL, S3_BASE_URL, axiosApiInstance, decryptData, preventNonNumericalInput } from "../../helpers/helper";
import DeleteDomainModal from "../modal/deleteDomainModal";
import { ToastContainer } from "react-toastify";
import { successAlert, failureAlert } from "../../helpers/helper";
import LoadingComponent from "../loader/LoadingComponent";
import { EMPLOYEE_DATABASE_UPLOAD_SUCCESS, FILE_UPLOAD_SUCCESS } from "../../redux/constants/commonApiConstants";
import ROLES from "../../helpers/userTypes";
import { downloadFile } from "../../helpers/downloadFile";
import 'font-awesome/css/font-awesome.min.css';
import UsersAddedModal from "../modal/UsersAddedModal";

import { FaUsersLine } from "react-icons/fa6";
import AddTeamModal from "../modal/addTeamModal";
import { data } from "synonyms/dictionary";
import EditTeams from "./EditTeams";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getOrgTeams } from "../../redux/actions/teamAction";
import DeleteTeamModal from "../modal/deleteTeamModal";
import { logOut } from "../../redux/actions/userAction";
import Header from "../header/header";

import { BsUpload } from "react-icons/bs";





const AddUserTeam = (props) => {
    const [openAddEditUserModal, setOpenAddEditUserModal] = useState(false);
    const [file, setFile] = useState(null);
    const [dummyUsersFile, setDummyUsersFile] = useState(null);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [openConfirmUploadModal, setOpenConfirmUploadModal] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [yesFlag, setYesFlag] = useState(false);
    const [domainName, setDomainName] = useState("");
    const [csvError, setCsvError] = useState("");
    const [domainNameError, setDomainNameError] = useState("");
    const [existingDomains, setExistingDomains] = useState([]);
    const [openDeleteDomainModal, setOpenDeleteDomainModal] = useState(false);
    const [deleteDomainName, setDeleteDomainName] = useState("");
    const [dummyUserdetails, setDummyUserDetails] = useState({ noOfUsers: '', domain: '', otp: '' });
    const [addDummyUsersClicked, setAddDummyUsersClicked] = useState(false);
    const [touched, setTouched] = useState({ noOfUsers: false, domain: false, otp: false, file: false });
    const [byUploadingFile, setByUploadingFile] = useState(false);
    const [openUsersAddedModal, setOpenUsersAddedModal] = useState(false);
    const [modalOne, setModalOne] = useState(false);
    const [modalTwo, setModalTwo] = useState(false);
    const [addDeteletedUser, setAddDeteletedUser] = useState({ modal: false, action: false, path: "" });
    const [toggalDeleteTeamModal, setToggalDeleteTeamModal] = useState(false)
    const [deleteTeamId, setDeleteTeamId] = useState('');
    const [isUserLimitExist, setIsUserLimitExist] = useState(false)

    const [openAddTeamModal, setOpenAddTeamModal] = useState(false)
    const [organizationTeams, setOrgnizationTeams] = useState([]);
    const [openEditTeam, setOpenEditTeam] = useState(false);


    const getAllOrgsTeam = useSelector((state) => state.getOrgsTeams);
    const { orgTeams } = getAllOrgsTeam;


    const dispatch = useDispatch();

    const UploadedFile = useSelector((state) => state.uploadedFile);
    const UploadedEmployeeDetails = useSelector((state) => state.uploadEmployeeDatabase);
    const { uploadedEmployeeDetails } = UploadedEmployeeDetails;
    const GetOrganisation = useSelector((state) => state.getOrganisation);
    const { orgDetailsByEmail } = GetOrganisation;
    const { userInfo } = useSelector((state) => state.getUser);
    const UpdateOrganisation = useSelector((state) => state.updateOrganisation);
    const CreateSamplecsv = useSelector(state => state.createSamplecsv);
    const OrgUsers = useSelector(state => state.orgUsers);
    const { orgUsers } = OrgUsers;
    const { myPlanDetails } = useSelector(state => state.getMyPlans);
    const AddUsersDummyEmail = useSelector(state => state.addUsersDummyEmail);
    const GetMasterOtp = useSelector(state => state.getMasterOtp);
    const { getMasterOtp } = GetMasterOtp;
    const SetMasterOtp = useSelector(state => state.setMasterOtp);

    const masterOtpRef = React.createRef();
    const history = useHistory();

    const DownloadEmployeeDatabase = useSelector(
        (state) => state.downloadEmployeeDatabase
    );

    const addMultyUserReff = useRef(null)

    const handleButtonClick = () => {
        addMultyUserReff.current.click();
    };

    useEffect(() => {
        if (orgUsers && myPlanDetails) {
            if (myPlanDetails.data && myPlanDetails.data.planDetail && orgUsers?.paginationData?.totalEntries >= myPlanDetails.data.planDetail.plan.userLimit) {
                setIsUserLimitExist(true)
            }
            else {
                setIsUserLimitExist(false)
            }

        }
    }, [OrgUsers, myPlanDetails])

    useEffect(() => {
        if (userInfo && userInfo.data && userInfo.data.email)
            dispatch(getOrganisation(userInfo.data.email));
        dispatch(getOrganisationUsers(""));
        return (() => {
            dispatch({ type: FILE_UPLOAD_SUCCESS, payload: null, });
            dispatch({ type: EMPLOYEE_DATABASE_UPLOAD_SUCCESS, payload: null, });
        })
    }, []);
    useEffect(() => {
        if (orgDetailsByEmail && orgDetailsByEmail.data) {
            dispatch(getMasterOtpOrganisation(orgDetailsByEmail?.data?.id));

            if (orgDetailsByEmail.data.restrictedDomain) setYesFlag(true);
            else setYesFlag(false);
            var domains = [];
            orgDetailsByEmail.data.allowedDomains.map((domain) => {
                domains.push(domain.name);
                return domain;
            });
            setExistingDomains(domains);
        }
    }, [orgDetailsByEmail]);

    useEffect(() => {
        if (getMasterOtp?.masterOtp) {
            const decryptedOtp = decryptData(getMasterOtp.masterOtp);
            if (decryptedOtp)
                setDummyUserDetails(prevState => ({
                    ...prevState,
                    otp: decryptedOtp
                }))
        }
    }, [getMasterOtp])

    const fileChanged = (e, isDummyUsersFile) => {
        e.persist();
        const inputFile = e.target.files[0];
        e.target.value = [];
        if (!isDummyUsersFile) {
            setFile(inputFile);
            setOpenConfirmUploadModal(true);
        }
        else {
            setDummyUsersFile(inputFile);
        }
    };
    useEffect(() => {
        async function callBack() {
            if (uploadFlag) {
                if (file) {
                    const fileData = new FormData();
                    fileData.append("sheets", file);
                    const { status, data } = await dispatch(uploadFile(fileData, true));
                    if (status === 200) {
                        if (data && data.data && data.data.path) {
                            const { path } = data.data;
                            const checkUserActivity = await axiosApiInstance.post(BASE_URL + '/api/employee-database/check', { filePath: path.replace(BASE_URL + "/", "") })
                            if (checkUserActivity?.data?.data?.length) {
                                setAddDeteletedUser({ modal: true, action: false, path: path })
                            } else {
                                const response = await dispatch(uploadEmployeeDetails(path.replace(BASE_URL + "/", "")));
                                const responsecode = response.status;
                                if (responsecode === 200 && userInfo && userInfo.data) {
                                    const { data } = response
                                    // successAlert(`File Uploaded Successfully, ${data.userCount && data.userCount} ${data.userCount && data.userCount <= 1 ? "user" : "users"} invited.`)
                                    setOpenUsersAddedModal(true);
                                    dispatch(getOrganisation(userInfo.data.email));
                                    setCsvError("");
                                } else if (
                                    response?.status === 500 &&
                                    response?.data?.message ===
                                    "USER EXISTS IN OTHER ORGANIZATION"
                                ) {
                                    setCsvError("* Duplicate Entry in file, Some Users already exists in some other organisation");
                                }
                                else if (response && response.data && response.data.message === "USER LIMIT EXCEEDED") {
                                    failureAlert("User limit exceeds");
                                }
                                else {
                                    failureAlert("File upload failed due to an error in the file");
                                }
                            }
                        }
                    }
                    else if (status?.includes?.("417"))
                        failureAlert("Uploaded file contains some malware!");
                    else if (status?.includes?.("500"))
                        failureAlert("File Format Not supported");
                    setFile(null);
                }
                setUploadFlag(false);
            }
        }
        callBack();
    }, [uploadFlag]);

    useEffect(() => {
        async function callBack() {
            const response = await dispatch(uploadEmployeeDetails(addDeteletedUser?.path.replace(BASE_URL + "/", "")));
            const responsecode = response.status;
            if (responsecode === 200 && userInfo && userInfo.data) {
                setOpenUsersAddedModal(true);
                dispatch(getOrganisation(userInfo.data.email));
                setCsvError("");
            } else if (
                response?.status === 500 &&
                response?.data?.message ===
                "USER EXISTS IN OTHER ORGANIZATION"
            ) {
                setCsvError("* Duplicate Entry in file, Some Users already exists in some other organisation");
            }
            else if (response && response.data && response.data.message === "USER LIMIT EXCEEDED") {
                failureAlert("User limit exceeds");
            }
            else {
                failureAlert("File upload failed due to an error in the file");
            }
        }
        if (addDeteletedUser?.path) {
            callBack();
        }
    }, [addDeteletedUser?.action]);

    useEffect(() => {
        if (
            (UploadedFile && UploadedFile.loading) ||
            (GetOrganisation && GetOrganisation.loading) ||
            (UpdateOrganisation && UpdateOrganisation.loading) ||
            (UploadedEmployeeDetails && UploadedEmployeeDetails.loading) ||
            (DownloadEmployeeDatabase && DownloadEmployeeDatabase.loading) ||
            (CreateSamplecsv && CreateSamplecsv.loading) ||
            (AddUsersDummyEmail && AddUsersDummyEmail.loading) ||
            (GetMasterOtp && GetMasterOtp.loading) ||
            (SetMasterOtp && SetMasterOtp.loading)
        ) {
            setLoaded(false);
        } else setLoaded(true);
    }, [
        UploadedFile,
        UploadedEmployeeDetails,
        GetOrganisation,
        UpdateOrganisation,
        DownloadEmployeeDatabase,
        CreateSamplecsv,
        AddUsersDummyEmail,
        GetMasterOtp,
        SetMasterOtp
    ]);
    const handleDownload = async () => {
        const response = await dispatch(downloadEmployeeDatabase());
        if (response && response.data && response.data.data && response.data.data.downloadUrl)
            downloadFile(response.data.data.downloadUrl);
    };
    const uploadDate = (date) => {
        return `${date.slice(8, 10)}/${date.slice(5, 7)}/${date.slice(0, 4)}`;
    };
    const updateDomainRestriction = (flag) => {
        setYesFlag(flag);
        if (orgDetailsByEmail && orgDetailsByEmail.data)
            dispatch(
                updateOrganisation(orgDetailsByEmail.data.id, {
                    restrictedDomain: flag
                })
            );
    };
    const handleChange = (e) => {
        const { value } = e.target;
        const domainFormat = /^@[\(\)\[\]a-zA-Z0-9\.\`\~\!\#\$\%\^\&\*\_\=\+\'-]+\.[a-zA-Z0-9]+$/;
        if (value.length === 1)
            setDomainNameError("Domain name length should be minimum 2");
        else if (existingDomains.includes(value))
            setDomainNameError("Domain name already exists");
        else if (value.length > 0 && !domainFormat.test(value)) {
            setDomainNameError("Insert the domain in the requested format.");
        } else setDomainNameError("");
        setDomainName(value);
    };
    const addDomain = async (e) => {
        e.preventDefault();
        if (domainNameError !== "" || domainName === "") return;
        const newDomain = { name: domainName };
        if (orgDetailsByEmail && orgDetailsByEmail.data) {
            var allowedDomains = [];
            orgDetailsByEmail.data.allowedDomains.map((domain) => {
                allowedDomains.push({ name: domain.name, createdAt: domain.createdAt });
                return domain;
            });
            const responsecode = await dispatch(
                updateOrganisation(orgDetailsByEmail.data.id, {
                    allowedDomains: [...allowedDomains, newDomain]
                })
            );
            if (responsecode && responsecode.status === 200 && userInfo && userInfo.data) {
                successAlert("Domain added successfully.")
                dispatch(getOrganisation(userInfo.data.email));
                setDomainName("");
            }
            else if (responsecode && responsecode.status === 400 && responsecode.data && responsecode.data.message === "DOMAIN_ALREADY_EXIST") {
                setDomainNameError("This domain name is already taken by some other organisation")
            }
        }
    };
    const handleDeleteDomain = (e, domain) => {
        e.preventDefault();
        setDeleteDomainName(domain);
        setOpenDeleteDomainModal(true);
    };
    const removeDomain = async (domainName) => {
        if (orgDetailsByEmail && orgDetailsByEmail.data) {
            var allowedDomains = [];
            orgDetailsByEmail.data.allowedDomains.map((domain) => {
                if (domainName !== domain.name)
                    allowedDomains.push({
                        name: domain.name,
                        createdAt: domain.createdAt
                    });
                return domain;
            });
            const responsecode = await dispatch(
                updateOrganisation(orgDetailsByEmail.data.id, {
                    allowedDomains: allowedDomains
                })
            );
            if (responsecode && responsecode.status === 200 && userInfo && userInfo.data) {
                successAlert("Domain Removed Successfully.")
                dispatch(getOrganisation(userInfo.data.email));
                setDomainName("");
            }
            setOpenDeleteDomainModal(false);
        }
    };

    const handleChangeData = (e) => {
        const { value, name } = e.target;
        if (name === 'otp' && value.length > 6)
            return;
        setDummyUserDetails(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const handleBlur = (e) => {
        const { name } = e.target;
        setTouched(prevState => ({
            ...prevState,
            [name]: true
        }));
    }
    const handleDummyUserSubmit = (e) => {
        e.preventDefault();
        setTouched({ noOfUsers: true, domain: true, otp: true, file: true });
        setAddDummyUsersClicked(true);
    }
    useEffect(() => {
        const callBack = async () => {
            if (addDummyUsersClicked) {
                if (JSON.stringify(validate()) === JSON.stringify({ noOfUsers: '', domain: '', otp: '', file: '' }) && orgDetailsByEmail?.data?.id) {
                    const body = {
                        organizationId: orgDetailsByEmail.data.id,
                        domain: dummyUserdetails.domain,
                    };
                    if (byUploadingFile) {
                        const fileData = new FormData();
                        fileData.append("temp-files", dummyUsersFile);
                        const { status, data } = await dispatch(uploadFile(fileData, true));
                        if (status === 200 && data?.data) {
                            const { path } = data.data;
                            body.filePath = path.replace(BASE_URL + "/", "");
                        }
                        else {
                            failureAlert("Something went wrong!");
                            return;
                        }
                    }
                    else
                        body.userCount = parseInt(dummyUserdetails.noOfUsers);

                    if (!getMasterOtp?.masterOtp)
                        body.masterOtp = dummyUserdetails.otp;
                    const response = await dispatch(addUsersWithDummyEmailOrg(body, byUploadingFile));
                    if (response?.data?.status === false)
                        failureAlert(response?.data?.message);
                    else if (response?.status === 200) {
                        downloadFile(response?.data?.downloadLink?.downloadUrl);
                        successAlert("Users Added SuccessFully, check the file for details.");
                        setDummyUserDetails({ domain: '', noOfUsers: '', otp: '' });
                        setTouched({ domain: false, noOfUsers: false, otp: false, file: false });
                        dispatch(getMasterOtpOrganisation(orgDetailsByEmail?.data?.id));
                        setByUploadingFile(false);
                        setDummyUsersFile(null);
                    }
                    else {
                        failureAlert("Something went wrong!");
                    }
                }
                setAddDummyUsersClicked(false);
            }
        }
        callBack();
    }, [addDummyUsersClicked]);

    const handleUpdateMasterOtp = async (e) => {
        e.preventDefault();
        if (orgDetailsByEmail && dummyUserdetails.otp.length === 6) {
            const response = await dispatch(setMasterOtpOrganisation(orgDetailsByEmail.data?.id, { otp: dummyUserdetails.otp }));
            if (response?.status === 200) {
                dispatch(getMasterOtpOrganisation(orgDetailsByEmail?.data?.id));
                successAlert("Otp updated successfully");
            }
            else
                failureAlert("Something went wrong!");
        }
    }

    const validate = () => {
        const errors = {
            noOfUsers: '',
            domain: '',
            otp: '',
            file: ''
        };
        if (touched.noOfUsers && !dummyUserdetails.noOfUsers && !byUploadingFile)
            errors.noOfUsers = "No of users should be given";
        const userLimit = myPlanDetails?.data?.planDetail?.plan?.userLimit - orgUsers?.data?.length;
        if (touched.noOfUsers && dummyUserdetails.noOfUsers && userLimit && dummyUserdetails.noOfUsers > userLimit && !byUploadingFile)
            errors.noOfUsers = "No of users exceeding user limits";
        const domainFormat = /^@[\(\)\[\]a-zA-Z0-9\.\`\~\!\#\$\%\^\&\*\_\=\+\'-]+\.[a-zA-Z0-9]+$/;
        if (touched.domain && !domainFormat.test(dummyUserdetails.domain))
            errors.domain = "Domain should be in given format";
        if (touched.otp && (!dummyUserdetails.otp || dummyUserdetails.otp.length !== 6))
            errors.otp = "Please enter valid otp";
        if (touched.file && !dummyUsersFile && byUploadingFile)
            errors.file = "Please select any file";
        return errors;
    }
    const errors = validate();


    useEffect(() => {
        if (orgTeams && orgTeams.message === "Sucessfully fetch org teams") {
            setOrgnizationTeams(orgTeams.data);

        }
        else if (orgTeams && orgTeams.message === "org teams not available") {
            setOrgnizationTeams([]);
        }
    }, [getAllOrgsTeam])

    const handleAddField = () => {
        // setInputFields([...inputFields, '']);
        setOpenAddTeamModal(true)
    };

    const handleManageTeams = () => {
        history.push(`manage-edit-teams`)
    }

    const handleUserData = () => {
        history.push(`manage-users-data`)
    }



    // const handleInputChange = (index, value) => {
    //     const newInputFields = [...inputFields];
    //     newInputFields[index] = value;
    //     setInputFields(newInputFields);
    // };

    // const handleDeleteTeamField = async (e, index) => {
    //     e.preventDefault();
    //     if (inputFields.length > 1) {
    //         setInputFields(inputFields.filter((field, i) => i !== index));

    //     }
    // }

    useEffect(() => {
        if (userInfo && userInfo.data && userInfo.data.organizationId) {
            dispatch(getOrgTeams(userInfo.data.organizationId))

        }

    }, [userInfo])

    const handleTaamDeleteClick = (teamId) => {
        setDeleteTeamId(teamId)
        setToggalDeleteTeamModal(true)
    }


    const handleDeleteTeam = async (teamId) => {
        setLoaded(false)
        if (!teamId) {
            return failureAlert("failed to delete team")
        }
        const body = {
            teamId: teamId
        }
        const response = await axiosApiInstance.post(BASE_URL + "/api/organization-team/delete-team", body);
        if (response && response.data && response.data.message === "sucessfully deleted team") {
            successAlert("Sucessfully deleted team")
            if (userInfo && userInfo.data && userInfo.data.organizationId) {
                dispatch(getOrgTeams(userInfo.data.organizationId))
            }
        }
        else {
            failureAlert("failed to delete team")
        }

        setDeleteTeamId('')
        setToggalDeleteTeamModal(false)
        setLoaded(true)
    }

    const signOut = async () => {
        await dispatch(logOut());
        if (props.history)
            props.history.push("/");

    };

    return (
        <div>
            <Header signOut={signOut} profile />
            <LoadingComponent loaded={loaded} />
            <ToastContainer position="bottom-center" />
            <main className="container conatiner-960 "
                style={{ width: '100%', maxWidth: '100%' }}
            >
                <div className="manageteam-wrapper">
                    <div className="flex items-center justify-center w-full">
                        <h5 className=" text-[26px] md:text-[48px] lg:text-[48px] text-[#23282E] font-semibold">Manage Team</h5>
                    </div>

                    <div className="employedata-wrapper add-user-wrapper mt-[30px]">
                        <div className="main-users-teams-container" >
                            <div className="add-users-teams-container">

                                <div className="flex flex-row md:flex-row lg:flex-row w-[100%] bg-cover bg-no-repeat p-[25px] justify-between bg-[#FDF1F3] rounded-[6px] relative"
                                style={{
                                background: 'linear-gradient(90deg, #F5899E 0%, #F7C2B5 100%)'
                                }}
                                >
                                     <div className="flex h-[120px] w-[120px] rounded-full bg-white absolute opacity-[0.2] right-[-0px] bottom-[-50px]"></div>
                                     <div className="flex h-[80px] w-[80px] rounded-full bg-white absolute opacity-[0.2] right-[-3px] top-[-10px]"></div>
                                    <div className="flex h-[100%] flex-col">
                                        <h4>Add Single User</h4>
                                        <p className="pr-[20px] md:pr-0 lg:pr-0">You can add a single user to your organisation</p>
                                    </div>
                                    <div className="flex flex-row-reverse items-center mt-3 sm:mt-0 md:mt-0 lg:mt-0 w-[100%] sm:w-auto md:w-auto lg:w-auto justify-start btn-tooltip ">
                                        <button
                                            className={`btn btn-teams-secondry bg-white w-[100px] sm:w-[120px] md:w-[120px] h-[32px] lg:w-[120px] sm:h-[32px] md:h-[32px] lg:h-[32px] text-[14px]   ${isUserLimitExist ? `disabled` : ``}`}
                                            disabled={isUserLimitExist}
                                            onClick={() => setOpenAddEditUserModal(true)}
                                        >
                                            +Add User
                                        </button>
                                        {isUserLimitExist &&
                                            <div className="tooltip" role="tooltip" >
                                                <span style={{ fontSize: '11px' }}>User limit exceeded. Please contact the admin.</span>
                                            </div>
                                        }
                                    </div>
                                </div>

                                <div className="flex flex-col p-[25px] w-[100%] bg-cover bg-no-repeat items-start justify-end mt-[26px] bg-[#DBF1F9] rounded-[6px] relative overflow-hidden"
                                
                                style={{
                                     background: 'linear-gradient(90deg, #4587DC 0%, #9DC9F5 100%)'
                                }}>
                                                                                <div className="flex h-[165px] w-[165px] rounded-full bg-[#FFFFFF33] absolute right-[-15px] bottom-[-30px]"></div>
                                            <div className="flex h-[105px] w-[105px] rounded-full bg-[#FFFFFF33] absolute right-[-17px] top-[-5px]"></div>

                                    <h4>Add Multiple Users</h4>
                                    <div className="flex flex-row mt-1">

                                        <h4 style={{ fontSize: '14px', marginRight: '10px', color:'white' }}>Sample CSV file</h4>
                                        <Link onClick={() => setModalOne(true)} to={'#'}>
                                            <img src={excel} alt="csv"/>
                                            <span style={{ fontSize: '14px', color: 'white', marginLeft: '3px', fontWeight: '200', textDecoration: 'underline' }}>View Sample</span>
                                        </Link>
                                    </div>
                                    <div className="flex flex-row justify-between  w-[100%] rounded-[6px] bg-white mt-[10px] p-[13px] relative">
                                        <span className=" text-[10px] text-[#23282E] opacity-[0.5] flex justify-center items-center">Upload CSV file to add users</span>
                                        <div className="flex items-center justify-center h-[100%] btn-tooltip">
                                            <button type="submit"
                                                className={` btn btn-secondry w-[100px] sm:w-[120px] md:w-[120px] lg:w-[120px] h-[25px] sm:h-[32px] md:h-[32px] lg:h-[32px] ${isUserLimitExist ? `disabled` : ``}`}
                                                style={{
                                                    padding: '0px'
                                                }}
                                                disabled={isUserLimitExist}
                                                onClick={handleButtonClick}
                                            >
                                                <input
                                                    type="file"
                                                    accept=".csv"
                                                    onChange={fileChanged}
                                                    disabled={isUserLimitExist}
                                                    className="hidden"
                                                    ref={addMultyUserReff}
                                                />
                                                <BsUpload />
                                                <span className=" text-[10px] md:text-[14px] lg:text-[14px] "> Upload Excel</span>
                                            </button>
                                            {isUserLimitExist && <div className="tooltip" role="tooltip">
                                                <span style={{ fontSize: '11px' }}>User limit exceeded. Please contact the admin.</span>
                                            </div>}
                                        </div>


                                    </div>
                                    <div className="flex w-full justify-end mt-[3px]" >
                                        {orgDetailsByEmail && orgDetailsByEmail.data && orgDetailsByEmail.data.files ?
                                            <span className=" text-red-600 opacity-[0.7] text-[10px] md:text-[12px] lg:text-[12px] font-normal">Download below listed file, edit and upload to update existing file.</span>
                                            : <span className=" text-red-600 opacity-[0.7] text-[10px] md:text-[12px] lg:text-[12px] font-normal">Check the sample file format once to upload properly.</span>
                                        }
                                    </div>
                                    {
                                        csvError ? (
                                            <div className="form-group"><div className="error-message">{csvError}</div></div>

                                        )
                                            : null
                                    }

                                </div>
                                {orgDetailsByEmail &&
                                    orgDetailsByEmail.data &&
                                    orgDetailsByEmail.data.files && (

                                        <div className="flex flex-col p-[25px] w-[100%] bg-[#FDF1F3] mt-[26px] rounded-[6px] relative overflow-hidden"
                                        style={{
                                            background: 'linear-gradient(90deg, #66C5AF 0%, #98D7D2 100%)'
                                        }}
                                        >
                                            <div className="flex h-[150px] w-[150px] rounded-full bg-[#FFFFFF33] absolute right-[-10px] bottom-[-40px]"></div>
                                            <div className="flex h-[110px] w-[110px] rounded-full bg-[#FFFFFF33] absolute right-[-17px] top-[-5px]"></div>

                                            <h4 className="text-white">Uploaded Files</h4>
                                            <div className=" flex flex-row items-center w-[100%] h-[50px] p-[10px] z-10">
                                                <div className="flex items-center justify-start w-[21%] sm:w-[25%] md:w-[30%] lg:w-[30%]  text-white text-[12px] font-sans">File Name</div>
                                                <div className="flex items-center justify-center w-[17%] sm:w-[20%] md:w-[20%] lg:w-[20%] text-[12px] md:text-[14px] lg:text-[14px] font-sans text-white">Users</div>
                                                <div className="flex items-center justify-center w-[32%] sm:w-[31%] md:w-[35%] lg:w-[25%] text-[12px] md:text-[14px] lg:text-[14px] font-sans text-white">Upload Date</div>
                                                <div className=" hidden lg:flex items-center justify-center w-[25%] text-[12px] md:text-[14px] lg:text-[14px] font-sans text-white">Actions</div>
                                                <div className=" flex lg:hidden items-center justify-center w-[25%] text-[12px] md:text-[14px] lg:text-[14px] font-sans text-white">Actions</div>


                                            </div>
                                            <div className="flex flex-row bg-white p-[13px] w-[100%]  rounded-[6px] z-10">
                                                <div className="flex items-center justify-start w-[21%] sm:w-[25%] md:w-[30%] text-[10px] sm:text-[14px] md:text-[16px] lg:text-[16px]"><h6 className=" overflow-text">{orgDetailsByEmail.data.files.sheetName}</h6></div>
                                                <div className="flex items-center justify-center w-[17%] sm:w-[20%] md:w-[20%] lg:w-[20%] text-[10px] sm:text-[14px] md:text-[16px] lg:text-[16px]">{orgDetailsByEmail.data.files.noOfUsers}</div>
                                                <div className="flex items-center justify-center w-[32%] sm:w-[31%] md:w-[35%] lg:w-[25%] text-[10px] sm:text-[14px] md:text-[16px] lg:text-[16px]"> {uploadDate(orgDetailsByEmail.data.files.createdAt)}</div>
                                                <div className="flex items-center justify-center w-[25%] text-[10px] sm:text-[14px] md:text-[16px] lg:text-[16px]">

                                                    <button
                                                        type="submit"
                                                        className="btn btn-secondry w-[99px] sm:w-[110px] md:w-[120px] lg:w-[120px] h-[25px] sm:h-[32px] md:h-[32px] lg:h-[32px]"
                                                        onClick={handleDownload}
                                                    >
                                                        <svg
                                                            width="13"
                                                            height="14"
                                                            viewBox="0 0 12 13"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                        >
                                                            <path
                                                                d="M5.60246 9.52273C5.6547 9.57497 5.71671 9.61641 5.78495 9.64468C5.8532 9.67295 5.92635 9.6875 6.00022 9.6875C6.07409 9.6875 6.14724 9.67295 6.21549 9.64468C6.28373 9.61641 6.34574 9.57497 6.39798 9.52273L8.51928 7.40143C8.62477 7.29594 8.68403 7.15287 8.68403 7.00368C8.68403 6.8545 8.62477 6.71143 8.51928 6.60594C8.41379 6.50045 8.27072 6.44119 8.12154 6.44119C7.97235 6.44119 7.82928 6.50045 7.72379 6.60594L6.56272 7.76701V1.625C6.56272 1.47582 6.50346 1.33274 6.39797 1.22725C6.29248 1.12176 6.1494 1.0625 6.00022 1.0625C5.85104 1.0625 5.70796 1.12176 5.60247 1.22725C5.49698 1.33274 5.43772 1.47582 5.43772 1.625V7.76701L4.27665 6.60594C4.17116 6.50045 4.02809 6.44119 3.8789 6.44119C3.72972 6.44119 3.58665 6.50045 3.48116 6.60594C3.37567 6.71143 3.31641 6.8545 3.31641 7.00368C3.31641 7.15287 3.37567 7.29594 3.48116 7.40143L5.60246 9.52273Z"
                                                                fill="var(--color-theme)"
                                                            />
                                                            <path
                                                                d="M10.875 5.9375C10.7258 5.9375 10.5827 5.99676 10.4773 6.10225C10.3718 6.20774 10.3125 6.35082 10.3125 6.5V10.8125H1.6875V6.5C1.6875 6.35082 1.62824 6.20774 1.52275 6.10225C1.41726 5.99676 1.27418 5.9375 1.125 5.9375C0.975816 5.9375 0.832742 5.99676 0.727252 6.10225C0.621763 6.20774 0.5625 6.35082 0.5625 6.5V11C0.5625 11.2486 0.661272 11.4871 0.837087 11.6629C1.0129 11.8387 1.25136 11.9375 1.5 11.9375H10.5C10.7486 11.9375 10.9871 11.8387 11.1629 11.6629C11.3387 11.4871 11.4375 11.2486 11.4375 11V6.5C11.4375 6.35082 11.3782 6.20774 11.2727 6.10225C11.1673 5.99676 11.0242 5.9375 10.875 5.9375Z"
                                                                fill="var(--color-theme)"
                                                            />
                                                        </svg>
                                                        <span className=" text-[10px] sm:text-[14px] md:text-[16px] lg:text-[16px]"> Download </span>
                                                    </button>
                                                </div>
                                            </div>

                                            <div className=" flex-col bg-white rounded-[6px] p-[13px] items-center justify-start hidden mt-3 lg:mt-0">
                                                <div className="flex items-center justify-start w-[100%]"><h6 className=" overflow-text text-[16px]">{orgDetailsByEmail.data.files.sheetName}</h6></div>

                                                <div className="flex flex-row w-[100%] mt-3 items-center justify-start">
                                                    <div className="flex items-center justify-center w-[20%] text-[16px]">{orgDetailsByEmail.data.files.noOfUsers}</div>
                                                    <div className="flex items-center justify-center w-[45%] sm:w-[45%] md:w-[50%] lg:w-[50%] text-[16px]"> {uploadDate(orgDetailsByEmail.data.files.createdAt)}</div>
                                                    <div className="flex items-center justify-center w-[35%] sm:w-[35%] md:w-[30%] lg:w-[30%]">

                                                        <button
                                                            type="submit"
                                                            className="btn btn-secondry w-[90px] sm:w-[90px] md:w-[120px] lg:w-[120px] h-[33px]"
                                                            onClick={handleDownload}
                                                        >
                                                            <svg
                                                                width="13"
                                                                height="14"
                                                                viewBox="0 0 12 13"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M5.60246 9.52273C5.6547 9.57497 5.71671 9.61641 5.78495 9.64468C5.8532 9.67295 5.92635 9.6875 6.00022 9.6875C6.07409 9.6875 6.14724 9.67295 6.21549 9.64468C6.28373 9.61641 6.34574 9.57497 6.39798 9.52273L8.51928 7.40143C8.62477 7.29594 8.68403 7.15287 8.68403 7.00368C8.68403 6.8545 8.62477 6.71143 8.51928 6.60594C8.41379 6.50045 8.27072 6.44119 8.12154 6.44119C7.97235 6.44119 7.82928 6.50045 7.72379 6.60594L6.56272 7.76701V1.625C6.56272 1.47582 6.50346 1.33274 6.39797 1.22725C6.29248 1.12176 6.1494 1.0625 6.00022 1.0625C5.85104 1.0625 5.70796 1.12176 5.60247 1.22725C5.49698 1.33274 5.43772 1.47582 5.43772 1.625V7.76701L4.27665 6.60594C4.17116 6.50045 4.02809 6.44119 3.8789 6.44119C3.72972 6.44119 3.58665 6.50045 3.48116 6.60594C3.37567 6.71143 3.31641 6.8545 3.31641 7.00368C3.31641 7.15287 3.37567 7.29594 3.48116 7.40143L5.60246 9.52273Z"
                                                                    fill="var(--color-theme)"
                                                                />
                                                                <path
                                                                    d="M10.875 5.9375C10.7258 5.9375 10.5827 5.99676 10.4773 6.10225C10.3718 6.20774 10.3125 6.35082 10.3125 6.5V10.8125H1.6875V6.5C1.6875 6.35082 1.62824 6.20774 1.52275 6.10225C1.41726 5.99676 1.27418 5.9375 1.125 5.9375C0.975816 5.9375 0.832742 5.99676 0.727252 6.10225C0.621763 6.20774 0.5625 6.35082 0.5625 6.5V11C0.5625 11.2486 0.661272 11.4871 0.837087 11.6629C1.0129 11.8387 1.25136 11.9375 1.5 11.9375H10.5C10.7486 11.9375 10.9871 11.8387 11.1629 11.6629C11.3387 11.4871 11.4375 11.2486 11.4375 11V6.5C11.4375 6.35082 11.3782 6.20774 11.2727 6.10225C11.1673 5.99676 11.0242 5.9375 10.875 5.9375Z"
                                                                    fill="var(--color-theme)"
                                                                />
                                                            </svg>
                                                            <span className="text-[16px]"> Download </span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>


                            <div className="add-users-teams-container-two ">

                                {
                                    userInfo?.data?.role === ROLES.ORG_SUPER_ADMIN &&
                                    <div className="flex flex-col w-[100%] p-[25px] bg-[#FFF7EF] rounded-[6px] bg-cover bg-no-repeat relative overflow-hidden"
                                    style={{
                                        background: 'linear-gradient(90deg, #D49DEE 0%, #9F70E4 100%)'
                                    }}
                                    >
                                        <div className="flex h-[370px] w-[370px] lg:h-[195px] lg:w-[195px] rounded-full bg-[#FFFFFF33] absolute lg:right-[-20px] lg:bottom-[-40px] bottom-[-51px] right-[-55px]"></div>
                                        <div className="flex h-[220px] w-[220px] lg:h-[130px] lg:w-[130px] rounded-full bg-[#FFFFFF33] absolute lg:right-[-30px] lg:top-[-5px] top-[7px] right-[-40px]"></div>
    
                                        <h4>Add Users With Dummy Email</h4>

                                        <div className="flex flex-col md:flex-row lg:flex-row  md:justify-between md:items-center lg:justify-between lg:items-center w-[100%] mt-2 mb-3 lg:mb-0">
                                            <div className="checkbox-container mb-3 lg:mb-0">
                                                <input type="checkbox" className="form cursor-pointer" onClick={e => setByUploadingFile(e.target.checked)} />
                                                <span className="text-[18px] ml-[10px] font-normal text-white">By Uploading File</span>
                                            </div>
                                            {
                                                byUploadingFile &&
                                                <div className="employedata-csvfile">
                                                    <h5 className="mr-[10px] text-[16px] text-white">Sample CSV file</h5>
                                                    <Link onClick={() => setModalTwo(true)} to={'#'}>
                                                        <img src={excel} alt="csv" />
                                                        <span className="text-white">View Sample</span>
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                        <form className="flex w-[100%] flex-col items-center mt-2 justify-between lg:flex-row">
                                            <div className="flex flex-col lg:flex-row w-[100%] md:w-[76%] lg:w-[76%]">
                                                <div className="flex w-[100%] lg:w-[40%] h-[45px] bg-white rounded-[6px] relative">

                                                    {
                                                        byUploadingFile ? (
                                                            <div className=" relative w-[100%] h-[100%]">
                                                                <button type="submit" className="btn btn-secondry h-[100%] w-[100%]">
                                                                    <BsUpload />
                                                                    <span> Upload File</span>
                                                                </button>
                                                                <input
                                                                    type="file"
                                                                    accept=".csv"
                                                                    className=" absolute w-[100%] h-[100%] top-0 left-0 cursor-pointer opacity-0 text-[0px]"
                                                                    onChange={e => fileChanged(e, true)}
                                                                />
                                                                <div className="error-message file-name">{dummyUsersFile?.name ? dummyUsersFile.name : ""}</div>
                                                                <div className="error-message">{errors.file}</div>
                                                            </div>
                                                        )
                                                            : <>
                                                                <input type="number" className="input-text-field rounded-[6px] h-[45px]" placeholder="No. Of Users*" onChange={handleChangeData} onBlur={handleBlur} name="noOfUsers" value={dummyUserdetails.noOfUsers} onKeyPress={preventNonNumericalInput} />
                                                                <div className="error-message">{errors.noOfUsers}</div>
                                                            </>
                                                    }

                                                </div>
                                                <div className=" flex h-[45px] w-[100%] lg:w-[60%] bg-white rounded-[6px] relative lg:ml-[12px] mt-[30px] lg:mt-0">
                                                    <input type="text" className="input-text-field rounded-[6px] h-[45px]" placeholder="Enter domain (eg; @gmail.com)" onChange={handleChangeData} onBlur={handleBlur} name="domain" value={dummyUserdetails.domain} />
                                                    <div className="error-message">{errors.domain}</div>
                                                </div>
                                            </div>


                                            <div className=" flex h-[45px] rounded-[6px] w-[100%] md:w-[76%] lg:w-[20%] items-center justify-center lg:justify-end mt-[30px] lg:mt-0  btn-tooltip" style={{marginLeft:'0px'}}>
                                                <button
                                                    className={`w-[100%] h-[45px] lg:w-[120px] lg:h-[32px] text-[14px] btn btn-teams-secondry bg-white ${isUserLimitExist ? `disabled` : ``}`}
                                                    disabled={isUserLimitExist}
                                                    onClick={handleDummyUserSubmit}>+Add User
                                                </button>

                                                {isUserLimitExist && <div className="tooltip" role="tooltip" >
                                                    <span style={{ fontSize: '11px' }}>User limit exceeded. Please contact the admin.</span>
                                                </div>}
                                            </div>
                                        </form>
                                        {
                                            getMasterOtp?.masterOtp ?

                                                <form className="flex w-[100%] flex-col items-center lg:mt-2 justify-between lg:flex-row mt-[30px]">
                                                    <div className="flex flex-col lg:flex-row w-[100%] md:w-[76%] lg:w-[76%]">
                                                        <div className="flex w-[100%] lg:w-[100%] h-[45px] bg-white rounded-[6px] relative">
                                                            <input type="password" className="input-text-field rounded-[6px] h-[45px]" ref={masterOtpRef} placeholder="Enter master otp *" onChange={handleChangeData} onBlur={handleBlur} name="otp" value={dummyUserdetails.otp} onKeyPress={preventNonNumericalInput} />
                                                            <span toggle="#password-field" className="fa fa-fw fa-eye toggle-password absolute right-5 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-2" onClick={e => {
                                                                if (e.target.classList?.value?.includes?.("fa-eye-slash")) {
                                                                    e.target.classList?.remove?.("fa-eye-slash")
                                                                    masterOtpRef.current.type = "text"
                                                                }
                                                                else {
                                                                    e.target.classList?.add?.("fa-eye-slash")
                                                                    masterOtpRef.current.type = "password"
                                                                }
                                                            }}></span>
                                                            <div className="error-message">{errors.otp}</div>

                                                        </div>

                                                    </div>
                                                    <div className=" flex h-[60px] rounded-[6px] w-[100%] md:w-[76%] lg:w-[20%] items-center justify-center lg:justify-end mt-3 lg:mt-0">
                                                        <button className=" btn btn-teams-secondry w-[100%] h-[45px] lg:w-[120px] lg:h-[32px] text-[14px] bg-[#FFFFFF33]" onClick={handleUpdateMasterOtp} style={{color:'white'}}>Update Otp</button>

                                                    </div>

                                                </form>
                                                : null
                                        }

                                    </div>

                                }

                                <div className=" flex flex-col md:flex-row lg:flex-row w-[100%] mt-[30px] items-start">
                                    <div className="flex flex-col items-center lg:items-start justify-center w-[100%] ">
                                        <h2 className=" text-[24px] sm:text-[30px] md:text-[34px] lg:text-[34px] font-semibold mb-6">Manage User Data / Create Teams</h2>
                                        <div className="flex flex-row items-center w-full">
                                            <button className=" w-[160px] h-[48] text-[17px] btn btn-secondry" onClick={handleUserData}>User Data</button>
                                            <button className=" w-[160px] h-[48] text-[17px] ml-6 btn btn-primary" onClick={handleManageTeams}>Create Teams</button>

                                        </div>

                                    </div>
                                    {/* <div className="flex flex-col items-start justify-center w-[100%] md:w-[50%] lg:w-[50%] mt-7 md:mt-0 lg:mt-0">
                                        <h2 className=" text-[34px] font-semibold mb-6">Create Teams</h2>
                                        <button className=" w-[160px] h-[48] text-[17px] btn btn-primary" onClick={handleManageTeams}>Manage Teams</button>

                                    </div> */}
                                </div>


                            </div>

                        </div>
                        <div className="flex flex-col w-[100%] mt-[40px]">

                            <div className="flex flex-col items-center justify-center w-[100%] p-[20px]">
                                <div className="flex flex-col md:flex-row lg:flex-row w-[100%] lg:w-[550px] lg:items-center">
                                    <h4 className=" font-semibold">Are users allowed to join with the game link?</h4>
                                    <div className="flex flex-row ml-0 md:ml-4 lg:ml-4  mt-3 md:mt-0 lg:mt-0">
                                        <label className="container-radio" onClick={() => updateDomainRestriction(true)}>
                                            Yes
                                            <input
                                                checked={yesFlag}
                                                type="radio"
                                                // onChange={() => updateDomainRestriction(true)}
                                                name="userallow"
                                                id="allow"
                                                value="yes"
                                            />
                                            <span className="checkmark" ></span>
                                        </label>
                                        <label className="container-radio ml-4" onClick={() => updateDomainRestriction(false)}>
                                            No
                                            <input
                                                checked={!yesFlag}
                                                type="radio"
                                                // onChange={() => updateDomainRestriction(false)}
                                                name="userallow"
                                                id="dont-allow"
                                                value="no"
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                                <div className={`${yesFlag ? `flex` : `hidden`} flex-col md:flex-row lg:flex-row w-[100%] lg:w-[550px] items-center justify-center md:justify-start lg:justify-start mt-4`}>
                                    <div className="flex items-center w-[100%] sm:w-[100%] md:w-[324px] lg:w-[324px] h-[45px] bg-white rounded-[6px]  border border-[#000000] relative">
                                        <input
                                            type="text"
                                            value={domainName}
                                            onChange={handleChange}
                                            placeholder="Enter domain (eg; @gmail.com)"
                                            className="input-text-field rounded-[6px] h-[43px] border border-[#000000]"
                                        />
                                        <div className="error-message">{domainNameError}</div>

                                    </div>
                                    <div className="flex h-[100%] w-full md:w-[120px] lg:w-[120px] items-center justify-center md:ml-4 lg:ml-4 mt-7 md:mt-0 lg:mt-0">
                                        <button
                                            className={
                                                domainName.length > 1
                                                    ? "btn btn-primary  w-[120px] h-[32px] text-[14px]"
                                                    : "btn btn-primary disabled  w-[120px] h-[32px] text-[14px]"
                                            }
                                            onClick={addDomain}
                                            style={{
                                                fontSize:'14px'
                                            }}
                                        >
                                            +Add Domain
                                        </button>
                                    </div>

                                </div>


                            </div>

                            {orgDetailsByEmail &&
                                orgDetailsByEmail.data &&
                                orgDetailsByEmail.data.allowedDomains &&
                                orgDetailsByEmail.data.allowedDomains.length > 0 && (
                                    <div className={`upload-table-wrapper ${yesFlag ? `` : `hide`}`}>
                                        <h4 style={{ fontSize: '20px', fontWeight: 'bold', marginBottom: '15px' }}>Uploaded Domains</h4>
                                        <div className="report-table uploadata-table">
                                            <table>
                                                <thead style={{ height: '60px' }}>
                                                    <tr style={{ background:'#F8F9FD' }}>
                                                        <th style={{ fontSize: '20px', fontWeight: 'bold' }}>Domain Name</th>
                                                        <th style={{ fontSize: '20px', fontWeight: 'bold' }}>Upload Date</th>
                                                        <th style={{ fontSize: '20px', fontWeight: 'bold' }}>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {orgDetailsByEmail.data.allowedDomains.map((domain) => {
                                                        return (
                                                            <tr style={{ height: '60px' }}>
                                                                <td width="38%">{domain.name}</td>
                                                                <td>{uploadDate(domain.createdAt)}</td>
                                                                <td style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                    <img
                                                                        src={deleteButton}
                                                                        alt="delete"
                                                                        onClick={(e) =>
                                                                            handleDeleteDomain(e, domain.name)
                                                                        }
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                        {/* table responsive */}
                                        <div className="table-responsive m-transacton-table">
                                            {orgDetailsByEmail.data.allowedDomains.map((domain) => {
                                                return (
                                                    <div className="uploadtable-card c-white-card">
                                                        <div className="uploadtable-card-left">
                                                            <h5>{domain.name}</h5>
                                                        </div>
                                                        <div className="uploadtable-card-right">
                                                            <li key={domain.createdAt}>{uploadDate(domain.createdAt)}</li>
                                                            <img
                                                                src={deleteButton}
                                                                alt="delete"
                                                                onClick={(e) => handleDeleteDomain(e, domain.name)}
                                                            />
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                        </div>


                        {openConfirmUploadModal && (
                            <Confirm
                                modalid="uploadcsv"
                                toggle={openConfirmUploadModal}
                                file={file}
                                setOpenConfirmUploadModal={setOpenConfirmUploadModal}
                                setUploadFlag={setUploadFlag}
                                isFileExisting={orgDetailsByEmail?.data?.files ? true : false}
                            />
                        )}
                        {addDeteletedUser.modal && (
                            <Confirm
                                modalid="checkDeletedUser"
                                toggle={addDeteletedUser.modal}
                                file={file}
                                setOpenConfirmUploadModal={setAddDeteletedUser}
                                setUploadFlag={() => { }}

                                message={`There are deleted users in the excel sheet.
                    Are you sure you want to add them back to the Team?`}
                                isFileExisting={false}
                            />
                        )}
                        {openAddEditUserModal && (
                            <ManageTeamAddEditUserModal
                                modalid="add-user-modal"
                                toggle={openAddEditUserModal}
                                setOpenAddEditUserModal={setOpenAddEditUserModal}
                                successAlert={successAlert}
                            />
                        )}
                        {openDeleteDomainModal && (
                            <DeleteDomainModal
                                toggle={openDeleteDomainModal}
                                setOpenDeleteDomainModal={setOpenDeleteDomainModal}
                                deleteFunction={removeDomain}
                                deleteData={deleteDomainName}
                            />
                        )}
                        {
                            openUsersAddedModal &&
                            <UsersAddedModal modalid={"usersAddedModal"} setOpenUsersAddedModal={setOpenUsersAddedModal} toggle={openUsersAddedModal} uploadedEmployeeDetails={uploadedEmployeeDetails} />
                        }

                        {
                            openEditTeam &&
                            <EditTeams toggle={openEditTeam} setOpenEditTeam={setOpenEditTeam} />
                        }

                        {
                            toggalDeleteTeamModal &&

                            <DeleteTeamModal toggle={toggalDeleteTeamModal} teamId={deleteTeamId} setOpenAddTeamModal={setToggalDeleteTeamModal} setDeleteTeamId={setDeleteTeamId} handleDeleteFun={handleDeleteTeam} textInfo={"Are you sure you want to delete this team?"} />
                        }

                        <SampleCsv showModal={modalOne} setModal={(data) => setModalOne(data)} />
                        <SampleCsv showModal={modalTwo} setModal={(data) => setModalTwo(data)} dummyusers />
                    </div>
                </div >

            </main>
        </div>
    );
};

export default AddUserTeam;
