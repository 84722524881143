import React, { useEffect, useLayoutEffect, useState } from "react";

import Account from "../../components/account/account";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/actions/userAction";
import PageLayout from "../../components/pagelayout/pagelayout";
import { Redirect, useParams } from "react-router";
import OrgDetails from "../../components/account/orgDetails";
import { sidebarContentMyAccount, sidebarContentMyAccountUser, sidebarContentMyAccountWithXoxo, sidebarContentMyAccountUserForDummyUser } from '../../helpers/sidebarContentAccount';
import sidebarContentAdmin from '../../helpers/sidebarContentAdmin';
import PlanDetail from "../../components/plandetail/plandetail";
import Security from "../../components/security/security";
import TeamDetails from "../../components/teamDetail/TeamDetails";
import ROLES from "../../helpers/userTypes";
import MyPoints from './../../components/points/myPoints';
import { BASE_URL, axiosApiInstance } from "../../helpers/helper";

const MyAccount = (props) => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.getUser);
  const userType = userInfo && userInfo.data && userInfo.data.role;
  const { tab } = useParams();
  const isAdmin =
    userInfo && userInfo.data && userInfo.data.role === "EXTRAMILE_SUPERADMIN";
  const userIsMail = userInfo && userInfo.data && userInfo.data.isMail;
  const [isXoxoEnabled, setXoxoEnabled] = useState(false);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      if (userInfo?.data?.organizationId) {
        const { data } = await axiosApiInstance.post(BASE_URL + "/api/xoxoday-game/getXoxoEnableStatus", {
          organizationId: userInfo?.data?.organizationId
        })
        if (data.status === 200 && userInfo.data.role === ROLES.ORG_SUPER_ADMIN) {
          setXoxoEnabled(data?.data?.isXoxoEnable)
        }
      }
    })();
  }, [userInfo?.data?.organizationId]);

  const signOut = async () => {
    await dispatch(logOut());
    props.history.push("/");
  };
  var active = ""
  if (tab === "details")
    active = "My Details";
  else if (tab === "org-details")
    active = "Organisation Details"
  else if (tab === "plan-details")
    active = "My Plan"
  else if (tab === "xoxo-day")
    active = "XOXO Day"
  else if (tab === "team-details")
    active = "Team Details"
  else if (tab === "security")
    active = "Security"

  if (isAdmin) {
    return (
      <div >
        <PageLayout
          sidebartitle=""
          active={"Profile"}
          sideBarContents={sidebarContentAdmin}
          profile
          {...props}
          signOut={signOut}
          isAdmin={isAdmin}
        >
          <Account />
        </PageLayout>
      </div>
    );
  } else {
    if (tab)
      return (
        // Updated UI for account
        <div >
          <PageLayout
            sidebartitle=""
            active={((userType === ROLES.ORG_ADMIN) || (userType === ROLES.ORG_SUPER_ADMIN) || (userType === ROLES.EMPLOYEE)) ? active : "My Details"}
            sideBarContents={((userType === ROLES.ORG_SUPER_ADMIN) && isXoxoEnabled) ? sidebarContentMyAccountWithXoxo : userType === ROLES.EMPLOYEE && !userIsMail ? sidebarContentMyAccountUserForDummyUser : (userType === ROLES.ORG_ADMIN) || (userType === ROLES.ORG_SUPER_ADMIN) ? sidebarContentMyAccount : sidebarContentMyAccountUser}
            profile
            {...props}
            signOut={signOut}
            isAdmin={isAdmin}
          >
            {active === "My Details" ?
              <Account {...props} /> : active === "Organisation Details" ?
                <OrgDetails {...props} role={userInfo && userInfo.data && userInfo.data.role} email={userInfo && userInfo.data && userInfo.data.email} /> :
                active === "My Plan" ?
                  <div className="plandetail-wrapper">
                    <PlanDetail {...props} userType={userType} />
                  </div> : active === "Team Details" ?
                    <div className="plandetail-wrapper">
                      <TeamDetails {...props} userType={userType} />
                    </div> : active === "Security" ?
                      <div className="plandetail-wrapper">
                        <Security {...props} userType={userType} />
                      </div> : isXoxoEnabled ?
                        <div className="plandetail-wrapper">
                          <MyPoints {...props} userType={userType} />
                        </div> : null
            }
          </PageLayout>
        </div>
      );
    return (<Redirect to="/account/details" />)
  }
};

export default MyAccount;
