import React, { useState, useEffect, useRef } from "react";

import OnBoard from "../../components/onboard/onboard";
import Header from "../../components/header/header";
import "./SignUp.css";
import { ToastContainer, toast } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-input-2'

import { successAlert, failureAlert, GOOGLE_RECAPTCHA_SITE_KEY, axiosApiInstance, encryptData, decryptData } from "../../helpers/helper";
import { getOrganisationDetailsById } from "../../redux/actions/plansApiActions";
import { sendOtpToUser, signin } from "../../redux/actions/userAction";
import { getGameServerOtp } from "../../redux/actions/gameDetailAction";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { BASE_URL } from '../../helpers/helper';

import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { CiCircleInfo } from "react-icons/ci";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";


const SignUp = (props) => {


  const [loaded, setLoaded] = useState(true);
  const [inputValue, setInputeValue] = useState({ email: "", firstName: "", lastName: "", orgName: "", phoneNo: "", password: "", verifyPassword: "" })
  const [touch, setTouch] = useState({ email: false, firstName: false, lastName: false, orgName: false, phoneNo: false, password: false, verifyPassword: false });
  const [showPassword, setShowPassword] = useState(false);
  const [inviteIdOrgName, setInviteIdOrgName] = useState(false);
  const [googleReCaptchaToken, setGoogleReCaptchaToken] = useState("");
  const [orgSignInBanner, setOrgSignInBanner] = useState('');
  const [validPhone, setValidPhone] = useState(false);
  const [defaultinputValue, setDefaultInputeValue] = useState({ email: "Enter Your Email", firstName: "Enter Your First Name", lastName: "Enter Your Last Name", orgName: "Enter Your Organization Name", phoneNo: "+91 99999-99999", password: "Enter Your Password", verifyPassword: " Verify Your Password" })
  const [invitedUserEmail, setInvitedUserEmail] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [OtpinputValue, setOtpInputValue] = useState({ otp: "" });
  const [otpTouched, setOtpTouched] = useState({ otp: false });
  const [otpErrorMessage, setOtpErrorMessage] = useState('');
  const [resendCounter, setResendCounter] = useState(0);
  const [signUpOtpBtn, setSignUpOtpBtn] = useState('send-otp');


  const dispatch = useDispatch();
  const UserSignin = useSelector((state) => state.userSignin);
  let { userInfo } = UserSignin;

  const [displayFieldError, setDisplayFieldError] = useState({
    email: false,
    firstName: false,
    lastName: false,
    orgName: false,
    phoneNo: false,
    password: false,
    verifyPassword: false,
    otp: false
  })

  let inviteId = new URLSearchParams(props.location.search).get('inviteId');
  const pollId = new URLSearchParams(props.location.search).get('pollId');
  const inviteToken = new URLSearchParams(props.location.search).get('inviteToken');


  let organizationId;
  if (props && props.location && props.location.state && props.location.state.organizationId) {
    organizationId = props.location.state.organizationId;
  }

  const counterinterval = useRef();

  useEffect(() => {
    if (resendCounter === 30) {
      let count = 30;
      counterinterval.current = setInterval(() => {
        if (count === 0)
          clearInterval(counterinterval.current);
        else {
          count -= 1;
          setResendCounter(count);
        }
      }, 1000);
    }
  }, [resendCounter]);

  useEffect(() => {
    return () => {
      if (counterinterval.current)
        clearInterval(counterinterval.current);
    }
  }, [])

  useEffect(() => {
    if (signUpOtpBtn === 'send-otp') {
      setOtpTouched({ otp: false });
      setOtpInputValue({ otp: "" });
      setResendCounter(0);
    }
  }, [signUpOtpBtn])

  useEffect(() => {
    window.scrollTo(0, 0);
    const sessionid = new URLSearchParams(props.location.search).get('sessionId');
    const gameId = new URLSearchParams(props.location.search).get('gameId');
    if (sessionid && gameId) {
      sessionStorage.setItem("sessionId", encryptData(sessionid));
      sessionStorage.setItem("gameId", encryptData(gameId));
    }
    if (inviteId) {
      sessionStorage.setItem("inviteId", encryptData(inviteId));
      let newUserData = decryptData(inviteId);
      if (newUserData && !gameId && !sessionid) {
        getOrgName(newUserData.organizationId)
        setInputeValue((prevState) => ({ ...prevState, email: newUserData.email, firstName: newUserData.firstName, lastName: newUserData.lastName }));
        setInvitedUserEmail(true);
      }
    }
    else {
      sessionStorage.setItem("inviteId", encryptData(""));
    }
  }, [inviteId]);

  const getOrgName = async (organizationId) => {
    const response = await dispatch(getOrganisationDetailsById(organizationId));
    if (response.data && response.data.organization.name) {
      setInviteIdOrgName(true);
      setInputeValue((prevState) => ({ ...prevState, orgName: response.data.organization.name }));
      setOrgSignInBanner(response.data.organization.sigInPageBanner);
    }
  }

  useEffect(() => {
    if (organizationId) {
      getOrgName(organizationId)
    }
  }, [organizationId])


  const history = useHistory();

  function trimSpaces(str) {
    return str.replace(/^[\s]+|[\s]+$/g, '');
  }

  const handleInputChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (touch[name]) {
      setTouch(prevState => ({ ...prevState, [name]: false }));
    }
    setInputeValue((prevState) => ({
      ...prevState,
      [name]: name === "firstName" ? capitalizeFirstAlpabate(value) : name === "lastName" ? capitalizeFirstAlpabate(value) : name === "email" ? value.toLowerCase() : value,
    }))
  }


  ////

  const redirectToGame = async (gameId, sessionId) => {
    const response = await dispatch(getGameServerOtp(gameId, sessionId));
    if (response && response.status === 200) {
      if (response.data && response.data.data && response.data.data.redirectUrl)
        window.location.replace(response.data.data.redirectUrl);
    }
    else {
      failureAlert("You are not allowed to access this game");
      setTimeout(() => {
        const redirectToHome = "/";
        const redirectToPlans = "/plans";
        if (userInfo.current && !userInfo.current.isNewUser && userInfo.current.role === 'USER') {
          props.history.replace(redirectToPlans);
          window.location.href = redirectToPlans;
        } else if (userInfo.current && !userInfo.current.isNewUser) {
          props.history.replace(redirectToHome);
          window.location.href = redirectToHome;
        }
      }, 2000);
    }
  }

  useEffect(() => {
    if (userInfo) {
      const searchParams = new URLSearchParams(window.location.search);
      const xoxoValue = searchParams.get('xoxo');
      const pollId = searchParams.get('pollId');
      const inviteToken = searchParams.get('inviteToken');
      const isPollLink = pollId ? ("poll/" + pollId + "?inviteToken=" + inviteToken) : "";

      const redirectToHome = xoxoValue === "true" ? "/?xoxo=true" : pollId ? `/${isPollLink}` : "/";
      const redirectToUserDetail = "/user-detail";
      const redirectToPlans = "/plans";
      const gameId = decryptData(sessionStorage.getItem("gameId") || encryptData(""));
      const sessionId = decryptData(sessionStorage.getItem("sessionId") || encryptData(""));
      if (userInfo && sessionStorage.getItem('sharedSessionGameId') != "") {
        setTimeout(() => {
          props.history.replace("/active-games/" + sessionStorage.getItem('sharedSessionGameId'));
          sessionStorage.setItem('sharedSessionGameId', "")
        }, 1000)
      }
      localStorage.setItem("isAuthenticated", encryptData(true));
      var obj = null
      if (inviteId)
        obj = decryptData(inviteId);
      if (obj && obj.redirectURL) {
        props.history.replace(obj.redirectURL);
      }
      else if (userInfo && gameId && sessionId) {
        sessionStorage.setItem("gameId", encryptData(""));
        sessionStorage.setItem("sessionId", encryptData(""));
        redirectToGame(gameId, sessionId);
      }
      else if ((userInfo && userInfo.role === 'USER' && !inviteId) || (userInfo && userInfo.isNewUser && !inviteId)) {
        setLoaded(false)
        setTimeout(() => {
          props.history.replace(redirectToPlans);
          setLoaded(true)
        }, 1300)
      } else {
        setLoaded(false)
        setTimeout(() => {
          props.history.replace(redirectToHome);
          setLoaded(true)
        }, 1300)
      }


      // else if (userInfo && gameId && sessionId) {
      //   sessionStorage.setItem("gameId", encryptData(""));
      //   sessionStorage.setItem("sessionId", encryptData(""));
      //   redirectToGame(gameId, sessionId);
      // }
      // else if (userInfo && userInfo.role === 'USER' && !inviteId) {
      //   setLoaded(false)
      //   setTimeout(() => {
      //     props.history.replace(redirectToPlans);
      //     // setLoginInputeValue({ email: "", password: "" });
      //     // setLoginTouch({ email: false, password: false });
      //     setLoaded(true)
      //   }, 1300)
      // } else {
      //   setLoaded(false)
      //   setTimeout(() => {
      //     props.history.replace(redirectToHome);
      //     // setLoginInputeValue({ email: "", password: "" });
      //     // setLoginTouch({ email: false, password: false });
      //     setLoaded(true)
      //   }, 1300)
      // }
      sessionStorage.setItem("inviteId", encryptData(null));
    }
  }, [userInfo]);

  const handleLoginSubmit = async (userEmail, userPassword, inviteId) => {
    if (userEmail && userPassword && inviteId) {
      setLoaded(false);
      const resStatus = await dispatch(signin({ email: userEmail, password: userPassword, inviteId: inviteId }));
      setLoaded(true);
      if (resStatus.payload) {
        return failureAlert(resStatus.payload);
      }
      if (resStatus && resStatus.status === 200) {
        localStorage.setItem("otpVerified", encryptData(true));

      }
    }
    else {
      setLoaded(false);
      const resStatus = await dispatch(signin({ email: userEmail, password: userPassword, inviteId: inviteId }));
      setLoaded(true);
      if (resStatus.payload) {
        return failureAlert(resStatus.payload);
      }
      if (resStatus && resStatus.status === 200) {
        localStorage.setItem("otpVerified", encryptData(true));

      }
    }

  }



  ///

  const handleNewUserOtpRequest = async (e) => {
    e.preventDefault()
    const trimSpaceOrgName = trimSpaces(inputValue.orgName);

    setLoaded(false);
    const registrationOtp = await axios.post(BASE_URL + "/api/user/new-user-otp", { email: inputValue.email, orgName: trimSpaceOrgName.toLowerCase() })
    if (registrationOtp && registrationOtp.data.message === "Invalid Email") {
      failureAlert("Invalid Email");
    }
    else if (registrationOtp && registrationOtp.data.message === "MAIL SENT SUCCESSFULLY") {
      successAlert('OTP sent successfully!');
      setSignUpOtpBtn("verify-otp")
      setResendCounter(30);


    }
    else {
      failureAlert("failed to send Otp")
    }
    setLoaded(true)
  }




  const handleSubmit = async (e, msFlag) => {
    e.preventDefault();
    if (signUpFormErrorMessg.email === "" && signUpFormErrorMessg.firstName === "" && signUpFormErrorMessg.lastName === "" && signUpFormErrorMessg.orgName === "" && signUpFormErrorMessg.phoneNo === "" && signUpFormErrorMessg.password === "" && signUpFormErrorMessg.verifyPassword === "") {
      if (googleReCaptchaToken) {
        const response = await axios.post(BASE_URL + `/api/user/verify/googlecaptcha`, { token: googleReCaptchaToken })
        if (response.data.data === true) {
          let confirmPassword = "";
          if (inputValue.password === inputValue.verifyPassword) {
            confirmPassword = inputValue.password
            const trimSpaceOrgName = trimSpaces(inputValue.orgName);
            const newUserData = {
              email: inputValue.email,
              firstName: inputValue.firstName,
              lastName: inputValue.lastName,
              orgName: trimSpaceOrgName.toLowerCase(),
              phoneNo: inputValue.phoneNo,
              password: confirmPassword,
              inviteId: invitedUserEmail
            }
            const checkEmail = await axios.post(BASE_URL + "/api/user/check/new-user-email", { email: inputValue.email });
            if (checkEmail && checkEmail.data.message === "sucess") {
              setSignUpOtpBtn("send-otp")
              setShowOtp(true)
              handleNewUserOtpRequest(e, "OTP")
            }
            else if (checkEmail && checkEmail.data.message === "email is already taken") {
              failureAlert("Email is already taken")
            }
            else {
              failureAlert("Something went wrong")
            }


            // setLoaded(false);
            // await axios.post(BASE_URL + "/api/user/register-user", newUserData)
            //   .then(async (response) => {
            //     setLoaded(true);
            //     if (response.data.message === "Sucessfully Register User") {
            //       successAlert("Successfully registered");
            //       if (inviteId) {
            //         handleLoginSubmit(inputValue.email, inputValue.password, inviteId)
            //       }
            //       else {
            //         handleLoginSubmit(inputValue.email, inputValue.password, inviteId);

            //       }

            //     }
            //     else if (response.data.message === "User With This Eamil Already Exists") {
            //       failureAlert("User with this email already exists");
            //     }
            //     else if (response.data.message === "User limit exceeded. Please contact the admin.") {
            //       failureAlert("User limit exceeded. Please contact the admin.");
            //     }
            //     else {
            //       failureAlert("Failed to register")
            //     }
            //   })
            //   .catch((error) => {
            //     setLoaded(true);
            //     console.log(error);
            //     failureAlert("Failed to register");
            //   })
          }

        } else {
          failureAlert("Please verify you are not a robot");

        }
      }
      else {
        failureAlert("Please verify you are not a robot");
      }

    }
    else {
      failureAlert("Ensure to insert correct details for successful signup");
    }
  };

  function validateEmail(email) {
    const regex = /[A-Z]/;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase()) && !regex.test(email);
  }

  function validatePassword(password) {
    let validLength = false;
    if (password.length > 4 && password.length <= 15) {
      validLength = true;
    }
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSymbol = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password);

    return hasUpperCase && hasLowerCase && hasNumber && hasSymbol && validLength;
  }


  const validateName = (name) => {
    let isValidName = false;
    if (name.length > 2) {
      isValidName = true
    }
    return isValidName;
  }

  const LoginValidateInput = (input) => {
    const errors = {
      email: "",
      firstName: "",
      lastName: "",
      orgName: "",
      phoneNo: "",
      password: "",
      verifyPassword: ""
    };
    if (touch.email && !validateEmail(input.email)) {
      errors.email = "Invalid email address, please provide a valid Email";
    }
    if (touch.email && input.email === "") {
      errors.email = "required*"
    }
    if (touch.firstName && (!validateName(input.firstName))) {
      errors.firstName = "Please Enter Your First Name"
    }
    if (touch.firstName && input.firstName === "") {
      errors.firstName = "required*"
    }
    if (touch.lastName && (!validateName(input.lastName))) {
      errors.lastName = "Please Enter Your Last Name"
    }
    if (touch.lastName && input.lastName === "") {
      errors.lastName = "required*"
    }
    if (touch.orgName && input.orgName === "") {
      errors.orgName = "required*"
    }

    if (touch.password && !validatePassword(input.password)) {
      errors.password = "Your password length must be between 4 to 15 must contain at least one uppercase letter, one number, and one symbol";
    }
    if (touch.password && input.password === "") {
      errors.password = "Your password length must be between 4 to 15 must contain at least one uppercase letter, one number, and one symbol"
    }
    if (touch.verifyPassword && inputValue.password !== "" && (input.verifyPassword !== inputValue.password || input.verifyPassword === "")) {
      errors.verifyPassword = "Password did not match"
    }
    if (touch.verifyPassword && input.verifyPassword !== "" && inputValue.password === "") {
      errors.verifyPassword = "Please set password first"
    }
    else if (touch.verifyPassword && input.verifyPassword === "" && inputValue.password === "") {
      errors.verifyPassword = "Please set password first"
    }
    if (input.phoneNo !== "" && !validPhone) {
      errors.phoneNo = "Invalid phone no"
    }

    return errors;
  };

  const capitalizeFirstAlpabate = (name) => {
    if (typeof name !== 'string' || name.length === 0) {
      return '';
    }
    let spaceTrimName = name.replace(/\s/g, '');
    return spaceTrimName.charAt(0).toUpperCase() + spaceTrimName.slice(1).toLowerCase();
  }

  let signUpFormErrorMessg = LoginValidateInput(inputValue);

  const togglePasswordVisibility = () => {
    setShowPassword(prevState => !prevState);
  };

  const handleSingInPage = async (e) => {
    e.preventDefault();
    if (inviteId) {
      const sessionid = new URLSearchParams(props.location.search).get('sessionId');
      const gameId = new URLSearchParams(props.location.search).get('gameId');
      const statevalue = {
        signIn: true
      }
      props.history.push(`signin?gameId=${gameId}&sessionId=${sessionid}&inviteId=${inviteId}`, statevalue);
      // props.history.push(`signup?gameId=${gameId}&sessionId=${sessionid}&inviteId=${inviteId}`)

    }
    else {
      props.history.push("signin");

    }
  }

  const handleGoogleCaptcha = async (value) => {
    if (value) {
      setGoogleReCaptchaToken(value)
    }
  }


  const handleIiconClick = (fieldName) => {
    setDisplayFieldError((prev) => ({ ...prev, [fieldName]: !displayFieldError[fieldName] }));
  }

  const handelOtpChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (otpTouched[name]) {
      setOtpTouched(prevState => ({ ...prevState, [name]: false }));
    }
    setOtpInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const reSendOtp = async (e) => {
    e.preventDefault();
    if (resendCounter > 0) {
      return;
    }
    if (showOtp) {

      const trimSpaceOrgName = trimSpaces(inputValue.orgName);
      const registrationOtp = await axios.post(BASE_URL + "/api/user/new-user-otp", { email: inputValue.email, orgName: trimSpaceOrgName })
      if (registrationOtp && registrationOtp.data.message === "Invalid Email") {
        failureAlert("Invalid Email");
      }
      else if (registrationOtp && registrationOtp.data.message === "MAIL SENT SUCCESSFULLY") {
        successAlert('OTP sent successfully!');
        setResendCounter(30);

      }
      else {
        failureAlert("failed to send Otp")
      }
    }
    else {
      failureAlert('Please enter your email');

    }

  }

  const verifyOTP = async (e) => {
    e.preventDefault();
    setOtpErrorMessage('');
    if (OtpinputValue.otp && OtpinputValue.otp.length === 6) {
      const body = {
        email: inputValue.email,
        otp: OtpinputValue.otp,
        inviteId: inviteId
      }
      const resStatus = await axios.post(BASE_URL + "/api/user/verify-new-user-otp", body)
      if (resStatus && resStatus.data.message === "OTP VERIFIED") {

        let confirmPassword = "";
        if (inputValue.password === inputValue.verifyPassword) {
          confirmPassword = inputValue.password
          const trimSpaceOrgName = trimSpaces(inputValue.orgName);
          const newUserData = {
            email: inputValue.email,
            firstName: inputValue.firstName,
            lastName: inputValue.lastName,
            orgName: trimSpaceOrgName.toLowerCase(),
            phoneNo: inputValue.phoneNo,
            password: confirmPassword,
            inviteId: invitedUserEmail
          }

          setLoaded(false);
          await axios.post(BASE_URL + "/api/user/register-user", newUserData)
            .then(async (response) => {
              setLoaded(true);
              if (response.data.message === "Sucessfully Register User") {
                successAlert("Successfully registered");
                if (inviteId) {
                  handleLoginSubmit(inputValue.email, inputValue.password, inviteId)
                }
                else {
                  handleLoginSubmit(inputValue.email, inputValue.password, inviteId);

                }

              }
              else if (response.data.message === "User With This Eamil Already Exists") {
                failureAlert("User with this email already exists");
              }
              else if (response.data.message === "User limit exceeded. Please contact the admin.") {
                failureAlert("User limit exceeded. Please contact the admin.");
              }
              else {
                failureAlert("Failed to register")
              }
            })
            .catch((error) => {
              setLoaded(true);
              console.log(error);
              failureAlert("Failed to register");
            })
        }

      }
      else if (resStatus && resStatus.data.message === "OTP EXPIRED") {
        setOtpErrorMessage("OTP Expired");
        setOtpInputValue({ otp: '' })

      }
      else if (resStatus && resStatus.data.message === "INVALID OTP") {
        setOtpErrorMessage("Invaild OTP")
        setOtpInputValue({ otp: '' })

      }
      else {
        setOtpErrorMessage("Please enter a valid OTP")
      }
    }
    else
      setOtpErrorMessage("Please enter a valid OTP")
  }


  return (
    <div>
      <Header {...props} disableHomepageButton={''} onboard inviteId={''} />
      <ToastContainer style={{ marginTop: '4rem' }} position="bottom-center" />
      <LoadingComponent loaded={loaded} />
      <OnBoard sideBannerImage={orgSignInBanner} isShowOtpComponent={showOtp}>
        <div className={loaded ? "auth-flow login" : "auth-flow login loading"} style={{ height: 'auto', maxHeight: '760px' }}>
          <div className="login-via-tabs" style={{ borderBottom: '3px solid #EA6C00' }}>
            {
              showOtp ? (
                <span className="signUp-heading">OTP Verification</span>

              )
                : (
                  <span className="signUp-heading">Sign up to create your account</span>

                )
            }
          </div>
          {!showOtp ? (<form onSubmit={async (e) => await handleSubmit(e, "OTP")} className="user-signup-form">
            <div className="auth-form" style={{ padding: '5% 0% 0% 0%' }}>

              <div className="signup-form-group" >
                <label htmlFor="email">Email ID *</label>
                <div className="tooltip-input-container">
                  {signUpFormErrorMessg.email !== "required*" && displayFieldError.email && <div className="tolltip-error-text" style={{ display: signUpFormErrorMessg.email === "" ? 'none' : 'flex' }}>{signUpFormErrorMessg.email}</div>}
                  {signUpFormErrorMessg.email === "required*" && displayFieldError.email && <div className="tolltip-error-text-required" style={{ display: signUpFormErrorMessg.email === "" ? 'none' : 'flex' }}>{signUpFormErrorMessg.email}</div>}
                  {signUpFormErrorMessg.email !== "" && <CiCircleInfo style={{ position: 'absolute', left: '95%', transform: 'translateY(13px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('email')}></CiCircleInfo>}

                  <input
                    className={signUpFormErrorMessg.email ? "error-input" : "form-control"}
                    id="email"
                    name="email"
                    type="email"
                    // placeholder="Enter Your Email"
                    onChange={(e) => handleInputChange(e)}
                    onFocus={() => setDefaultInputeValue((prev) => ({ ...prev, email: "" }))}
                    onBlur={() => { setTouch(prevState => ({ ...prevState, email: true })); }}
                    value={inputValue.email ? inputValue.email : defaultinputValue.email}
                    required
                    disabled={invitedUserEmail}
                  />


                  {signUpFormErrorMessg.email && <div className="error-message" style={{ position: 'static' }}>{signUpFormErrorMessg.email}</div>}
                </div>

                <label htmlFor="firstName">First Name *</label>
                <div className="tooltip-input-container">
                  {signUpFormErrorMessg.firstName !== "required*" && displayFieldError.firstName && <div className="tolltip-mid-error-text" style={{ display: signUpFormErrorMessg.firstName === "" ? 'none' : 'flex' }}>{signUpFormErrorMessg.firstName}</div>}
                  {signUpFormErrorMessg.firstName === "required*" && displayFieldError.firstName && <div className="tolltip-error-text-required" style={{ display: signUpFormErrorMessg.firstName === "" ? 'none' : 'flex' }}>{signUpFormErrorMessg.firstName}</div>}
                  {signUpFormErrorMessg.firstName !== "" && <CiCircleInfo style={{ position: 'absolute', left: '95%', transform: 'translateY(13px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('firstName')}></CiCircleInfo>}

                  <input
                    className={signUpFormErrorMessg.firstName ? "error-input" : "form-control"}
                    id="firstName"
                    name="firstName"
                    type="text"
                    // placeholder="Enter Your First Name"
                    onChange={(e) => handleInputChange(e)}
                    onFocus={() => setDefaultInputeValue((prev) => ({ ...prev, firstName: "" }))}
                    onBlur={() => setTouch(prevState => ({ ...prevState, firstName: true }))}
                    value={inputValue.firstName ? inputValue.firstName : defaultinputValue.firstName}
                    required
                  />
                  {signUpFormErrorMessg.firstName && <div className="error-message" style={{ position: 'static' }}>{signUpFormErrorMessg.firstName}</div>}

                </div>

                <label htmlFor="lastName">Last Name *</label>
                <div className="tooltip-input-container">
                  {signUpFormErrorMessg.lastName !== "required*" && displayFieldError.lastName && <div className="tolltip-mid-error-text" style={{ display: signUpFormErrorMessg.lastName === "" ? 'none' : 'flex' }}>{signUpFormErrorMessg.lastName}</div>}
                  {signUpFormErrorMessg.lastName === "required*" && displayFieldError.lastName && <div className="tolltip-error-text-required" style={{ display: signUpFormErrorMessg.lastName === "" ? 'none' : 'flex' }}>{signUpFormErrorMessg.lastName}</div>}
                  {signUpFormErrorMessg.lastName !== "" && <CiCircleInfo style={{ position: 'absolute', left: '95%', transform: 'translateY(13px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('lastName')}></CiCircleInfo>}

                  <input
                    className={signUpFormErrorMessg.lastName ? "error-input" : "form-control"}
                    id="lastName"
                    name="lastName"
                    type="text"
                    // placeholder="Enter Your Last Name"
                    onChange={(e) => handleInputChange(e)}
                    onFocus={() => setDefaultInputeValue((prev) => ({ ...prev, lastName: "" }))}
                    onBlur={() => setTouch(prevState => ({ ...prevState, lastName: true }))}
                    value={inputValue.lastName ? inputValue.lastName : defaultinputValue.lastName}
                    required
                  />
                  {signUpFormErrorMessg.lastName && <div className="error-message" style={{ position: 'static' }}>{signUpFormErrorMessg.lastName}</div>}
                </div>

                <label htmlFor="orgName">Organization Name *</label>
                <div className="tooltip-input-container">
                  {/* <div className="tolltip-error-text">{signUpFormErrorMessg.orgName}</div> */}
                  {signUpFormErrorMessg.orgName === "required*" && displayFieldError.orgName && <div className="tolltip-error-text-required" style={{ display: signUpFormErrorMessg.orgName === "" ? 'none' : 'flex' }}>{signUpFormErrorMessg.orgName}</div>}
                  {signUpFormErrorMessg.orgName !== "" && <CiCircleInfo style={{ position: 'absolute', left: '95%', transform: 'translateY(13px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('orgName')}></CiCircleInfo>}
                  <input
                    className={signUpFormErrorMessg.orgName ? "error-input" : "form-control"}
                    id="orgName"
                    name="orgName"
                    type="text"
                    // placeholder=""
                    onChange={(e) => handleInputChange(e)}
                    onFocus={() => setDefaultInputeValue((prev) => ({ ...prev, orgName: "" }))}
                    onBlur={() => setTouch(prevState => ({ ...prevState, orgName: true }))}
                    value={inputValue.orgName ? inputValue.orgName : defaultinputValue.orgName}
                    required
                    disabled={inviteIdOrgName}
                  />
                  {signUpFormErrorMessg.orgName && <div className="error-message" style={{ position: 'static' }}>{signUpFormErrorMessg.orgName}</div>}
                </div>

                <label htmlFor="phoneNo">Phone No (optional)</label>
                {/* <input
                  className={signUpFormErrorMessg.phoneNo ? "error-input" : "form-control"}
                  id="phoneNo"
                  name="phoneNo"
                  type="tel"
                  placeholder="9826250309"
                  onChange={(e) => handleInputChange(e)}
                  onBlur={() => setTouch(prevState => ({ ...prevState, phoneNo: true }))}
                  value={inputValue.phoneNo}
                  maxLength={10}
                /> */}
                <div className="tooltip-input-container">
                  {displayFieldError.phoneNo && <div className="tolltip-error-text" style={{ left: '-32%', transform: 'translate(14%)', display: signUpFormErrorMessg.phoneNo === "" ? 'none' : 'flex' }}>{signUpFormErrorMessg.phoneNo}</div>}
                  {signUpFormErrorMessg.phoneNo !== "" && <CiCircleInfo style={{ position: 'absolute', left: '95%', transform: 'translateY(13px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('phoneNo')}></CiCircleInfo>}

                  <PhoneInput
                    type="numbers"
                    className={signUpFormErrorMessg.phoneNo ? "phone-input-border " : "form-control-phone"}
                    name="phoneNo"
                    enableLongNumbers
                    // placeholder="+91 98765-43210"
                    country="in"
                    onChange={(val, country, e, formattedValue) => {
                      if (country && ((country.format && formattedValue && formattedValue.length === country.format.length) || (val.length <= country.dialCode.length))) {
                        setValidPhone(true);
                        setTouch(prevState => ({ ...prevState, phoneNo: false }));
                        setInputeValue((prevState) => ({
                          ...prevState,
                          phoneNo: val
                        }))
                      }
                      else {
                        setValidPhone(false);
                        setTouch(prevState => ({ ...prevState, phoneNo: false }));
                        setInputeValue((prevState) => ({
                          ...prevState,
                          phoneNo: val
                        }))
                      }
                    }}
                    value={inputValue.phoneNo ? inputValue.phoneNo : defaultinputValue.phoneNo}
                    onFocus={() => {
                      setDefaultInputeValue((prev) => ({ ...prev, phoneNo: "" }))
                      if (!inputValue.phoneNo) {
                        setInputeValue(prev => ({ ...prev, phoneNo: "+91" }));
                        setValidPhone(true);
                      }
                    }}
                    id="phoneNo"
                    containerStyle={{
                      borderBottom: "1px solid #bfc1d7",
                    }}
                  />
                  {signUpFormErrorMessg.phoneNo && <div className="error-message" style={{ position: 'static' }}>{signUpFormErrorMessg.phoneNo}</div>}
                </div>

                <label htmlFor="password">Set Password *</label>
                <div className="tooltip-input-container">
                  {signUpFormErrorMessg.password !== "required*" && displayFieldError.password && <div className="tolltip-error-text" style={{ display: signUpFormErrorMessg.password === "" ? 'none' : 'flex' }}>{signUpFormErrorMessg.password}</div>}
                  {/* {signUpFormErrorMessg.password === "required*" && <div className="tolltip-error-text" style={{left:'40%', transform: 'translate(74%)', display: signUpFormErrorMessg.password === "" ? 'none' : 'flex'}}>{signUpFormErrorMessg.password}</div>} */}
                  {signUpFormErrorMessg.password !== "" && <CiCircleInfo style={{ position: 'absolute', left: '87%', transform: 'translateY(13px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('password')}></CiCircleInfo>}

                  <div className="password-container">
                    <input
                      className={signUpFormErrorMessg.password ? "error-input" : "form-control"}
                      id="password"
                      name="password"
                      type={defaultinputValue.password || showPassword ? 'text' : 'password'}
                      // placeholder="Enter Your password"
                      onChange={(e) => handleInputChange(e)}
                      onFocus={() => setDefaultInputeValue((prev) => ({ ...prev, password: "" }))}
                      onBlur={() => setTouch(prevState => ({ ...prevState, password: true }))}
                      value={inputValue.password ? inputValue.password : defaultinputValue.password}
                      required

                    />
                    <div
                      className="pass-eye-btn"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEye style={{ position: 'absolute' }} /> : <FaEyeSlash style={{ position: 'absolute' }} />}
                    </div>
                  </div>

                  {signUpFormErrorMessg.password && <div className="error-message" style={{ position: 'static' }}>{signUpFormErrorMessg.password}</div>}
                </div>
                <label htmlFor="verifyPassword">Verify Password *</label>
                <div className="tooltip-input-container">
                  {signUpFormErrorMessg.verifyPassword !== "required*" && displayFieldError.verifyPassword && <div className="tolltip-mid-error-text" style={{ transform: 'translateX(91%)', display: signUpFormErrorMessg.verifyPassword === "" ? 'none' : 'flex' }}>{signUpFormErrorMessg.verifyPassword}</div>}
                  {/* {signUpFormErrorMessg.verifyPassword === "required*" && <div className="tolltip-error-text" style={{left:'40%', transform: 'translate(74%)', display: signUpFormErrorMessg.verifyPassword === "" ? 'none' : 'flex'}}>{signUpFormErrorMessg.verifyPassword}</div>} */}
                  {signUpFormErrorMessg.verifyPassword !== "" && <CiCircleInfo style={{ position: 'absolute', left: '87%', transform: 'translateY(13px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('verifyPassword')}></CiCircleInfo>}

                  <div className="password-container">
                    <input
                      className={signUpFormErrorMessg.verifyPassword ? "error-input" : "form-control"}
                      id="verifyPassword"
                      name="verifyPassword"
                      type={defaultinputValue.verifyPassword || showPassword ? 'text' : 'password'}
                      // placeholder="Enter Your Password"
                      onChange={(e) => handleInputChange(e)}
                      onFocus={() => setDefaultInputeValue((prev) => ({ ...prev, verifyPassword: "" }))}
                      onBlur={() => setTouch(prevState => ({ ...prevState, verifyPassword: true }))}
                      value={inputValue.verifyPassword ? inputValue.verifyPassword : defaultinputValue.verifyPassword}
                      required
                    />
                    <div
                      className="pass-eye-btn"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? <FaEye style={{ position: 'absolute' }} /> : <FaEyeSlash style={{ position: 'absolute' }} />}
                    </div>
                  </div>

                  {signUpFormErrorMessg.verifyPassword && <div className="error-message" style={{ position: 'static' }}>{signUpFormErrorMessg.verifyPassword}</div>}
                </div>
              </div>
              <ReCAPTCHA
                style={{ marginTop: '15px' }}
                sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                onChange={handleGoogleCaptcha}
              ></ReCAPTCHA>

              <button type="submit" className="btn btn-primary">
                Sign Up
              </button>

            </div>
          </form>) : (
            <form onSubmit={async (e) => await handleNewUserOtpRequest(e, "OTP")} className="user-signup-form">
              {/* <span className="text-[12px] mt-2"> {inputValue.email ? `OTP will be send to ${inputValue.email}` : ``}</span> */}
              <div className="flex items-center justify-center text-[12px] md:text-[13px] lg:text-[13px] mt-2"> Sent a verification code to verify your email id</div>

              <div className="auth-form relative" style={{ padding: '5% 0% 0% 0%' }}>
                <label htmlFor="verifyPassword"> OTP </label>
                <div className="tooltip-input-container">
                  {otpErrorMessage === "Please enter a valid OTP" && displayFieldError.otp && <div className="tolltip-error-text-required" style={{ display: otpErrorMessage === "" ? 'none' : 'flex', left: '-64%' }}>{otpErrorMessage}</div>}
                  {otpErrorMessage !== "Please enter a valid OTP" && displayFieldError.otp && <div className="tolltip-error-text-required" style={{ display: otpErrorMessage === "" ? 'none' : 'flex', left: '-36%' }}>{otpErrorMessage}</div>}
                  {otpErrorMessage !== "" && <CiCircleInfo style={{ position: 'absolute', left: '95%', transform: 'translateY(15px)', zIndex: '20', color: '#e75000', cursor: 'pointer' }} onClick={() => handleIiconClick('otp')}></CiCircleInfo>}

                  <input
                    className={otpErrorMessage !== "" ? "error-input" : "form-control"}
                    id="otp"
                    name="otp"
                    placeholder={'Enter OTP'}
                    onChange={handelOtpChange}
                    onBlur={() => setOtpTouched({ otp: true })}
                    value={OtpinputValue.otp}
                    required={showOtp && signUpOtpBtn === "verify-otp" ? true : false}
                    disabled={showOtp && signUpOtpBtn === "verify-otp" ? false : true}
                  />
                </div>

                {showOtp && signUpOtpBtn === "verify-otp" && resendCounter > 0 && <span className={`error-message ${resendCounter > 0 ? "" : "invisible"}`}>{` in 00:${resendCounter > 9 ? resendCounter : "0" + resendCounter}`}</span>}
                {showOtp && signUpOtpBtn === "verify-otp" && resendCounter === 0 ? (
                  <span className="w-full flex flex-row text-[11px] ">
                    Didn't get otp yet?
                    <div className="text-[11px] text-red-500 ml-1" style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => reSendOtp(e)}>Resend OTP</div>
                    
                  </span>
                ) : null
                }
              </div>
              {
                signUpOtpBtn === "send-otp" ? (
                  <button type="submit" className="btn btn-primary" style={{ marginTop: '30px' }}>
                    Request OTP
                  </button>
                ) : signUpOtpBtn === "verify-otp" ? (
                  <button type="button" className="btn btn-primary" onClick={(e) => verifyOTP(e)} style={{ marginTop: '30px' }}>
                    verify OTP
                  </button>
                ) : null
              }


            </form>
          )}
          {
            !showOtp ? (
              <div className="signUp-form-link-cont">
                <div className="singUp-link">Have an account? <span onClick={handleSingInPage}> Sign In</span></div>

              </div>
            ) : null
          }

        </div>
      </OnBoard >
    </div >
  )
}

export default SignUp;