import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./overviewgraph.css";

const OverviewGraph = () => {
  const { overallReports } = useSelector((state) => state.overallReports);
  const [diffRating, setDiffRating] = useState();
  const [diffScore, setDiffScore] = useState();
  const [diffTime, setDiffTime] = useState();
  const [diffUser, setDiffUsers] = useState();
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (overallReports && overallReports.data) {

      //Users change
      if (
        overallReports.data.user &&
        overallReports.data.user.sumOfUsersByWeek.length >= 2
      ) {
        setDiffUsers(
          overallReports.data.user.sumOfUsersByWeek[
          overallReports.data.user.sumOfUsersByWeek.length - 1
          ] -
          overallReports.data.user.sumOfUsersByWeek[
          overallReports.data.user.sumOfUsersByWeek.length - 2
          ]
        );
      } else if (
        overallReports.data.user &&
        overallReports.data.user.sumOfUsersByWeek.length
      ) {
        setDiffUsers(
          overallReports.data.user.sumOfUsersByWeek[
          overallReports.data.user.sumOfUsersByWeek.length - 1
          ]
        );
      }
      // Time change
      if (
        overallReports.data.time &&
        overallReports.data.time.sumOfTimeByWeek.length >= 2
      ) {
        setDiffTime(
          overallReports.data.time.sumOfTimeByWeek[
          overallReports.data.time.sumOfTimeByWeek.length - 1
          ] -
          overallReports.data.time.sumOfTimeByWeek[
          overallReports.data.time.sumOfTimeByWeek.length - 2
          ]
        );
      } else if (
        overallReports.data.time &&
        overallReports.data.time.sumOfTimeByWeek.length
      ) {
        setDiffTime(
          overallReports.data.time.sumOfTimeByWeek[
          overallReports.data.time.sumOfTimeByWeek.length - 1
          ]
        );
      }
      // Score Change
      if (
        overallReports.data.score &&
        overallReports.data.score.sumOfScoreByWeek.length >= 2
      ) {
        setDiffScore(
          overallReports.data.score.sumOfScoreByWeek[
          overallReports.data.score.sumOfScoreByWeek.length - 1
          ] -
          overallReports.data.score.sumOfScoreByWeek[
          overallReports.data.score.sumOfScoreByWeek.length - 2
          ]
        );
      } else if (
        overallReports.data.score &&
        overallReports.data.score.sumOfScoreByWeek.length
      ) {
        setDiffScore(
          overallReports.data.score.sumOfScoreByWeek[
          overallReports.data.score.sumOfScoreByWeek.length - 1
          ]
        );
      }
      //Rating change
      if (
        overallReports.data.ratings &&
        overallReports.data.ratings.sumOfRatingsByWeek.length >= 0
      ) {
        setDiffRating(
          overallReports.data.ratings.sumOfRatingsByWeek[
          overallReports.data.ratings.sumOfRatingsByWeek.length - 1
          ] -
          overallReports.data.ratings.sumOfRatingsByWeek[
          overallReports.data.ratings.sumOfRatingsByWeek.length - 2
          ]
        );
      } else if (
        overallReports.data.ratings &&
        overallReports.data.ratings.sumOfRatingsByWeek
      ) {
        setDiffRating(
          overallReports.data.ratings.sumOfRatingsByWeek[
          overallReports.data.ratings.sumOfRatingsByWeek.length - 1
          ]
        );
      }
    }
  }, [overallReports]);

  const handleAvgTime = (totalTime) => {
    const avgTime = parseFloat(totalTime);
    const hours = Math.floor(avgTime / 60);
    const minutes = Math.floor(avgTime % 60);
    const seconds = Math.round((avgTime - Math.floor(avgTime)) * 60);
    const checkPlural = (val) => val !== 1 && val !== 0 ? 's' : ''

    if (avgTime >= 1440) {
      const days = Math.floor(hours / 24);
      const remainingHours = hours % 24;
      return (
        <>
          {days} day{days > 1 ? 's' : ''}
          {isHovered &&
            <>
              {remainingHours > 0 && ` ${remainingHours} hr${checkPlural(remainingHours)}`} {minutes} min{checkPlural(minutes)} {' '}
              {seconds} sec{checkPlural(seconds)}
            </>
          }
        </>
      );
    }
    if (avgTime < 1440 && avgTime >= 60) {
      return `${hours} hr${checkPlural(hours)} ${minutes} min${checkPlural(minutes)} ${seconds} sec${checkPlural(seconds)}`;
    } else if (avgTime < 60) {
      return `${minutes} min${checkPlural(minutes)} ${seconds} sec${checkPlural(seconds)}`;
    }
  }
  return (
    <div className="overflow-wrapper">
      {/* avgerage card */}
      <div className="avg-wrapper">
        <div className="c-white-card">
          <div className="avg-title">Avg no of Users</div>
          <div className="avg-graph">
            <div className="avg-left">
              <h5>
                {overallReports &&
                  overallReports.data &&
                  overallReports.data.user &&
                  overallReports.data.user.avgUsers
                  ? Math.round(overallReports.data.user.avgUsers)
                  : 0}
              </h5>
              <span
                className={diffUser && diffUser >= 0 ? "success" : "danger"}
              >
                {diffUser ? (diffUser >= 0 ? "+" + diffUser : diffUser) : 0}{" "}
                this week
              </span>
            </div>
            <div className="avg-right">{/* graph content */}</div>
          </div>
        </div>
        <div className="c-white-card" onMouseOver={() => setIsHovered(overallReports?.data?.time?.avgTimeInMinutes > 1440 ? true : false)} onMouseOut={() => setIsHovered(false)}>
          <div className="avg-title">Avg time spent per user</div>
          <div className="avg-graph">
            <div className="avg-left">
              <h5>
                {overallReports &&
                  overallReports.data &&
                  overallReports.data.time &&
                  overallReports.data.time.avgTimeInMinutes
                  ? handleAvgTime(overallReports.data.time.avgTimeInMinutes)
                  : "0 minutes"}{" "}
              </h5>
              <span
                className={diffTime && diffTime >= 0 ? "success" : "danger"}
              >
                {
                  !isHovered &&

                  `${diffTime
                    ? diffTime >= 0
                      ? "+" + parseFloat(diffTime).toFixed(2)
                      : parseFloat(diffTime).toFixed(2)
                    : 0} this week`
                }
              </span>
            </div>
            <div className="avg-right">{/* graph content */}</div>
          </div>
        </div>
        <div className="c-white-card">
          <div className="avg-title">Avg Score</div>
          <div className="avg-graph">
            <div className="avg-left">
              <h5>
                {overallReports &&
                  overallReports.data &&
                  overallReports.data.score &&
                  overallReports.data.score.avgScore
                  ? overallReports.data.score.avgScore.toLocaleString("en-IN", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                  : 0}
              </h5>
              <span
                className={diffScore && diffScore >= 0 ? "success" : "danger"}
              >
                {diffScore ? (diffScore >= 0 ? "+" + diffScore : diffScore) : 0}{" "}
                this week
              </span>
            </div>
            <div className="avg-right">{/* graph content */}</div>
          </div>
        </div>
        <div className="c-white-card">
          <div className="avg-title">Avg User rating</div>
          <div className="avg-graph">
            <div className="avg-left">
              <h5>
                {overallReports &&
                  overallReports.data &&
                  overallReports.data.ratings &&
                  overallReports.data.ratings.avgRating
                  ? parseFloat(overallReports.data.ratings.avgRating).toFixed(2)
                  : 0}
                <span>/5</span>
              </h5>
              <span
                className={diffRating && diffRating >= 0 ? "success" : "danger"}
              >
                {diffRating
                  ? diffRating >= 0
                    ? "+" + parseFloat(diffRating).toFixed(2)
                    : parseFloat(diffRating).toFixed(2)
                  : 0}{" "}
                this week
              </span>
            </div>
            <div className="avg-right">{/* graph content */}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewGraph;
