const sidebarContent = [
  { title: "My Details", redirectLink: "/account/details" },
  { title: "Organisation Details", redirectLink: "/account/org-details" },
  { title: "My Plan", redirectLink: "/account/plan-details" },
  { title: "Security", redirectLink: "/account/security" }
]

const sidebarContentMyAccount = [
  ...sidebarContent
];

const sidebarContentMyAccountWithXoxo = [
  ...sidebarContent,
  { title: "XOXODay Storefront", redirectLink: "/account/xoxo-day" }
];


const sidebarContentMyAccountUser = [
  { title: "My Details", redirectLink: "/account/details" },
  { title: "Team Details", redirectLink: "/account/team-details" },
  { title: "Security", redirectLink: "/account/security" }

];

const sidebarContentMyAccountUserForDummyUser = [
  { title: "My Details", redirectLink: "/account/details" }
];


export {
  sidebarContentMyAccountUserForDummyUser,
  sidebarContentMyAccount,
  sidebarContentMyAccountUser,
  sidebarContentMyAccountWithXoxo
};