

import React, { useCallback, useEffect, useRef, useState } from 'react';


import { BASE_URL, S3_BASE_URL, axiosApiInstance, failureAlert, successAlert } from '../../helpers/helper';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgTeams } from '../../redux/actions/teamAction';
import LoadingComponent from '../loader/LoadingComponent';
import { ToastContainer } from 'react-toastify';
import { Link, useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Header from '../header/header';
import { FaUsersLine } from 'react-icons/fa6';
import arrowback from "../../assets/images/arrow-back.svg";
import excel from "../../assets/images/excel.svg";
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineModeEditOutline } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import { FaUserCircle } from "react-icons/fa";
import { IoIosAddCircle } from "react-icons/io";
import { getUser, logOut } from '../../redux/actions/userAction';
import DeleteTeamModal from '../modal/deleteTeamModal';
import { uploadFile } from '../../redux/actions/commonActions';
import AddTeamModal from '../modal/addTeamModal';
import PlayIcon from "../../assets/backgrounds/team logo.png"
import { CiSearch } from "react-icons/ci";
import { GoStar } from "react-icons/go";
import { GoStarFill } from "react-icons/go";


import { data } from 'synonyms/dictionary';
import { BsUpload } from 'react-icons/bs';
import Confirm from '../modal/confirm';
import SampleCsvAddTeamUser from '../modal/sampleCsvAddTeamUser';




const AddManageTeams = (props) => {


    const [loaded, setLoaded] = useState(true);
    const [teams, setTeams] = useState([])
    const [selectedTeamInfo, setSelectedTeamInfo] = useState()

    const [orgAllUsers, setOrgAllUsers] = useState([]);
    const [selectedTeamUsersData, setSelectedTeamUsersData] = useState([])
    const [teamCaptainData, setTeamCaptainData] = useState({
        userId: null,
        teamId: null
    })

    const [teamLogo, setTeamLogo] = useState('')

    const [openDeleteUserModal, setOpenDeleteUserModal] = useState(false);
    const [deleteUserId, setDeleteUserId] = useState('');
    const [openAddTeamModal, setOpenAddTeamModal] = useState(false)
    const [teamMemberSearch, setTeamMemberSearch] = useState();
    const [openConfirmUploadModal, setOpenConfirmUploadModal] = useState(false);
    const [uploadFlag, setUploadFlag] = useState(false);
    const [file, setFile] = useState(null);
    const [modalOne, setModalOne] = useState(false)
    const [deleteTeamId, setDeleteTeamId] = useState('');
    const [toggalDeleteTeamModel, setToggalDeleteTeamModal] = useState(false);



    const getAllOrgsTeam = useSelector((state) => state.getOrgsTeams);
    const { orgTeams } = getAllOrgsTeam;
    const UserInfo = useSelector((state) => state.getUser);
    const { userInfo } = UserInfo;
    const fileUpload = useSelector(state => state.uploadedFile);
    const { uploadedFile } = fileUpload;
    const history = useHistory();
    const dispatch = useDispatch();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const teamId = queryParams.get('teamId');


    const fileInputRef = useRef(null);
    const addTeamMembersCsv = useRef(null)
    const editTeamRef = useRef(null)

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        if (uploadedFile && uploadedFile.data) {
            let isImagePath = isImageFileType(uploadedFile.data.path)
            if (isImagePath) {
                setTeamLogo(uploadedFile.data.path)

            }

        }

    }, [uploadedFile, fileUpload])


    const handleCsvFileClick = () => {
        addTeamMembersCsv.current.click();
    };


    const isImageFileType = (path) => {
        const validImageExtensions = ['png', 'jpg', 'jpeg', 'gif'];
        const extension = path.split('.').pop().toLowerCase();
        return validImageExtensions.includes(extension);
    };


    useEffect(() => {
        if (userInfo && userInfo.data && userInfo.data.organizationId) {
            setTeams([])
            dispatch(getOrgTeams(userInfo.data.organizationId))
        }

    }, [userInfo])

    useEffect(() => {
        if (orgTeams && orgTeams.message === "Sucessfully fetch org teams") {
            setTeams(orgTeams.data);
            setSelectedTeamUsersData([])
            // if (selectedTeamInfo && selectedTeamInfo?.id) {
            //     let getSelectedTeam = orgTeams.data.filter((team) => team.id === selectedTeamInfo.id)
            //     setSelectedTeamInfo(getSelectedTeam)
            //     getAllUsers(getSelectedTeam.id, '')
            //     setTeamLogo(getSelectedTeam.teamLogo);
            //     setTeamMemberSearch('')
            //     if (getSelectedTeam && getSelectedTeam.teamCaptainId) {
            //         setTeamCaptainData({
            //             userId: getSelectedTeam.teamCaptainId,
            //             teamId: getSelectedTeam.id
            //         })
            //     }
            //     else {
            //         setTeamCaptainData({
            //             userId: null,
            //             teamId: null
            //         })
            //     }
            // }

            setSelectedTeamInfo(orgTeams.data[0])
            getAllUsers(orgTeams.data[0].id, '')
            setTeamLogo(orgTeams.data[0].teamLogo);
            setTeamMemberSearch('')
            if (orgTeams.data[0] && orgTeams.data[0].teamCaptainId) {
                setTeamCaptainData({
                    userId: orgTeams.data[0].teamCaptainId,
                    teamId: orgTeams.data[0].id
                })
            }
            else {
                setTeamCaptainData({
                    userId: null,
                    teamId: null
                })
            }

        }
        else if (orgTeams && orgTeams.message === "org teams not available") {
            setTeams([]);
        }


    }, [getAllOrgsTeam, orgTeams])


    const getAllUsers = async (orgteamId, searchText) => {
        setLoaded(false)
        const usersInfo = await axiosApiInstance.post(BASE_URL + "/api/organization-team/get-allusers", { organizationId: userInfo.data.organizationId, organisationTeamId: orgteamId, search: searchText })
        if (usersInfo && usersInfo.data && usersInfo.data.message === "Succesfully fetch organization users") {
            setOrgAllUsers(usersInfo.data.data);
        }
        setLoaded(true)
    }

    const handleTeamEdit = async (teamData) => {
        
        if (selectedTeamInfo && teamData.id === selectedTeamInfo.id) {
            return
        }
        setSelectedTeamUsersData([])
        setSelectedTeamInfo(teamData)
        getAllUsers(teamData.id, '')
        setTeamLogo(teamData?.teamLogo);
        setTeamMemberSearch('')
        if (teamData && teamData.teamCaptainId) {
            setTeamCaptainData({
                userId: teamData.teamCaptainId,
                teamId: teamData.id
            })
        }
        else {
            setTeamCaptainData({
                userId: null,
                teamId: null
            })
        }
    }

    // useEffect(() => {
    //     console.log("teamLogo: " + teamLogo);
    // }, [teamLogo])


    const handleAddTeam = (e) => {
        e.preventDefault();
        setOpenAddTeamModal(true)
    }


    const handleTeamChange = (userId, teamId) => {

        setSelectedTeamUsersData((prevSelectedTeams) => {
            const updatedSelection = prevSelectedTeams.filter(selection => selection.userId !== userId);
            const updatedSelectionUnique = prevSelectedTeams.find(selection => selection.userId === userId);
            const userSelection = orgAllUsers.find(user => user.id === userId)?.organizationTeam?.id;

            if (userSelection && userSelection === teamId && !updatedSelectionUnique) {
                updatedSelection.push({ userId, teamId: null });
                if (teamCaptainData && teamCaptainData.teamId === teamId && teamCaptainData.userId === userId) {
                    setTeamCaptainData({
                        userId: null,
                        teamId: null
                    })
                }

            } else if (updatedSelectionUnique) {
                if (updatedSelectionUnique.teamId === teamId) {
                    updatedSelection.push({ userId, teamId: null });
                    if (teamCaptainData && teamCaptainData.teamId === teamId && teamCaptainData.userId === userId) {
                        setTeamCaptainData({
                            userId: null,
                            teamId: null
                        })
                    }

                } else {
                    updatedSelection.push({ userId, teamId });
                }
            } else {
                updatedSelection.push({ userId, teamId });
            }

            return updatedSelection;
        });


    };

    const getSelectedChekced = (userId) => {
        const userSelection = selectedTeamUsersData.find(selection => selection.userId === userId);
        return userSelection && userSelection.teamId === null ? false : userSelection && userSelection.teamId !== null ? true : (orgAllUsers.find(user => user.id === userId)?.organizationTeam?.id ? true : false);
    };


    const handleTeamCaptainSelection = (userId) => {
        let isUserSeleceted = getSelectedChekced(userId);
        if (userId && isUserSeleceted === true) {
            setTeamCaptainData({
                userId: userId,
                teamId: selectedTeamInfo.id
            })
        }
    }

    // const getSelectedTeamCaptainChecked = (userId) => {
    //     const userSelection = teamCaptainData.userId === userId
    //     return userSelection ? true : false
    // };

    useEffect(() => {
        if (selectedTeamInfo && selectedTeamInfo?.id && teamMemberSearch && teamMemberSearch.length > 0) {
            getAllUsers(selectedTeamInfo.id, teamMemberSearch)
        }
        else if (selectedTeamInfo && selectedTeamInfo?.id && teamMemberSearch === '') {
            getAllUsers(selectedTeamInfo.id, '')
        }

    }, [teamMemberSearch])

    // useEffect(() => {
    //     if (selectedTeamUsersData && selectedTeamUsersData.length > 0) {
    //         console.log("update team user info: " + JSON.stringify(selectedTeamUsersData));
    //     }
    // }, [selectedTeamUsersData])

    // useEffect(() => {
    //     if (teamCaptainData && teamCaptainData.userId) {
    //         console.log("team captain info: " + JSON.stringify(teamCaptainData));
    //     }
    // }, [teamCaptainData])



    const handleBack = () => {
        history.push('/manage-users-team')
    }

    const handleSaveChanges = async (e) => {
        e.preventDefault();

        let isTeamUserAssign = orgAllUsers.filter((user) => user.organisationTeamId === selectedTeamInfo.id);
        if (isTeamUserAssign.length < 1 && selectedTeamUsersData && selectedTeamUsersData.length < 1) {
            return failureAlert("Please assign team members")
        }
      

        if (!teamLogo) {
            if (selectedTeamUsersData && selectedTeamUsersData.length < 1 && teamCaptainData.userId === selectedTeamInfo.teamCaptainId) {
                return;
            }

        }
        else {
            if (selectedTeamUsersData && selectedTeamUsersData.length < 1 && teamCaptainData.userId === selectedTeamInfo.teamCaptainId  && teamLogo === selectedTeamInfo.teamLogo) {
                return;
            }
        }

        if (!teamCaptainData.teamId || !teamCaptainData.userId) {
            return failureAlert("Please assign team captain")
        }


        if (userInfo && userInfo.data && userInfo.data.organizationId) {

            const body = {
                teamcaptainInfo: teamCaptainData,
                usersTeam: selectedTeamUsersData,
                teamLogoData: {
                    teamId: selectedTeamInfo.id,
                    logo: teamLogo ? teamLogo : null
                }
            }

            const response = await axiosApiInstance.post(BASE_URL + "/api/organization-team/update-team", body);
            if (response && response.data && response.data.message === "org user Updated sucessfully") {
                successAlert("sucessfully added user to team");
                setTeamCaptainData({
                    teamId: null,
                    userId: null
                });
                setSelectedTeamUsersData([]);
                setTeams([]);
                setTeamLogo('');
                setTeamMemberSearch('');
                dispatch(getOrgTeams(userInfo.data.organizationId));

            }
            else {
                failureAlert("!Opps something went wrong")
            }
        }
        else {
            failureAlert("!Opps something went wrong")
        }



    }

    const fileChanged = async (e) => {
        e.persist();
        const file = e.target.files[0]
        e.target.value = null;
        const img = new Image();
        let width, height;
        img.onload = async function () {
            height = this.height;
            width = this.width;
            if (e.target.name === "team-logo-upload") {
                if (width === 127 && height === 127) {
                    const data = new FormData();
                    data.append("team-logo", file);
                    const response = await dispatch(uploadFile(data));
                    if (response?.status?.includes?.("417"))
                        failureAlert("Uploaded file contains some malware!");
                    else if (response?.status?.includes?.("500"))
                        failureAlert("File format not supported");
                }
                else {
                    failureAlert("The team logo dimensions should be 127 x 127.")

                }

            }
        }
        const _URL = window.URL || window.webkitURL;
        img.src = _URL.createObjectURL(file);
    }

    const csvFileChanged = (e) => {
        e.persist();
        const inputFile = e.target.files[0];
        e.target.value = [];
        setFile(inputFile);
        setOpenConfirmUploadModal(true);

    };

    useEffect(() => {
        async function callBack() {
            if (uploadFlag) {
                if (file) {
                    const fileData = new FormData();
                    fileData.append("sheets", file);
                    const { status, data } = await dispatch(uploadFile(fileData, true));
                    if (status === 200) {
                        if (data && data.data && data.data.path) {
                            const { path } = data.data;
                            const checkUserActivity = await axiosApiInstance.post(BASE_URL + '/api/organization-team/add-members/csv', { filePath: path.replace(BASE_URL + "/", "") })
                            if (checkUserActivity.data.message === "Users added to team sucesssfully") {
                                successAlert("Users assigned to the team successfully");

                                setTeamCaptainData({
                                    teamId: null,
                                    userId: null
                                });
                                setSelectedTeamUsersData([]);
                                setTeams([]);
                                setTeamLogo('');
                                setTeamMemberSearch('');
                                dispatch(getOrgTeams(userInfo.data.organizationId));

                            }
                            else if (checkUserActivity.data.message === "there can be only one captain") {
                                failureAlert("File contain more then 1 captain")
                            }
                            else if (checkUserActivity.data.message === "Invalid team Name") {
                                failureAlert("File contain invalid team Name")
                            }
                            else if (checkUserActivity.data.message === "Invalid Fields") {
                                failureAlert("Invald file format")
                            }
                            else if (checkUserActivity.data.message === "invaild field data") {
                                failureAlert("File contain invaild user data")
                            }
                            else {
                                failureAlert("Something went wrong")
                            }
                            setSelectedTeamUsersData([]);
                            setTeams([]);
                            setTeamLogo('');
                            setTeamMemberSearch('');
                            dispatch(getOrgTeams(userInfo.data.organizationId));

                        }
                    }
                    else if (status?.includes?.("417"))
                        failureAlert("Uploaded file contains some malware!");
                    else if (status?.includes?.("500"))
                        failureAlert("File Format Not supported");
                    setFile(null);
                }
                setUploadFlag(false);
            }
        }
        callBack();
    }, [uploadFlag]);

    const handleScheduleGame = () => {
        history.push('/schedule/teams-games')
    }

    function capitalizeWords(str) {
        if (!str) {
            return '';
        }
        return str.split(' ').map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        ).join(' ');
    }


    const handleDeleteTeam = async (teamId) => {
        setLoaded(false)
        if (!teamId) {
            return failureAlert("failed to delete team")
        }
        const body = {
            teamId: teamId
        }
        const response = await axiosApiInstance.post(BASE_URL + "/api/organization-team/delete-team", body);
        if (response && response.data && response.data.message === "sucessfully deleted team") {
            successAlert("Sucessfully deleted team")
            if (userInfo && userInfo.data && userInfo.data.organizationId) {
                setSelectedTeamUsersData([]);
                setTeams([]);
                setTeamLogo('');
                setTeamMemberSearch('');
                setSelectedTeamInfo()
                dispatch(getOrgTeams(userInfo.data.organizationId));
            }
        }
        else {
            failureAlert("failed to delete team")
        }

        setDeleteTeamId('')
        setToggalDeleteTeamModal(false)
        setLoaded(true)
    }
    const handleTemmDeleteClick = (teamId) => {
        setDeleteTeamId(teamId)
        setToggalDeleteTeamModal(true)
    }
    const signOut = async () => {
        await dispatch(logOut());
        if (props.history)
            props.history.push("/");

    };


    return (<>
        <Header profile signOut={signOut} />

        <div className="flex w-[100%] h-[100vh] flex-col items-center justify-start pt-[90px] lg:pt-[130px] md:pr-8 md:pl-8 lg:pr-10 lg:pl-10 pr-5 pl-5 pb-5 overflow-y-scroll">

            <ToastContainer position='bottom-center' />
            <LoadingComponent loaded={loaded} />

            <div className="flex flex-col lg:flex-row w-full items-center justify-start ">
                <div className="flex flex-col justify-end items-center w-[100%]  lg:w-[25%] h-[100px] lg:h-[152px]  rounded-[6px] mb-7 lg:mb-0">
                    <div className="flex items-start justify-start w-[100%] h-[20%]">
                        <img src={arrowback} alt="back" onClick={handleBack} className='flex h-[30px] w-[30px] hover:cursor-pointer' />

                    </div>
                    <div className="flex items-center justify-center lg:justify-start  w-[100%] h-[80%]">
                        {/* <h1 className='text-[34px] text-center font-semibold lg:ml-12'> Edit Teams</h1> */}

                    </div>
                </div>
                <div className="flex flex-row justify-start sm:justify-center md:justify-center lg:justify-center items-center w-[100%]  lg:w-[824px] h-[99px] sm:h-[120px] md:h-[152px] lg:h-[152px] rounded-[6px] p-[13px]"
                    style={{
                        background: 'linear-gradient(90deg, #4587DC 0%, #9DC9F5 100%)'
                    }}
                >
                    <div className="flex w-[66px] h-[66px] sm:h-[100px] sm:w-[100px] md:h-[127px] md:w-[127px] lg:h-[127px] lg:w-[127px] justify-center items-center  rounded-[6px] relative  ml-0">
                        <img
                            src={teamLogo ? `${S3_BASE_URL}${teamLogo}` : PlayIcon}
                            alt="logo" className="h-full w-full object-cover rounded-[8px]" />

                        <div className="flex absolute bg-white h-[32px] w-[32px] bottom-0 right-[-15px] rounded-[6px] items-center justify-center cursor-pointer" onClick={handleButtonClick}>
                            <div className="flex items-center justify-center w-full h-full bg-[#E2556926] rounded-[6px] relative">
                                <FaRegEdit className="h-[15px] w-[15px] rounded-[6px]" fill='#E25569' />
                                <input
                                    type="file"
                                    className="hidden w-[32px] h-[32px] rounded-[6px] opacity-0 cursor-pointer  "
                                    name="team-logo-upload"
                                    onChange={fileChanged}
                                    accept="image/x-png,image/jpg,image/jpeg,image/gif"
                                    ref={fileInputRef}

                                />

                            </div>

                        </div>

                    </div>
                    <div className="flex flex-col items-center justify-center p-10">
                        <h1 className=' text-center text-[24px] sm:text-[30px] md:text-[42px] lg:text-[42px] font-semibold text-white'> {selectedTeamInfo && selectedTeamInfo?.name ? capitalizeWords(selectedTeamInfo.name) : 'Team Name'}</h1>
                        <span className='text-[18px] md:text-[20px] lg:text-[20px] font-light text-white'>  {`Members ${selectedTeamInfo && selectedTeamInfo?.members ? selectedTeamInfo.members.length : 0}`}</span>
                    </div>

                </div>

            </div>
            <div className="flex flex-col lg:flex-row items-start justify-center w-full mt-5">

                <div className="flex flex-col items-center justify-start w-[100%] lg:w-[35%] mt-[30px] lg:mt-0">
                    <div className="flex flex-col items-center justify-start w-full h-[279px] bg-[#FFF7EF] rounded-[6px] p-[20px] relative overflow-hidden"
                        style={{
                            background: 'linear-gradient(90deg, #F5899E 0%, #F7C2B5 100%)'
                        }}
                    >
                        <div className="flex h-[290px] w-[290px] rounded-full bg-[#FFFFFF33] absolute right-[-20px] bottom-[-65px] z-0"></div>
                        <div className="flex h-[170px] w-[170px] rounded-full bg-[#FFFFFF33] absolute right-[-25px] top-[-25px] z-0"></div>

                        <div className="flex flex-row w-full h-[40px] p-[10px] pb-0 z-50">
                            <div className="flex items-center w-[32%] text-[18px] font-semibold text-white">Team</div>
                            <div className="flex items-center justify-center w-[32%] text-[18px] font-semibold text-white">Members</div>
                            <div className="flex items-center justify-end w-[32%] text-[18px] font-semibold mr-[10px] text-white">Action</div>

                        </div>
                        <div className="flex flex-col items-center justify-start overflow-y-scroll no-scrollbar w-full h-auto rounded-[6px] pt-[17px] z-50">
                            {
                                teams && teams.length > 0 ? teams.map((team, index) => {
                                    return (
                                        <div className={`flex flex-row  p-[10px] bg-[#FFFFFF80] rounded-[6px] mb-[5px] h-[45px] z-50 ${selectedTeamInfo && selectedTeamInfo.id === team.id ? `w-[100%]` : `w-[96%]`}`}>
                                            <div className="flex flex-row w-full rounded-[6px] ">
                                                <span className='flex items-center justify-start w-1/3 text-[16px]'>{team ? capitalizeWords(team.name) : ''}</span>
                                                <span className='flex items-center justify-center w-1/3 text-[16px]'>{team?.members.length}</span>
                                                <div className='flex items-center justify-end w-1/3  mr-[15px]'>
                                                    <div className="flex items-center justify-center w-[32px] h-[32px] rounded-[6px] cursor-pointer mr-4" onClick={(e) => handleTeamEdit(team)} >
                                                        <div className="flex items-center justify-center w-full h-full bg-[#E2556914] rounded-[6px]">
                                                            <FaRegEdit className="h-[15px] w-[15px] rounded-[6px]" fill='#E25569' />
                                                        </div>
                                                    </div>

                                                    <div className="flex items-center justify-center w-[32px] h-[32px] rounded-[6px] cursor-pointer ">
                                                        <div className="animated-btn-tooltip">


                                                            <button class="session-delete-button"

                                                                onClick={(e) => handleTemmDeleteClick(team.id)}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 69 14"
                                                                    class="svgIcon bin-top"
                                                                >
                                                                    <g clip-path="url(#clip0_35_24)">
                                                                        <path
                                                                            fill="black"
                                                                            d="M20.8232 2.62734L19.9948 4.21304C19.8224 4.54309 19.4808 4.75 19.1085 4.75H4.92857C2.20246 4.75 0 6.87266 0 9.5C0 12.1273 2.20246 14.25 4.92857 14.25H64.0714C66.7975 14.25 69 12.1273 69 9.5C69 6.87266 66.7975 4.75 64.0714 4.75H49.8915C49.5192 4.75 49.1776 4.54309 49.0052 4.21305L48.1768 2.62734C47.3451 1.00938 45.6355 0 43.7719 0H25.2281C23.3645 0 21.6549 1.00938 20.8232 2.62734ZM64.0023 20.0648C64.0397 19.4882 63.5822 19 63.0044 19H5.99556C5.4178 19 4.96025 19.4882 4.99766 20.0648L8.19375 69.3203C8.44018 73.0758 11.6746 76 15.5712 76H53.4288C57.3254 76 60.5598 73.0758 60.8062 69.3203L64.0023 20.0648Z"
                                                                        ></path>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_35_24">
                                                                            <rect fill="white" height="14" width="69"></rect>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>

                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 69 57"
                                                                    class="svgIcon bin-bottom"
                                                                >
                                                                    <g clip-path="url(#clip0_35_22)">
                                                                        <path
                                                                            fill="black"
                                                                            d="M20.8232 -16.3727L19.9948 -14.787C19.8224 -14.4569 19.4808 -14.25 19.1085 -14.25H4.92857C2.20246 -14.25 0 -12.1273 0 -9.5C0 -6.8727 2.20246 -4.75 4.92857 -4.75H64.0714C66.7975 -4.75 69 -6.8727 69 -9.5C69 -12.1273 66.7975 -14.25 64.0714 -14.25H49.8915C49.5192 -14.25 49.1776 -14.4569 49.0052 -14.787L48.1768 -16.3727C47.3451 -17.9906 45.6355 -19 43.7719 -19H25.2281C23.3645 -19 21.6549 -17.9906 20.8232 -16.3727ZM64.0023 1.0648C64.0397 0.4882 63.5822 0 63.0044 0H5.99556C5.4178 0 4.96025 0.4882 4.99766 1.0648L8.19375 50.3203C8.44018 54.0758 11.6746 57 15.5712 57H53.4288C57.3254 57 60.5598 54.0758 60.8062 50.3203L64.0023 1.0648Z"
                                                                        ></path>
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_35_22">
                                                                            <rect fill="white" height="57" width="69"></rect>
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>
                                                            </button>
                                                            <div className="hover-tooltip" role="tooltip" style={{ top: '-24px' }}>
                                                                <span>Delete</span>
                                                            </div>

                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                    :
                                    (
                                        <div className='flex flex-col w-full p-[10px] bg-white rounded-[6px] mb-[5px] h-[30vh] items-center justify-center'>
                                            No team present
                                        </div>
                                    )

                            }
                            {/* {
                                teams && teams.length <= 0 && (
                                    <div className='flex flex-col w-full p-[10px] bg-white rounded-[6px] mb-[5px] h-[30vh] items-center justify-center'>
                                        No team present
                                    </div>
                                )
                            } */}


                        </div>

                    </div>
                    <div className="flex items-center justify-center w-full mt-[25px]">
                        <button
                            className={`w-[120px] h-[32px] text-[14px] btn btn-secondry `}
                            onClick={(e) => handleAddTeam(e)}

                        >
                            +Add Team
                        </button>
                    </div>
                </div>

                {
                    teams && teams.length > 0 && orgAllUsers && orgAllUsers.length > 0 ? (<>
                        <div className="flex flex-col items-center justify-start w-[100%] lg:w-[32.5%]  mt-[30px] lg:mt-0 lg:ml-[18px] lg:mr-[18px]">
                            <div className="flex flex-col items-center justify-start w-full  h-[279px] rounded-[6px] p-[20px] pb-0 relative overflow-hidden"
                                style={{
                                    background: 'linear-gradient(90deg, #4587DC 0%, #9DC9F5 100%)'
                                }}
                            >
                                <div className="flex h-[295px] w-[295px] rounded-full bg-[#FFFFFF33] absolute right-[-30px] bottom-[-55px] z-0"></div>
                                <div className="flex h-[165px] w-[165px] rounded-full bg-[#FFFFFF33] absolute right-[-20px] top-[-35px] z-0"></div>

                                <div className="flex flex-col items-center rounded-[6px] w-full h-[70px]">
                                    <div className="flex flex-row w-full h-[46px] bg-white rounded-[6px] relative ">
                                        <div className="flex items-center justify-center h-full w-[46px] absolute left-0">
                                            <CiSearch className='h-[23px] w-[23px]' />
                                        </div>
                                        <input type="text" className="input-text-field rounded-[6px] h-[46px] pl-[46px]" placeholder="Find Participants"
                                            onChange={(e) => setTeamMemberSearch(e.target.value)}
                                            value={teamMemberSearch} />
                                    </div>
                                </div>

                                <div className="flex flex-col items-center justify-start overflow-y-scroll no-scrollbar w-full h-auto max-h-[160px] rounded-[6px] mt-2 z-50">
                                    {
                                        orgAllUsers && orgAllUsers.length > 0 && orgAllUsers.map((user, index) => {
                                            return (
                                                <div className="flex flex-row w-full bg-[#FFFFFF80] rounded-[6px] h-[46px] mb-[5px] items-center justify-between p-[10px]">
                                                    <span className=' overflow-text w-auto'>{`${user.firstName ? capitalizeWords(user.firstName) : ''} ${user?.lastName ? capitalizeWords(user.lastName) : ''}`}</span>

                                                    <div className="flex flex-row h-full items-center justify-center">
                                                        {
                                                            teamLogo && getSelectedChekced(user.id) ? (
                                                                <div className="flex items-center justify-center h-[25px] w-[25px] rounded-[6px] mr-5 bg-transparent">
                                                                    <img
                                                                        src={teamLogo ? `${S3_BASE_URL}${teamLogo}` : PlayIcon}
                                                                        alt="logo" className="h-full w-full object-cover rounded-[5px]" />
                                                                </div>
                                                            )
                                                                : null
                                                        }


                                                        <div className="flex h-[32px] w-[32px] items-center justify-center"
                                                        // onClick={(e) => e.preventDefault()}
                                                        >

                                                            <label class="checkbox-label">
                                                                <input type="checkbox" class="checkbox-tow bg-[#f9cdd3] hover:bg-[#f9cdd3]"
                                                                    style={{ background: '#f9cdd3' }}
                                                                    checked={getSelectedChekced(user.id)}
                                                                    onClick={(e) => { handleTeamChange(user.id, selectedTeamInfo.id) }}
                                                                />
                                                                <div class="svg-icon text-[#fefefe]">
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        height="16px"
                                                                        viewBox="0 0 448 512"
                                                                    >
                                                                        <path
                                                                            d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                                            fill='#E25569'
                                                                        ></path>
                                                                    </svg>
                                                                </div>
                                                                <span class="check-box-container bg-[#E2556914]"></span>
                                                            </label>
                                                        </div>

                                                        <div className="flex items-center justify-center h-full ml-2 mr-1">
                                                            {
                                                                teamCaptainData && teamCaptainData.userId === user.id ? (
                                                                    <GoStarFill style={{ width: '22px', height: '21px', cursor: 'pointer' }} fill='#5ECA87' onClick={(e) => handleTeamCaptainSelection(user.id)} />
                                                                )
                                                                    : (
                                                                        <GoStar style={{ width: '22px', height: '21px', color: '#23282E52', opacity: '0.5', cursor: 'pointer' }} onClick={(e) => handleTeamCaptainSelection(user.id)} />
                                                                    )
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }



                                </div>

                                <div className="flex flex-row w-full min-h-[30px] max-h-[30px] items-end mb-[10px] mt=[10px]">
                                    <div className="flex w-[23px]  items-start">
                                        <GoStarFill className='w-[15px] h-[16px] sm:w-[18px] sm:h-[19px] md:w-[20px] md:h-[21px] lg:w-[20px] lg:h-[21px]' fill='#5ECA87' />

                                    </div>
                                    <span className=' text-[9px] sm:text-[12px] md:text-[12px] lg:text-[12px] font-medium ml-1 text-white'>"Choosing The Star Will Assign The Teammate As Captain."</span>

                                </div>
                            </div>


                            <div className="flex items-center justify-center w-full mt-[25px]">
                                <button
                                    className={`w-[120px] h-[32px] text-[14px] btn btn-secondry `}
                                    onClick={(e) => handleSaveChanges(e)}
                                    style={{
                                        fontSize: '14px'
                                    }}

                                >
                                    Save Changes
                                </button>
                                {/* <button type='button' className='w-[100%] sm:w-[160px] md:w-[160px] lg:w-[160px] h-[48px] text-[14px] btn btn-primary ml-10 lg:ml-0' onClick={handleSaveChanges}>Save Changes</button> */}

                            </div>

                        </div>

                        <div className="flex flex-col items-center justify-start w-[100%] lg:w-[20.5%] mt-[30px] lg:mt-0">
                            <div className="flex flex-col items-center lg:items-start justify-between w-full h0[220px] lg:h-[279px] rounded-[6px] ">
                                {/* <div className="flex flex-col items-start justify-start w-full h-[200px] p-[20px] rounded-[6px] relative overflow-hidden"
                            style={{
                                background: 'linear-gradient(90deg, #66C5AF 0%, #98D7D2 100%)'
                            }}
                        >
                            <div className="flex h-[220px] w-[220px] rounded-full bg-[#FFFFFF33] absolute right-[-15px] bottom-[-90px] z-0"></div>
                            <div className="flex h-[130px] w-[130px] rounded-full bg-[#FFFFFF33] absolute right-[-10px] top-[-25px] z-0"></div>
                            <h4 className='text-[20px] font-semibold text-white'>Upload File</h4>
                            <div className="flex flex-row mt-5 z-50">

                                <h4 style={{ fontSize: '16px', marginRight: '15px', color: 'white' }}> CSV file format</h4>
                                <Link
                                    onClick={() => setModalOne(true)}
                                    to={'#'}>
                                    <img src={excel} alt="csv" />
                                    <span style={{ fontSize: '15px', color: 'white', marginLeft: '3px', fontWeight: '200', textDecoration: 'underline' }}>View Sample</span>
                                </Link>

                            </div>
                            <div className="flex flex-row justify-between  w-[100%] rounded-[6px] bg-white mt-[10px] p-[13px] z-50">
                                <span className=" text-[15px] text-[#23282E] opacity-[0.5] flex justify-center items-center ">Upload CSV file to add users</span>
                                <div className="flex items-center justify-center h-[100%] btn-tooltip">
                                    <button type="submit"
                                        className={` btn btn-secondry w-[120px] h-[33px]`}
                                        style={{
                                            padding: '0px',
                                        }}
                                        onClick={handleCsvFileClick}
                                    >
                                        <input
                                            type="file"
                                            accept=".csv"
                                            onChange={csvFileChanged}
                                            className="hidden"
                                            ref={addTeamMembersCsv}
                                        />
                                        <BsUpload />

                                        <span className=" text-[14px] " csvFileChanged> Upload Excel</span>
                                    </button>

                                </div>

                            </div>
                        </div> */}
                                <div className=" hidden w-full lg:flex items-center ">

                                    <button
                                        className={`w-[160px] h-[48px] text-[14px] btn btn-primary hidden lg:flex`}
                                        onClick={handleScheduleGame}

                                    >
                                        Create A Game
                                    </button>
                                </div>


                            </div>

                            <div className="lg:hidden flex flex-row w-full items-center justify-center lg:mt-3 mt-[30px] pb-[40px] lg:pb-0">

                                <button
                                    className={` w-[160px] h-[48px] text-[14px] btn btn-primary  `}
                                    onClick={handleScheduleGame}

                                >
                                    Create Game
                                </button>

                                {/* <button type='button' className='w-[100%] sm:w-[160px] md:w-[160px] lg:w-[160px] h-[48px] text-[14px] btn btn-primary ml-10 lg:ml-0' onClick={handleSaveChanges}>Save Changes</button> */}
                            </div>



                        </div>
                    </>)
                        : null
                }




            </div>

            {/* <div className="hidden lg:flex w-[100%] flex-row items-center justify-center lg:mt-3"> */}
            {/* <div className=" flex w-full sm:w-auto lg:hidden items-center">
                    <button
                        className={` w-[100%] sm:w-[160px] md:w-[160px] lg:w-[160px] h-[48px] text-[14px] btn btn-primary  `}
                        onClick={handleScheduleGame}

                    >
                        Create Game
                    </button>
                </div> */}

            {/* <button type='button' className=' sm:w-[160px] md:w-[160px] lg:w-[160px] h-[48px] text-[14px] btn btn-primary ml-10 lg:ml-0' onClick={handleSaveChanges}>Save Changes</button> */}
            {/* </div> */}

            {openConfirmUploadModal && (
                <Confirm
                    modalid="uploadcsv"
                    toggle={openConfirmUploadModal}
                    file={file}
                    setOpenConfirmUploadModal={setOpenConfirmUploadModal}
                    setUploadFlag={setUploadFlag}
                />
            )}

            {
                openDeleteUserModal &&
                <DeleteTeamModal toggle={openDeleteUserModal} setOpenAddTeamModal={setOpenDeleteUserModal} teamId={deleteUserId} handleDeleteFun={''} textInfo={" Are you sure you want to remove this user from team?"} />
            }
            {
                openAddTeamModal &&
                <AddTeamModal modalid={"usersAddedModal"} setOpenAddTeamModal={setOpenAddTeamModal} toggle={openAddTeamModal} />
            }
            {
                toggalDeleteTeamModel &&

                <DeleteTeamModal toggle={toggalDeleteTeamModel} teamId={deleteTeamId} setOpenAddTeamModal={setToggalDeleteTeamModal} setDeleteTeamId={setDeleteTeamId} handleDeleteFun={handleDeleteTeam} textInfo={"Are you sure you want to delete this team?"} />
            }

            <SampleCsvAddTeamUser showModal={modalOne} setModal={(data) => setModalOne(data)} />

        </div >


    </>

    );
};
export default AddManageTeams;
